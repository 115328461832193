import React, { ChangeEvent, DOMAttributes, FocusEvent, useEffect, useRef, useState } from 'react';
import usePlacesAutocomplete, { getDetails, Suggestion } from 'use-places-autocomplete';

export interface AddressLookupProps extends DOMAttributes<AddressLookupProps> {
  address: Address;
  defaultValue?: string;
  setAddress: (address: Address, addressString: string) => void;
  setValid?: (valid: boolean) => void;
  componentRestrictions?: google.maps.places.ComponentRestrictions;
  name?: string | undefined;
  callback?: (event: any) => void,
  eecBillingInfo?: any,
  billingInfo?: any,
  billingEventSentToGTM?: boolean
  setBillingEventSentToGTM?: (status: boolean) => void
}

export interface Address {
  buildingName?: string;
  doorNumber?: string;
  line1?: string;
  line2?: string;
  state?: string;
  city?: string;
  county?: string;
  postCode?: string;
  country?: string;

  [key: string]: string | undefined;
}

export function simplifyAddress(address: Address): string {
  // Add the address parts together in the correct order
  const addressParts = [];
  if(address.doorNumber) {
    addressParts.push(address.doorNumber);
  }
  if(address.buildingName) {
    addressParts.push(address.buildingName);
  }
  if(address.line1) {
    addressParts.push(address.line1);
  }
  if(address.line2) {
    addressParts.push(address.line2);
  }
  if(address.city) {
    addressParts.push(address.city);
  }
  if(address.county) {
    addressParts.push(address.county);
  }
  if(address.state) {
    addressParts.push(address.state);
  }
  if(address.postCode) {
    addressParts.push(address.postCode);
  }
  if(address.country) {
    addressParts.push(address.country);
  }
  const addressString = addressParts.join(', ');

  return addressString.replace(',', '');
}

export function validateAddress(address: Address) {
  const regex = new RegExp("^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$");
  
  return (address.postCode && regex.test(address.postCode)) && address.line1 && address.postCode;
}

export function testPostCode(address: Address): JSX.Element {
  const regex = new RegExp("^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$");




  if (regex.test(address.postCode)  ) {
    return (
      <div>
        <h3><font color="#ff5757"> </font></h3>
      </div>
    )
  } else {
    return (
      <div>
        <h3><font color="#ff8a8a">Please ensure a valid postal code</font></h3>
      </div>
    )
  }
}



function loadData(suggestion: Suggestion,
                  setAddress: (address: Address, addressString: string) => void) {

  const placeId = suggestion.place_id;
  if(placeId) {
    getDetails({placeId})
      .then(result => {
        const address = convertAddress((result as google.maps.places.PlaceResult).address_components);
        setAddress(address, simplifyAddress(address));
      });
  }
}

export default function AddressLookup({address, setAddress, setValid, children, componentRestrictions, defaultValue, callback, eecBillingInfo, billingInfo, billingEventSentToGTM, setBillingEventSentToGTM}: AddressLookupProps) {
  const {value: placeValue, setValue: setPlaceValue, suggestions: {status, data}} = usePlacesAutocomplete({requestOptions: {componentRestrictions}, defaultValue});
  const [active, setActive] = useState(false);
  const selectionRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      const node = event.target as Node;
      if(!selectionRef?.current?.contains(node) &&
        !inputRef?.current?.contains(node)) {
        setActive(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectionRef, inputRef]);

  useEffect(() => {
    if(setValid) {
      setValid(!!validateAddress(address));
    }

    if(validateAddress(address) && callback && !billingEventSentToGTM && setBillingEventSentToGTM) {
      setBillingEventSentToGTM(true);
      callback(eecBillingInfo);
      callback(billingInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, setValid]);

  const onChange = (key: string) => (value: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>) => {
    const updatedAddress : Address = {
      ...address,
      [key]: value.target.value,
    };

    if(setValid) {
      setValid(!!validateAddress(updatedAddress));
    }
    setAddress(updatedAddress, simplifyAddress(updatedAddress));
  };
  return (
    <div className="coll-del-div addressLookup">
      <div className="title-and-input">
        <div className="title">
          <section>Address lookup:</section>
          <img src="/img/location-icon.png" alt="" />
        </div>
        <input className="lookup"
          placeholder="Type Address Here..."
          ref={inputRef}
          onFocus={() => setActive(true)}
          value={placeValue}
          onChange={(event) => setPlaceValue(event.target.value)}
        />
        {active &&
        <div ref={selectionRef} className="description address">
          {status === 'OK' ?
           <ul>
             {data.map((suggestion) => {
               const {
                 place_id,
                 structured_formatting: {main_text, secondary_text},
               } = suggestion;

               return (
                 <li key={place_id} onClick={() => {
                   loadData(suggestion, setAddress, );
                   setActive(false);
                   setPlaceValue("");
                 }}>
                   <strong>{main_text}</strong> <small>{secondary_text}</small>
                 </li>
               );
             })}
           </ul> : null
          }
        </div>}
        <div className="full-address-info">
          <AddressInput address={address} onChange={onChange} name="doorNumber" placeholder="Door Number" />
          <AddressInput address={address} onChange={onChange} name="buildingName" placeholder="Building Name" />
          <AddressInput address={address} onChange={onChange} name="line1" placeholder="Address Line 1" />
          <AddressInput address={address} onChange={onChange} name="line2" placeholder="Address Line 2" />
          <AddressInput address={address} onChange={onChange} name="city" placeholder="City" />
          <AddressInput address={address} onChange={onChange} name="county" placeholder="County" />
          <AddressInput address={address} onChange={onChange} name="postCode" placeholder="Post Code" />
          <AddressInput address={address} onChange={onChange} name="country" placeholder="Country" />
        </div>
        {children}
      </div>
    </div>
  );
}

interface AddressInputProps {
  onChange: (key: string) => (value: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>) => void;
  placeholder: string;
  name: string;
  address: Address;
}

function AddressInput({onChange, placeholder, name, address}: AddressInputProps) {
  return <div key={name} className="each">
    <input id={name} onChange={onChange(name)} onBlur={onChange(name)} value={address[name] || ""} />
    <div className="line" />
    <label className="address-label" htmlFor={name}>{placeholder}</label>
  </div>;
}

function convertAddress(addressComponents: google.maps.GeocoderAddressComponent[] | undefined) {
  const address: Address = {};

  if(addressComponents) {
    for(let addressComponent of addressComponents) {
      for(let type of addressComponent.types) {
        switch(type) {
          case 'street_number':
            address.doorNumber = address.doorNumber || addressComponent.long_name;
            break;
          case 'premise':
            address.buildingName = address.buildingName || addressComponent.long_name;
            break;
          case 'street_address':
          case 'route':
            address.line1 = address.line1 || addressComponent.long_name;
            break;
          case 'country':
            address.country = address.country || addressComponent.long_name;
            break;
          case 'administrative_area_level_1':
            address.state = address.state || addressComponent.long_name;
            break;
          case 'neighborhood':
          case 'locality':
          case 'postal_town':
            address.city = address.city || addressComponent.long_name;
            break;
          case 'administrative_area_level_2':
            address.county = address.county || addressComponent.long_name;
            break;
          case 'postal_code':
            address.postCode = address.postCode || addressComponent.long_name;
            break;
        }
      }
    }
  }

  return address;
}