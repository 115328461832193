import React, { useEffect, useState } from 'react';
import SubmitButton from '../../components/SubmitButton';
import SmallButton from '../../components/SmallButton';
import { RouteComponentProps, useParams, withRouter } from 'react-router';
import { Order, OrderBoxesResponse, OrderResponse } from '../../models/APIModels';
import Moment from 'moment';
import Title from '../../components/Title';
import Label from '../../components/Label';
import StatusBlock from '../../components/StatusBlock';
import { axiosKK } from '../../services/networkRequest';
import PaymentBlock from '../../components/PaymentBlock';
import { RouteProps } from 'react-router-dom';
import { toMoney } from '../../utilities/MoneyConversion';
import PaymentStatusBlock from '../../components/PaymentStatusBlock';
import { NoteBlock } from '../../components/NoteBlock';
import Barcode from '../../components/Barcode';

interface OrderParams {
  id: string;
}

function OrderComponent({history}: RouteComponentProps): JSX.Element {
  const {id} = useParams<OrderParams>();
  const [order, setOrder] = useState<Order>();
  const statuses = order?.statuses || [];
  const payments = order?.payments || [];
  const notes = order?.notes || [];
  const orderBoxes = order?.orderBoxes || [];
  
  useEffect(() => {
    window.scrollTo(0, 0);

    if(!order) {
      axiosKK.get<OrderResponse>(`order/${id}`)
        .then(response => {
          setOrder(response.data.order);
        });
    }
  }, [order, id]);

  return (<>
      <section className="nav-status-bar">
        <div className="status-bar-content">
          <i className="fas fa-arrow-circle-left"/>
          <SmallButton onClick={() => {history.push("/dashboard")}}>Back</SmallButton>
        </div>
      </section>
      <Title>Your Order Overview</Title>
      <div className="print-doc customer-print-doc">
        <div className="top-border">{ order?.suitcaseOrder ? 'Suitcase Order' : order?.storageRequired ? 'Storage Order' : 'Shipping Order'}</div>
        <div className="second-border">
          <p className="roomPack">{ order?.roomPack ? 'Room is Packed' : 'Room is Not Packed'}</p>
          <p className="date">Order Created At: {Moment(order?.createdAt).format('dddd, MMM Do YYYY')}</p>
        </div>
        <div className="basic-block">
          <div className="id-city-requirement">
            <div className="id-and-city">
              <p className="id">{order?.id}</p>
              <p className="city">{order?.city}</p>
            </div>
          </div>
          <div className="addresses-block">
            <div className="each">
              <Label>Collection Date & Address:</Label>
              <p>{order?.collectionAddress}</p>
              <p>{Moment(order?.collectionDate).format('dddd, MMM Do YYYY')}</p>
            </div>
            <div className="each">
              <Label>Delivery Date & Address:</Label>
              <p>{order?.deliveryAddress}</p>
              <p>{order?.deliveryDate && Moment(order.deliveryDate).format('dddd, MMM Do YYYY')}</p>
            </div>
          </div>
        </div>

        <div className="items-block">
          {orderBoxes.map((orderBox, index) => {
            return(<OrderBoxesDisplay 
              key={ index }
              id={ orderBox.id }
              suitcaseOrder={ order?.suitcaseOrder }
              orderBox={ orderBox } />);
          })}
        </div>
        
        <NoteBlock notes={ notes } />
        <StatusBlock statuses={statuses} />
        <PaymentStatusBlock payments={payments} />
        <PaymentBlock initialCost={order?.initialCost} monthlyCost={order?.monthlyCost} storageRequired={ order?.storageRequired } />

      </div>
      <div className="btn-row">
        <SubmitButton red={true} onClick={() => history.push(`/order/${id}/edit`)}>
          Edit
        </SubmitButton>
      </div>
      
    </>
  );
}

interface OrderBoxesDisplayProps extends RouteProps {
  orderBox: OrderBoxesResponse;
  id: number;
  suitcaseOrder?: boolean;
  barcode?: string;
}

export function OrderBoxesDisplay({ orderBox, id, suitcaseOrder, barcode }: OrderBoxesDisplayProps): JSX.Element {

  const orderBoxDetail = orderBox?.box;
  const dimensions = orderBox?.dimensions;

  return(<>
    <div className="eachBox">
      <div className="border-top" />
      <div className="content">
        {
          suitcaseOrder ?
          <h3>Suitcase</h3> :
          <img className="boxIcon" src={orderBoxDetail?.imageUrl} alt="Product" />
        }
        <div className="basic">
          <h3 id={`orderBoxDetail-name-${id}`}>{orderBoxDetail?.name}</h3>
          <p id={`orderBoxDetail-description-${id}`}>{orderBoxDetail?.description}</p>
        </div>
        <div className="cost">
          <Label htmlFor={`orderBoxDetail-cost-${id}`}>Costs:</Label>
          <div className="costDetails">
            {
              suitcaseOrder ?
              <>
                <Label htmlFor={`orderBoxDetail-suitcaseDimensions-${id}`}>Dimensions:</Label>
                <div className="suitcase-mobile-view-table">
                  <ul>
                    <li>
                      <span className="title">Dimensions (cm)</span>
                      <h4 className="blue">{ dimensions?.depth } x { dimensions?.height } x { dimensions?.width }</h4>
                    </li>
                    <li>
                      <span className="title">Weight (kg)</span>
                      <h4 className="blue">{ dimensions?.weight }</h4>
                    </li>
                  </ul>
                </div>
              </> :
              <>
                <Label htmlFor={`orderBoxDetail-purchaseCost-${id}`}>Purchase Cost:</Label>
                <p id={`orderBoxDetail-purchaseCost-${id}`}>{orderBoxDetail?.purchaseCost && toMoney(orderBoxDetail.purchaseCost)}</p>
              </>
            }
            <Label htmlFor={`orderBoxDetail-shippingCost-${id}`}>Shipping Cost:</Label>
            <p id={`orderBoxDetail-shippingCost-${id}`}>{ suitcaseOrder ? orderBox?.shippingCost && toMoney(orderBox.shippingCost) : orderBoxDetail?.shippingCost && toMoney(orderBoxDetail.shippingCost)}</p>
            {
              !suitcaseOrder &&
                <>
                  <Label htmlFor={`orderBoxDetail-storageCost-${id}`}>Storage Cost:</Label>
                  <p id={`orderBoxDetail-storageCost-${id}`}>{orderBoxDetail?.storageCost && toMoney(orderBoxDetail.storageCost)}</p>
                </>
            }
          </div>
        </div>
        {
          barcode &&
            <div className="barcode">
              <Barcode>{ barcode }</Barcode>
            </div>
        }
        
      </div>
    </div>
    
  </>);
}

export default withRouter(OrderComponent);