import React from 'react';
import SubmitButton from './SubmitButton';
import { useLocation } from 'react-router-dom';

export default function Header(): JSX.Element {

  const { pathname } = useLocation();

  return (
    <header className="App-header">
      <nav className="navbar" >
        <div className="container">
          <a href="/" className="navbar-brand">
            <img src="/img/Kit Keeper Logo - White.png"  width="185" alt="LOGO"/>
          </a>
          <section className="nav-exclude-img">
            <ul id="primary" className="navbar-nav items">
              <li className="menu-item">
                <a href="../storage/boxes"  target="_blank" rel="noreferrer"
                   className={ pathname?.includes('storage') ? 'active' : '' }><h5>Storage</h5>
                </a>
              </li>
              <li className="menu-item">
                <a href="../shipping/boxes"  target="_blank" rel="noreferrer"
                   className={ pathname?.includes('suitcase') ? 'active' : '' }><h5>Shipping</h5>
                </a>
              </li>
              {/*<li className="menu-item"><a href="https://www.kitkeeper.co.uk/faqs/">FAQs</a></li>*/}
              <li className="menu-item"><a href="https://www.kitkeeper.co.uk/blog/"  target="_blank" rel="noreferrer"><h5>News</h5></a></li>
              <li className="menu-item"><a href="https://intercom.help/kit-keeper/en/" target="_blank" rel="noreferrer"><h5>Support</h5></a></li>
              <li className="menu-item"><a href="../login" target="_blank" rel="noreferrer"><h5> <img src="/img/LoginIcon.png"  width="25" alt="LOGIN"/> </h5></a></li>

            </ul>

            {/*
            <div className="pipe-div">|</div>
            */}
            {/*}   COMMENTING OUT THE SOCIAL MEDIA ICONS IN THE  HEADER BAR
            <ul id="primary" className="navbar-nav icons">

              <li className="social-icons">
                <a href="https://www.facebook.com/kitkeeper" target="_blank" rel="noreferrer">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li className="social-icons">
                <a href="https://www.instagram.com/kit.keeper" target="_blank" rel="noreferrer">
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li className="social-icons">
                <a href="https://www.linkedin.com/company/kit-keeper" target="_blank" rel="noreferrer">
                  <i className="fab fa-linkedin-in" />
                </a>
              </li>
              <li className="social-icons">
                <a href="https://twitter.com/kit_keeper" target="_blank" rel="noreferrer">
                  <i className="fab fa-twitter" />
                </a>
              </li>

            </ul>
            */}
            {/*<ul className="navbar toggle">*/}
            {/*  <a href="#" className="nav-link pr-sm-4" data-toggle="modal" data-target="#menu">*/}
            {/*    <i className="fas fa-bars" />*/}
            {/*  </a>*/}
            {/*</ul>*/}
            <div className="contact-button">
              <a href="https://www.kitkeeper.co.uk/contact">
                <SubmitButton>
                  {/* <i className="fas fa-phone-alt" /> COMMENT OUT THE PHONE ICON IN CONTACT US BUTTON */}<span>Contact Us</span>
                </SubmitButton>
              </a>

            </div>


          </section>

        </div>





        <div className="containerConvincer">

          <span>
            <br />
            <br />
            <br />
            <br />
            <span className="ConvincerBar1">

              <sup><img src="/img/CheckBox.png"  height="25" alt="CheckBox"/></sup>Pay by week, not by month &nbsp;&nbsp;
              {/*<sub><img src="/img/ThumbsUp.png"  height="25" alt="ThumbsUp"/></sub>Free £100 cover &nbsp;&nbsp;*/}
              {/*4.8* TrustPilot<sub><img src="/img/Stars.png"  height="25" alt="Stars"/></sub>&nbsp;&nbsp;*/}
              {/*<sub><img src="/img/CheckBox.png"  height="25" alt="CheckBox"/></sub>Official uni partnerships &nbsp;&nbsp;*/}
              {/*<sub><img src="/img/Location.png"  height="25"  alt="Location"/></sub>Collect & delivery anywhere in the UK*/}
            </span>

            <span className="ConvincerBar2">

            {/*  <sub><img src="/img/CheckBox.png"  height="25" alt="CheckBox"/></sub>Pay by week, not by month &nbsp;&nbsp;*/}
              <sup><img src="/img/ThumbsUp.png"  height="25" alt="ThumbsUp"/></sup>Free £100 cover &nbsp;&nbsp;
            {/*  4.8* TrustPilot<sub><img src="/img/Stars.png"  height="25" alt="Stars"/></sub>&nbsp;&nbsp;*/}
            {/*  <sub><img src="/img/CheckBox.png"  height="25" alt="CheckBox"/></sub>Official uni partnerships &nbsp;&nbsp;*/}
            {/*  <sub><img src="/img/Location.png"  height="25"  alt="Location"/></sub>Collect & delivery anywhere in the UK*/}
            </span>

            <span className="ConvincerBar3">

            {/*  <sub><img src="/img/CheckBox.png"  height="25" alt="CheckBox"/></sub>Pay by week, not by month &nbsp;&nbsp;*/}
            {/*  <sub><img src="/img/ThumbsUp.png"  height="25" alt="ThumbsUp"/></sub>Free £100 cover &nbsp;&nbsp;*/}
              4.9* TrustPilot<sup><img src="/img/Stars.png"  height="25" alt="Stars"/></sup>&nbsp;&nbsp;
            {/*  <sub><img src="/img/CheckBox.png"  height="25" alt="CheckBox"/></sub>Official uni partnerships &nbsp;&nbsp;*/}
            {/*  <sub><img src="/img/Location.png"  height="25"  alt="Location"/></sub>Collect & delivery anywhere in the UK*/}
            </span>

            <span className="ConvincerBar4">

            {/*  <sub><img src="/img/CheckBox.png"  height="25" alt="CheckBox"/></sub>Pay by week, not by month &nbsp;&nbsp;*/}
            {/*  <sub><img src="/img/ThumbsUp.png"  height="25" alt="ThumbsUp"/></sub>Free £100 cover &nbsp;&nbsp;*/}
            {/*  4.8* TrustPilot<sub><img src="/img/Stars.png"  height="25" alt="Stars"/></sub>&nbsp;&nbsp;*/}
              <sup><img src="/img/CheckBox.png"  height="25" alt="CheckBox"/></sup>Official uni partnerships &nbsp;&nbsp;
            {/*  <sub><img src="/img/Location.png"  height="25"  alt="Location"/></sub>Collect & delivery anywhere in the UK*/}
            </span>

            <span className="ConvincerBar5">

            {/*  <sub><img src="/img/CheckBox.png"  height="25" alt="CheckBox"/></sub>Pay by week, not by month &nbsp;&nbsp;*/}
            {/*  <sub><img src="/img/ThumbsUp.png"  height="25" alt="ThumbsUp"/></sub>Free £100 cover &nbsp;&nbsp;*/}
            {/*  4.8* TrustPilot<sub><img src="/img/Stars.png"  height="25" alt="Stars"/></sub>&nbsp;&nbsp;*/}
            {/*  <sub><img src="/img/CheckBox.png"  height="25" alt="CheckBox"/></sub>Official uni partnerships &nbsp;&nbsp;*/}
              <sup><img src="/img/Location.png"  height="25"  alt="Location"/></sup>Collect & delivery anywhere in the UK
            </span>



          </span>


          {/*

            <sub><img src="/img/CheckBox.png"  height="25" alt="CheckBox"/></sub>Pay by week, not by month
            <sub><img src="/img/ThumbsUp.png"  height="25" alt="ThumbsUp"/></sub>Free £100 cover &nbsp;&nbsp;
            4.8* TrustPilot<sub><img src="/img/Stars.png"  height="25" alt="Stars"/></sub>&nbsp;&nbsp;
            <sub><img src="/img/CheckBox.png"  height="25" alt="CheckBox"/></sub>Official uni partnerships
            <sub><img src="/img/Location.png"  height="25"  alt="Location"/></sub>Collect & delivery anywhere in the UK &nbsp;&nbsp;


          <p>
            <ul className="CCnavbar-nav items">
              <li className="menu-itemCC1"><sub><img src="/img/CheckBox.png"  height="25" alt="CheckBox"/></sub>Pay by week, not by month</li>
              <li className="menu-itemCC2"><sub><img src="/img/ThumbsUp.png"  height="25" alt="ThumbsUp"/></sub>Free £100 cover &nbsp;&nbsp; </li>
              <li className="menu-itemCC3">4.8* TrustPilot<sub><img src="/img/Stars.png"  height="25" alt="Stars"/></sub>&nbsp;&nbsp;</li>
              <li className="menu-itemCC4"><sub><img src="/img/CheckBox.png"  height="25" alt="CheckBox"/></sub>Official uni partnerships</li>
              <li className="menu-itemCC5"><sub><img src="/img/Location.png"  height="25"  alt="Location"/></sub>Collect & delivery anywhere in the UK &nbsp;&nbsp; </li>

            </ul>


          </p>
            */}



        </div>



      </nav>



    </header>
  );
}