import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { RouteProps, useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import Label from '../../components/Label';
import { axiosKK } from '../../services/networkRequest';
import { Order, OrderListResponse } from '../../models/APIModels';
import Moment from 'moment';
import UserContext from '../../contexts/UserContext';
import { Role } from '../../utilities/Role';
import SubmitButton from '../../components/SubmitButton';
import FileDownload from 'js-file-download';


function AdminDashboardComponent({history}: RouteComponentProps): JSX.Element {
  const [orders, setOrders] = useState<Order[]>([]);
  const {user: loggedInUser} = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);

    axiosKK.get<OrderListResponse>('/orders')
      .then(result => {
        setOrders(result.data.orders);
      });
  }, []);

  return (<>
    {function() {
      switch(loggedInUser?.role) {
        case Role.Admin:
          return <Title>Admin Dashboard</Title>;
        case Role.Warehouse:
          return <Title>Warehouse Dashboard</Title>;
        default:
          return <Title>Dashboard</Title>;
      }
    }()}

    { loggedInUser && <p className="grey">Welcome back, { loggedInUser?.firstName }</p> }

    <section className="option-box-container">
      {loggedInUser?.role === Role.Admin && <>
        <div className="each-box" onClick={() => {
          history.push('/admin/products');
        }}>
          <div className="img"><i className="fas fa-sign-in-alt" /></div>
          <div className="content">
            <h5>Products</h5>
            <p>Create and Edit Products</p>
          </div>
        </div>
        <div className="each-box" onClick={() => {
          history.push('/admin/discounts');
        }}>
          <div className="img"><i className="fas fa-sign-in-alt" /></div>
          <div className="content">
            <h5>Discounts</h5>
            <p>Create and Edit Discounts</p>
          </div>
        </div>
        <div className="each-box" onClick={() => {
          history.push('/admin/users');
        }}>
          <div className="img"><i className="fas fa-map-marker-alt" /></div>
          <div className="content">
            <h5>Manage Admins</h5>
            <p>Create and Edit Admin Profiles</p>
          </div>
        </div>
      </>}
      <div className="each-box" onClick={() => {
        history.push('/admin/customers');
      }}>
        <div className="img"><i className="fas fa-map-marker-alt" /></div>
        <div className="content">
          <h5>Customer Profiles</h5>
          <p>Create and Edit Customer Profiles</p>
        </div>
      </div>
    </section>

    <div className="line" />

    <Title>Orders</Title>
    <section className="search-bar">
      <SubmitButton onClick={() => {
        axiosKK.get(`/orders/CSV`, {
          responseType: 'blob',
        })
          .then(response => {
            FileDownload(response.data, `OrderBoxes.csv`);
          });
      }}>Download CSV</SubmitButton>
    </section>
    <section className="admin-all-orders-info">
      <div className="table">
        <div className="thead">
          <div className="tr">
            <div className="td id">ID</div>
            <div className="td date">Created Date</div>
            <div className="td address">Customer Name</div>
            <div className="td yes-and-no">Room Packed</div>
            <div className="td yes-and-no">Order Type</div>
            <div className="td date">Collection Date</div>
            <div className="td date">Delivery Date</div>
          </div>
        </div>
        <div className="tbody">
          {orders.map(each => (<Row key={each.id} order={each} />))}
        </div>
      </div>
    </section>

  </>);
}

interface RowProps extends RouteProps {
  order: Order;
}

function Row({order}: RowProps): JSX.Element {
  const history = useHistory();

  return (
    <div className="tr" onClick={() => history.push(`/admin/order/${order.id}`, {order})}>
      <Label>ID</Label>
      <div className="td id">{order.id}</div>
      <Label>Created At</Label>
      <div className="td date">{Moment(order.createdAt).format('dddd, MMM Do YYYY')}</div>
      <Label>Customer Name</Label>
      <div className="td address">{`${order.customer.firstName} ${order.customer.lastName}`}</div>
      <Label>Room Pack</Label>
      <div className="td yes-and-no">{order.roomPackIncluded ? 'Yes' : 'No'}</div>
      <Label>Order Type</Label>
      <div className="td yes-and-no">{ order.suitcaseOrder ? 'Suitcase' : order.storageRequired ? 'Storage' : 'Shipping' }</div>
      <Label>Collection Date</Label>
      <div className="td date">{Moment(order.collectionDate).format('dddd, MMM Do YYYY')}</div>
      <Label>Delivery Date</Label>
      <div className="td date">{order.deliveryDate ? Moment(order.deliveryDate).format('dddd, MMM Do YYYY') : "Not Set"}</div>
    </div>
  );
}


export default withRouter(AdminDashboardComponent);