import React, { useState } from 'react';
import SubmitButton from '../../components/SubmitButton';
import { Order, OrderResponse } from '../../models/APIModels';
import Moment from 'moment';
import Label from '../../components/Label';
import { axiosKK } from '../../services/networkRequest';
import Modal from 'react-modal';
import { Formik } from 'formik';
import AddressLookup, { Address, validateAddress } from '../../components/AddressLookup';
import errorMessage from '../../services/errorMessage';
import { AxiosError } from 'axios';
import DayPickerInputValue from '../../components/edit/DayPickerInputValue';
import { useDateDisabler } from '../../utilities/DateDisabler';

interface OrderModalProps {
  update: (updated: Order) => void;
  close: () => void;
  editMode: boolean;
  order: Order;
}

interface OrderEditCollectionDetailsErrors {
  collectionDate?: string;
  collectionAddress?: string;
}

export default function OrderEditCollectionDetailsModal({editMode, order, close, update}: OrderModalProps): JSX.Element {
  const disableCollection = order.statuses.reduce<boolean>((previous, status) => {
    return previous && status.statusId >= 3;
  }, false);

  const [collectionAddress, setCollectionAddress] = useState<Address>({});
  const [networkError, setNetworkError] = useState<string>();

  // 0 = Sunday, 6 = Saturday.
  const currentDayOfWeek = Moment().day()
  let daysToAdd = 3;
  if(currentDayOfWeek === 3 || currentDayOfWeek === 4 || currentDayOfWeek === 5) {
    daysToAdd = 5;
  } else if (currentDayOfWeek === 6) {
    daysToAdd = 4;
  }

  // Do not allow edits to delivery details if delivery date is within 3 working days of current date.
  const cutOffTime = Moment().add(daysToAdd, 'd').set('hour', 23).set('minute', 59).set('second', 59);
  const collectionDateIsBeforeCutOff = Moment(order?.collectionDate).isBefore(cutOffTime);

  const disabledDays = useDateDisabler(null, null);

  const initialValues: Order = {
    ...order
  }

  return (<Modal
    isOpen={editMode}
    onRequestClose={close}
    style={{  content: {
        display: 'flex',
      }}}
    contentLabel="Edit Collection Details"
  >
    <Formik
      initialValues={initialValues}
      validate={({collectionAddress: collectionAddressString, collectionDate}: Order) => {
        const errors: OrderEditCollectionDetailsErrors = {};

        if(order?.deliveryDate && (Moment(collectionDate).isAfter(order.deliveryDate) || Moment(collectionDate).isSame(order.deliveryDate, 'date'))) {
          errors.collectionDate = `Collection date must be before delivery date on ${Moment(order.deliveryDate).format('dddd, MMM Do YYYY')}. 
                                  Alternatively, edit your delivery details first`
        }

        // Only authenticate address if it is not identical to the original
        if (order?.collectionAddress !== collectionAddressString || !order.collectionAddress) {

          if (!collectionAddressString || collectionAddressString.length <= 0) {
            errors.collectionAddress = 'Collection address is invalid';
          } else if (!validateAddress(collectionAddress)) {
            errors.collectionAddress = 'Collection address is incomplete';
          }
        }

        return errors;
      }}
      onSubmit={(values, {setSubmitting, resetForm}) => {
        setSubmitting(true);
        axiosKK.put<OrderResponse>('/order/update', {
          ...values,
          "collectionCountry": collectionAddress.country,
          "collectionPostcode": collectionAddress.postCode
        })
          .then(({data}) => {
            update(data.order);
          })
          .catch(error => {
            errorMessage(setNetworkError)(error);
            setSubmitting(false);
          })
          .catch((error: Error | AxiosError) => {
            const jsonError = JSON.parse(JSON.stringify(error));

            if(jsonError.hasOwnProperty('response')) {
              console.error((error as AxiosError).response?.data);
            } else if(jsonError.hasOwnProperty('message')) {
              console.error(error.message);
            } else {
              console.error(error);
            }
            setSubmitting(false);
          })
          .finally(() => {
            setSubmitting(false);
            resetForm();
            close();
          });
      }}
    >
      {
        ({
          values,
          isSubmitting,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit,
          errors,
          isValid,
          dirty,
          }) => <>
          <form onSubmit={handleSubmit} className="order-edit-form" name="order-edit-form">
            <div className="modal-header">
              <h3>Edit Collection Details</h3>
              <SubmitButton red={true} onClick={close}>Close</SubmitButton>
            </div>
            <div className="modal-content">
            {collectionDateIsBeforeCutOff? <div className="error-block"><p>Can not change collection details within 3 working days of collection date</p></div> : null}
              <div className="order-edit-inputs">
                <Label htmlFor="date">Collection Date</Label>
                <div className="current-info">
                  <div className="current-label"><h6>Current</h6></div>
                  <div className="info"><span>{Moment(order.collectionDate).format('dddd, MMM Do YYYY')}</span></div>
                </div>
                <div className="DO_NOT_REMOVE_THIS_DIV">
                  <div className="dateLookup">
                    <div className="title-and-input">
                      <div className="title">
                        <section>Collection Date:</section>
                        <img alt="" src="/img/calendar-icon.png" />
                      </div>
                      <span className="error">{errors.collectionDate}</span>
                      {collectionDateIsBeforeCutOff? 
                        <span className="error">Cannot edit date</span> : 
                        <DayPickerInputValue 
                         id="collectionDate" 
                         handleFormChange={setFieldValue} 
                         value={values.collectionDate}
                         disabled={disableCollection} 
                         onChange={handleChange} 
                         handleBlur={handleBlur}
                         disabledDays={disabledDays}
                       />
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-edit-inputs">
                <Label htmlFor="date">Collection Address</Label>
                <div className="current-info">
                  <div className="current-label"><h6>Current</h6></div>
                  <div className="info"><span>{order?.collectionAddress}</span></div>
                </div>
                <div className="stuff">
                <span className="error">{errors.collectionAddress}</span>
                {collectionDateIsBeforeCutOff? 
                  <span className="error">Cannot edit address</span> : 
                  <AddressLookup name="collectionAddress" address={collectionAddress} setAddress={(address, addressString) => {
                    setFieldValue("collectionAddress", addressString, true);
                    setCollectionAddress(address);
                  }}
                    componentRestrictions={{country: 'uk'}} defaultValue={values.collectionAddress} />}
                </div>
              </div>              
            </div>
            {networkError && <span className="error">{networkError}</span>}
            <SubmitButton disabled={collectionDateIsBeforeCutOff || !isValid || !dirty} isSubmitting={isSubmitting}>
              Update
            </SubmitButton>
          </form>
        </>
      }
    </Formik>
  </Modal>);
}