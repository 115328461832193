import React from 'react';

interface BarcodeProps extends React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
    children: string,
}

export default function Title({children}: BarcodeProps): JSX.Element {
  
    return (
        <p className="barcode">{children}</p>
    );
  }