import React from 'react';

interface TitleProps extends React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  children: string,
}

export default function Title({children}: TitleProps): JSX.Element {
  const [first, ...rest] = children.split(' ');

  return (
    <h2 className="blue-black-title">
      <span>{first}</span>
      &nbsp;
      {rest.join(' ')}
    </h2>);
}