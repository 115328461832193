import React from 'react';
import { withRouter } from 'react-router';
import LoginSection, { LoginProps } from '../components/LoginSection';
import { Link } from 'react-router-dom';
import useGTM from "@elgorditosalsero/react-gtm-hook";

interface LoginComponentProps extends LoginProps {

}

function LoginComponent(props: LoginComponentProps): JSX.Element {
  const { sendDataToGTM } = useGTM();

  const handleRegisterClick = () => {
    sendDataToGTM({event: 'register_link'});
  }
  
  return <>
    <section className="login-status-bar">
      <div className="status-bar-content">
        <span>Not a customer?</span>
        <Link onClick={handleRegisterClick} to="/register">Register here</Link>
      </div>
    </section>
    <LoginSection {...props} />
    You may need to login twice
  </>
}

export default withRouter(LoginComponent);