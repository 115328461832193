import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Order, PaymentResponse } from '../../models/APIModels';
import { toMoney } from '../../utilities/MoneyConversion';
import Title from '../../components/Title';
import Label from '../../components/Label';
import Moment from 'moment';
import SubmitButton from '../../components/SubmitButton';

interface OrdersProps {
  orders?: Order[];
}

export default function PaymentsDisplayComponent({orders}: OrdersProps): JSX.Element {

  const allMonthlyCosts = orders?.filter(order => !order.orderComplete).map(order => order.monthlyCost);
  const allMonthlyCostsTotal = allMonthlyCosts?.reduce((a, b) => a + b, 0);

  const [showMore, setShowMore] = useState(false);

  return <>
    <section className="admin-customer-payment">
      <div className="display-payment-block">
        <div className="each-type all-transactions">
          <Title>All Transactions</Title>
          <div className="line" />
          <div className="all-payments">
            {orders?.slice(0, showMore ? orders.length : 3).map(order => {
              const payments = order.payments;
              return (
                payments.length && <EachPayments key={order.id} order={order} payments={payments} />
              );
            })}
          </div>
          {orders && orders.length >= 3 && <SubmitButton onClick={() => {
            if (showMore) {
              setShowMore(false);
            } else {
              setShowMore(true);
            }}}>
            {showMore ? <i className="fas fa-chevron-up" />: <i className="fas fa-chevron-down" />}
          </SubmitButton>}
        </div>
        <div className="each-type recurring-payments">
          <Title>Recurring Payments</Title>
          <div className="line" />
          <div className="monthly-rate">
            <h4>{allMonthlyCostsTotal && toMoney(allMonthlyCostsTotal)}</h4>
            <div>/ month</div>
          </div>
          <div className="all-monthly-payments">
            <MonthlyPayment orders={orders} total={allMonthlyCostsTotal} />
          </div>
        </div>
      </div>
    </section>
  </>;
}

interface MonthlyPaymentProps {
  orders?: Order[];
  total?: number;
}

function MonthlyPayment({orders, total}: MonthlyPaymentProps): JSX.Element {
  const history = useHistory();
  
  return (<>
    <section className="monthlyPayment">
      <span className="title"><p>All current recurring charges</p></span>
      <div className="all-cost-layout">
        {orders?.map(order => 
          (order.orderComplete ? null : 
            <div key={order.id} className="each">
              <div className="left-container">
                <div onClick={() => history.push(`/admin/order/${order.id}`)} className="orderId">{order.id}</div>
                <span className="updatedAt">Last Payment: {Moment(order?.updatedAt).format('Do MMM YYYY')}</span>
              </div>
              <span className="cost">{toMoney(order.monthlyCost)}</span>
            </div>
          ))
        }
      </div>
      <div className="total">
        <span>Total</span>
        <span>{total && toMoney(total)}</span>
      </div>
    </section>
  </>)
}

interface EachPaymentsProps {
  payments?: PaymentResponse[]
  order: Order;
}

function EachPayments({payments, order}: EachPaymentsProps): JSX.Element {
  const history = useHistory();
  

  return (<>
    <section className="eachPayments">
      <div onClick={() => history.push(`/admin/order/${order.id}`)} className="left-column">
        <p>{order.id}</p>
      </div>
      <main>
        <div className="payments-update-block">
          { payments?.map((payment, index) => <EachPayment key={index} payment={payment} />) }
        </div>
        <div className="lastUpdated">
          <p>Last updated : {Moment(order?.updatedAt).format('dddd, MMM Do YYYY')}</p>
        </div>
      </main>
    </section>
  </>);
}

interface EachPaymentProps {
  payment: PaymentResponse
}

function EachPayment({payment}: EachPaymentProps): JSX.Element {

  return (
    <div className="eachPay">
      <div className={payment.succeeded ? 'status succeeded' : 'status fail'}><p>{payment.status}</p></div>
      <div className="total">
        <Label>Amount:</Label>
        <p>{toMoney(payment.amount)}</p>
      </div>
      <span className="createdAt">Transaction made on {Moment(payment?.createdAt).format('dddd, MMM Do YYYY')}</span>
    </div>
  )
}