import Label from './Label';
import React from 'react';
import { toMoney } from '../utilities/MoneyConversion';


interface PaymentsBlockProps {
  initialCost?: number;
  monthlyCost?: number;
  storageRequired?: boolean;
}

export default function PaymentBlock({ initialCost, monthlyCost, storageRequired }: PaymentsBlockProps) {
  return (
    <div className="payment-block">
      <div className="each">
        <Label htmlFor={`initialCost`}>{ storageRequired ? 'Initial Cost' : 'Total Cost' }</Label>
        <h5>{ initialCost && <span>{toMoney(initialCost)}</span> }</h5>
      </div>
      {storageRequired &&
      <>
        <div className="divider" />
        <div className="each">
          <h5>{monthlyCost && <span>{toMoney(monthlyCost)}</span>}</h5>
          <Label htmlFor={`initialCost`}>Monthly Cost</Label>
        </div>
      </>
      }
    </div>
  );
}