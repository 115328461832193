import React, { useContext, useEffect, useState } from 'react';
import Orders from '../../components/user/Orders';
import SubmitButton from '../../components/SubmitButton';
import { RouteComponentProps, withRouter } from 'react-router';
import Title from '../../components/Title'
import { axiosKK } from '../../services/networkRequest';
import { PreviousOrderListResponse, Order } from '../../models/APIModels';
import UserContext from '../../contexts/UserContext';

function DashboardComponent({history}: RouteComponentProps): JSX.Element {
  
  const [orders, setOrders] = useState<Order[]>([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    axiosKK.get<PreviousOrderListResponse>("/order/previous")
      .then(result => {
        setOrders(result.data.previousOrders.sort((a, b) => b.id - a.id));
      });
  }, []);
  
  return (<>
    <Title>Customer Dashboard</Title>
    { user && <p className="grey">Welcome back, { user?.firstName }</p> }
    <div className="dashboard-all-box-container customer-dashboard">
      <section onClick={() => history.push("/")} className="each-box">
        <div className="content">
          <img className="static-state" src="/img/box-icon-black.png" alt="" />
          <img className="rollover-state" src="/img/box-icon-white.png" alt="" />
          <h3>Make New Bookings!</h3>
        </div>
        <SubmitButton>
          Book Now
        </SubmitButton>
      </section>
      <section onClick={() => history.push("/profile")} className="each-box">
        <div className="content">
          <img className="static-state" src="/img/ship-icon-black.png" alt="" />
          <img className="rollover-state" src="/img/ship-icon-white.png" alt="" />
          <h3>Name, Address and Payment Details</h3>
        </div>
        <SubmitButton>
          Manage Profile
        </SubmitButton>
      </section>
      <section onClick={() => history.push('/payments')} className="each-box">
        <div className="content">
          <img className="static-state" src="/img/dummy-item.png" alt="" />
          <img className="rollover-state" src="/img/dummy-item-white.png" alt="" />
          <h3>Recurring Payments and Transactions</h3>
        </div>
        <SubmitButton>
          Track My Payments
        </SubmitButton>
      </section>
    </div>

    <div className="line"/>

    <Title>Your Recent Orders</Title>
    <p className="grey">Please note that only orders that are not processed are shown below. To view all orders, please naviagte to <i>Track my Order</i>.</p>
    <Orders orders={orders} />
  </>
  );
}

export default withRouter(DashboardComponent)