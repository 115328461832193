
import React from 'react';


export function displayLoginName(  user  ): JSX.Element {

  let welcomeText: string;

  if ( user ) {
    welcomeText = "Welcome back, " + user.firstName + " ";
    return (
      <div>
        <span>{welcomeText} </span>


      </div>
    )
  }  else {
    welcomeText = "";
    return (
      <div>
        <span>{welcomeText}<br /> </span>
      </div>

    )
  }

}
