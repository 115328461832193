import React from 'react';

export interface User {
  id: number,
  email: string,
  role: string,
  firstName: string,
  lastName: string,
  token?: string,
  phoneNumber: string, //? removed
  createdAt: string,
  updatedAt: string,
  stripeId?: string,
}

export interface UserState {
  user?: User,
  updateUser: (user: User) => void,
}

const UserContext = React.createContext<UserState>({
  updateUser(): void {}
} )

export default UserContext;