import React from 'react';
import Label from './Label';
import Moment from 'moment';
import { StatusResponse } from '../models/APIModels';

interface StatusBlockProps {
  statuses: StatusResponse[];
}

export default function StatusBlock({statuses}: StatusBlockProps) {
  return (<>
    { statuses.length &&
      <div className="status-block">
        <Label>Status Update:</Label>
        {statuses.map(status => {
          return (
            <div key={status.id} className="each">
              <Label>Status Updated At:</Label>
              <p>{Moment(status.updatedAt).format('dddd, MMM Do YYYY')}</p>
              <Label>Status</Label>
              <p>{status.status.type}</p>
            </div>
          );
        })}
      </div>
    }
  </>);
}