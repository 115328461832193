import { BankHoliday, BankHolidays } from '../models/APIModels';
import { Address } from '../components/AddressLookup';


export function reduceBankHolidays(bankHolidays: BankHolidays, address: Address | null) {
  let datesToInclude: BankHoliday[] = [];
  const country = address && (address.state || address.country);

  // Prioritise state fist, then country, as state is the country that gets set by Google Maps API
  switch(country && country.toLowerCase()) {
    // If address has not yet been set, default to England and Wales as bank holidays
    default:
    case 'england':
      datesToInclude = datesToInclude.concat(bankHolidays['england-and-wales']?.events);
      break;
    case 'wales':
      datesToInclude = datesToInclude.concat(bankHolidays!!['england-and-wales']?.events);
      break;
    case 'scotland':
      datesToInclude = datesToInclude.concat(bankHolidays!!['scotland']?.events);
      break;
    case 'northern ireland':
      datesToInclude = datesToInclude.concat(bankHolidays!!['northern-ireland']?.events);
      break;
  }

  return datesToInclude;
}