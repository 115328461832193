import React, { useEffect } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import { Modifier } from 'react-day-picker/types/Modifiers';

interface DayPickerInputProps {
  handleFormChange?: (field: string, value: any, shouldValidate?: boolean) => void;
  handleStateChange?: (value: any) => void;
  value?: number;
  id: string;
  disabled: boolean;
  handleBlur?: (e: React.FocusEvent<any>) => void;
  onChange?: (e: React.ChangeEvent<any>) => void;
  disabledDays?: Modifier | Modifier[];
}

export default function DayPickerInputValue({handleFormChange, handleStateChange, onChange, handleBlur, id, value, disabledDays}: DayPickerInputProps): JSX.Element {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return(
    <>
      <DayPickerInput
        placeholder="DD/MM/YYYY"
        format="DD/MM/YYYY"
        formatDate={MomentLocaleUtils.formatDate}
        parseDate={MomentLocaleUtils.parseDate}
        onDayChange={(day) => {
          if(handleFormChange) {
            handleFormChange(id, day, true);
          } else if (handleStateChange) {
            handleStateChange(day)
          }
        }}
        onChange={onChange}
        onBlur={handleBlur}
        value={value ? new Date(value) : ""}
        dayPickerProps={{
          disabledDays: disabledDays || []
        }}
      />
    </>
  );
}