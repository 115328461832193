import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import Modal from 'react-modal';
import { Formik } from 'formik';
import { axiosKK } from '../../services/networkRequest';
import { Discount, DiscountAndCount, DiscountResponse } from '../../models/APIModels';
import SubmitButton from '../SubmitButton';
import Label from '../Label';
import StyledSwitch from '../StyledSwitch';
import DayPickerInputValue from '../edit/DayPickerInputValue';
import Error from '../Error';

interface DiscountModalProps {
  showEdit: boolean | number;
  showCreate: boolean;
  close: () => void;
  updateDiscounts: (discount: DiscountAndCount) => void;
  refreshDiscounts: () => void;
  discount?: Discount;
}

interface DiscountFormValues {
  discountCode: string,
  discountPercentage: number,
  recurring: boolean,
  upFront: boolean,
  expires?: number;
}

interface DiscountErrors {
  discountCode?: string,
  discountPercentage?: string,
  recurring?: string,
  upFront?: string,
  expires?: string,
}

export function DiscountModal({
                                showEdit,
                                showCreate,
                                close,
                                updateDiscounts,
                                refreshDiscounts,
                                discount,
                              }: DiscountModalProps): JSX.Element {

  const discountExpires = !!discount?.expires;
  const [needExpiryDate, setNeedExpiryDate] = useState(discountExpires);
  const reducedEdit = showEdit !== false;

  useEffect(() => {
    setNeedExpiryDate(discountExpires);
  }, [discountExpires]);

  const today = Moment();
  const momentNow = today.add(1, 'days').format('x');
  const momentNowNumber: number = +momentNow;

  const initialValues: DiscountFormValues = discount || {
    discountCode: '',
    discountPercentage: 0,
    recurring: false,
    upFront: false,
    expires: needExpiryDate ? momentNowNumber : undefined,
  };

  return <Modal
    isOpen={showEdit !== false || showCreate}
    onRequestClose={close}
    style={{
      content: {
        display: 'flex',
      },
    }}
    contentLabel="Discount Modal"
  >
    <Formik
      validateOnMount={true}
      enableReinitialize
      initialValues={initialValues}
      validate={({discountCode, discountPercentage, upFront, recurring, expires}: DiscountFormValues) => {
        const errors: DiscountErrors = {};

        if(discountCode.length < 4) {
          errors.discountCode = 'Code length too short';
        }

        const percentage = discountPercentage * 1;

        if(percentage <= 0 || percentage > 100) {
          errors.discountPercentage = 'Discount must between 1 and 100';
        }

        if(!upFront && !recurring) {
          errors.upFront = errors.recurring = 'Select one option minimum';
        }

        if(expires !== undefined && Moment(expires).isBefore(today)) {
          errors.expires = "Expiry date must be in the future";
        }

        return errors;
      }}
      onSubmit={(values, {setSubmitting}) => {
        setSubmitting(true);
        const url = showCreate ? '/discounts' : `/discounts/${showEdit}`;
        const method = showCreate ? 'post' : 'put';

        if(!needExpiryDate) {
          values.expires = undefined;
        }

        axiosKK[method]<DiscountResponse>(url, values)
          .then(({data}) => {
            if(showCreate) {
              updateDiscounts({
                ...(data.discount),
                count: 0,
              });
            } else {
              refreshDiscounts();
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
        close();
      }}
    >
      {
        ({
           values,
           handleChange,
           handleSubmit,
           errors,
           touched,
           handleBlur,
           isValid,
           setFieldValue,
         }) =>
          <form onSubmit={handleSubmit} name="new-discount-form">
            <div className="modal-header">
              <h3>{showCreate ? 'Create New Discount' : `Edit Discount: ${discount?.discountCode}`}</h3>
              <SubmitButton red={true} onClick={close}>Close</SubmitButton>
            </div>

            <div className="modal-content">

              <div className="expires">
                <Label>Expiry Date (Optional)</Label>
                <div className="stuff">
                  <div className="expiry-date-switch">
                    <StyledSwitch id="needExpiryDate"
                                  handleChange={() => setNeedExpiryDate(!needExpiryDate)}
                                  value={needExpiryDate} />
                    <span className={needExpiryDate ? 'expiry-span need' : 'expiry-span no-need'}>
                      {needExpiryDate}
                    </span>
                  </div>
                  {needExpiryDate && <div className="dateLookup">
                    <div className="title-and-input">
                      <div className="title">
                        <section>Set Expiry Date:</section>
                        <img alt="" src="/img/calendar-icon.png" />
                      </div>
                      <DayPickerInputValue id="expires"
                                           handleFormChange={(function(id, date) {
                                             setFieldValue(id, date || new Date(), true);
                                           })}
                                           value={needExpiryDate ? values.expires || Date.now() : undefined}
                                           disabled={false}
                                           onChange={handleChange}
                                           handleBlur={handleBlur}
                      />
                      <Error error={errors.expires} touched={true} />
                    </div>
                  </div>}
                </div>
              </div>

              <div className="percentage">
                <Label htmlFor="discount">Percentage</Label>
                <div className="stuff">
                  <input onBlur={handleBlur} onChange={handleChange} name="discountPercentage" id="discountPercentage"
                         type="number" min="0" value={values.discountPercentage} disabled={reducedEdit} />
                  <h6>% off</h6>
                </div>
                <Error error={errors.discountPercentage} touched={touched.discountPercentage} />
              </div>

              <div className="checkbox">
                <Label>Types of Discounts</Label>
                <div className="stuff">
                  <div className="each">
                    <StyledSwitch handleChange={setFieldValue} value={values.recurring} id="recurring"/>
                    <Label htmlFor="recurring" className='title-label'>Recurring Discount</Label>
                  </div>
                  <div className="each">
                    <StyledSwitch handleChange={setFieldValue} value={values.upFront} id="upFront" />
                    <Label htmlFor="upFront" className='title-label'>Up-Front Discount</Label>
                  </div>
                </div>
                <Error error={errors.recurring || errors.upFront} touched={touched.recurring || touched.upFront} />
              </div>

              <div className="code">
                <Label htmlFor="discountCode">Discount Code</Label>
                <div className="stuff">
                  <input onBlur={handleBlur} onChange={e => {
                    e.target.value = e.target.value.toUpperCase();
                    handleChange(e);
                  }} value={values.discountCode} type="text" name="discountCode" id="discountCode"
                         disabled={reducedEdit} />
                </div>
                <Error error={errors.discountCode} touched={touched.discountCode} />
              </div>
            </div>

            <SubmitButton disabled={!isValid}>
              Submit
            </SubmitButton>
          </form>
      }
    </Formik>
  </Modal>;
}