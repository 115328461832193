import { User } from '../contexts/UserContext';

export enum Role {
  Admin = 'ADMIN',
  Warehouse = 'WAREHOUSE',
}

export function adminCheck(user: User) : boolean {
  return user.role === Role.Admin || user.role === Role.Warehouse;
}
