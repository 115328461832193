import React from 'react';
import SubmitButton from '../components/SubmitButton';

export default function BackToPaymentComponent(): JSX.Element {
  
  return (
    <div className="App-content">
      <div className="success">
        <h2 className="blue-h2">Close and Continue</h2>
        <SubmitButton type="button"
                      onClick={ () => {
                        window?.top?.postMessage('3DS-authentication-complete', process.env.REACT_APP_FRONTEND_URL!);
                      } }>
          Back
        </SubmitButton>
      </div>
    </div>
  )
}