import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Formik } from 'formik';
import Title from '../../components/Title';
import Label from '../../components/Label';
import SubmitButton from '../../components/SubmitButton';
import SmallButton from '../../components/SmallButton';
import Error from '../../components/Error';
import { axiosKK } from '../../services/networkRequest';
import { ProductListResponse, Product, ProductAndCount, ProductResponse, Dimensions } from '../../models/APIModels';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import { RouteProps } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import { Role } from '../../utilities/Role';

const customStyles = {
  content: {
    display: 'flex',
  },
};

Modal.setAppElement('body');

export default function ProductComponent(this: any): JSX.Element {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState<ProductAndCount[]>([]);
  const { user: loggedInUser } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);

    axiosKK.get<ProductListResponse>('/boxes')
      .then(({data}) => {
        setProducts(data.products);
      })
  }, []);

  return(<>
    <section className="nav-status-bar">
      <div className="status-bar-content">
        <i className="fas fa-arrow-circle-left"/>
        <SmallButton onClick={() => {history.push("/admin/dashboard")}}>Back</SmallButton>
      </div>
    </section>
    <Title>Products</Title>
    <section className="admin-all-products-info">
      { loggedInUser?.role === Role.Admin &&
        <section className="search-bar">
          <SubmitButton onClick={() => {setShowModal(true);}}>+ Create New Product</SubmitButton>
        </section> }

      <div className="table">
        <div className="thead">
          <div className="tr">
            <div className="td small">Sort Order</div>
            <div className="td small">Image</div>
            <div className="td small">ID</div>
            <div className="td large">Name</div>
            <div className="td large">Email Field</div>
            <div className="td mid">Create At</div>
            <div className="td mid">Updated At</div>
          </div>
        </div>
        <div className="tbody">
          {products.map(each => (<Row key={each.id} product={each} count={each} />))}
        </div>
      </div>

    </section>
    { loggedInUser?.role === Role.Admin &&
      <ProductModal show={showModal} close={() => setShowModal(false)} updateProducts={product => {
        setProducts(([
          product,
          ...products,
        ]));
      }} /> }
  </>);
}

interface ProductModalProps {
  show: boolean;
  close: () => void;
  updateProducts: (product: ProductAndCount) => void;
}

interface ProductFormValues {
  name: string,
  purchaseCost: number,
  shippingCost: number,
  storageCost: number,
  description: string,
  sortOrder: number,
  imageUrl: string,
  emailField: string,
  dimensions: Dimensions,
  returnFee: boolean,
  chargeShipping: boolean,
}

interface ProductErrors {
  name?: string,
  purchaseCost?: string,
  shippingCost?: string,
  storageCost?: string,
  description?: string,
  sortOrder?: string,
  imageUrl?: string,
  emailField?: string,
  dimensions?: {
    width?: string,
    depth?: string,
    height?: string,
    weight?: string,
  },
}

function ProductModal({show, close, updateProducts}: ProductModalProps): JSX.Element {
  const initialValues: ProductFormValues = {
    name: '',
    purchaseCost: 0,
    shippingCost: 0,
    storageCost: 0,
    description: '',
    sortOrder: 0,
    imageUrl: '',
    emailField: '',
    dimensions: {
      depth: 0,
      height: 0,
      width: 0,
      weight: 0,
    },
    returnFee: false,
    chargeShipping: false,
  };

  return <Modal
    isOpen={show}
    onRequestClose={close}
    style={customStyles}
    contentLabel="Discount Modal"
  >
    <Formik
      initialValues={initialValues}
      validate={({name, purchaseCost, shippingCost, storageCost, description, dimensions, imageUrl, emailField}: ProductFormValues) => {
        const errors: ProductErrors = {};

        if(name.length < 2) {
          errors.name = 'Name too short';
        }

        if(purchaseCost < 0 || shippingCost < 0 || storageCost < 0) {
          errors.purchaseCost = errors.shippingCost = errors.storageCost = 'Costs must be a positive number';
        }

        const { width, height, depth } = dimensions;

        if(width <= 0 || height <= 0 || depth <= 0) {
          const dimensionError = 'Dimensions must be over 0';

          errors.dimensions = {
            width: dimensionError,
            height: dimensionError,
            depth: dimensionError,
          };
        }

        if(imageUrl.length < 2) {
          errors.imageUrl = 'Image URL too short';
        }

        if(emailField.length < 2) {
          errors.emailField = 'Email field too short';
        }

        if(description.length < 2) {
          errors.description = 'Description too short';
        }

        return errors;
      }}
      onSubmit={(values, {setSubmitting}) => {
        setSubmitting(true);
        axiosKK.post<ProductResponse>('/boxes', values)
          .then(({data}) => {
            updateProducts({
              ...(data.product),
              count: 0,
            });
          })
          .finally(() => {
            setSubmitting(false);
          });
        close();
      }}
    >
      {
        ({
           values,
           handleChange,
           handleSubmit,
           errors,
           touched,
           handleBlur,
           isValid,
           dirty
         }) =>
          <form onSubmit={handleSubmit} name="new-product-form">
            <div className="modal-header">
              <h3>Create New Product</h3>
              <SubmitButton red={true} onClick={close}>Close</SubmitButton>
            </div>

            <div className="modal-content">
              <div className="code">
                <Label htmlFor="name">Name</Label>
                <div className="stuff">
                  <input onBlur={handleBlur} onChange={handleChange} type="text" name="name" id="name"
                        value={values.name} />
                </div>
                <Error error={errors.name} touched={touched.name} />
              </div>
              <div className="cost">
                <Label>Costs:</Label>
                <div className="stuff">
                  <div className="each">
                    <input onBlur={handleBlur} onChange={handleChange} name="purchaseCost" id="purchaseCost" 
                          type="number" min="0" value={values.purchaseCost} />
                    <Label htmlFor="purchaseCost">Purchase Cost</Label>
                  </div>
                  <div className="each">
                    <input onBlur={handleBlur} onChange={handleChange} name="storageCost" id="storageCost" 
                          type="number" min="0" value={values.storageCost} />
                    <Label htmlFor="storageCost">Storage Cost</Label>
                  </div>
                  <div className="each">
                    <input onBlur={handleBlur} onChange={handleChange} name="shippingCost" id="shippingCost" 
                          type="number" min="0" value={values.shippingCost} />
                    <Label htmlFor="shippingCost">Shipping Cost</Label>
                  </div>
                </div>
                <Error error={errors.purchaseCost || errors.storageCost || errors.shippingCost} 
                      touched={touched.purchaseCost || touched.storageCost || touched.shippingCost} />
              </div>
              <div className="cost">
                <Label>Dimensions:</Label>
                <div className="stuff">
                  <div className="each">
                    <input onBlur={handleBlur} onChange={handleChange} name="dimensions.width" id="width"
                           type="number" min="0" value={ values.dimensions.width } />
                    <Label htmlFor="width">Width</Label>
                  </div>
                  <div className="each">
                    <input onBlur={handleBlur} onChange={handleChange} name="dimensions.height" id="height"
                           type="number" min="0" value={ values.dimensions.height } />
                    <Label htmlFor="height">Height</Label>
                  </div>
                  <div className="each">
                    <input onBlur={handleBlur} onChange={handleChange} name="dimensions.depth" id="depth"
                           type="number" min="0" value={ values.dimensions.depth } />
                    <Label htmlFor="depth">Depth</Label>
                  </div>
                </div>
                <Error error={errors.dimensions?.width || errors.dimensions?.height || errors.dimensions?.depth}
                       touched={touched.dimensions?.width || touched.dimensions?.height || touched.dimensions?.depth} />
              </div>
              <div className="code">
                <Label htmlFor="description">Description</Label>
                <div className="stuff">
                  <textarea onBlur={handleBlur} onChange={handleChange} name="description" id="description"
                        value={values.description} />
                </div>
                <Error error={errors.description} touched={touched.description} />
              </div>
              <div className="code">
                <Label htmlFor="imageUrl">Image URL</Label>
                <div className="stuff">
                  <input type="text" onBlur={handleBlur} onChange={handleChange} name="imageUrl" id="imageUrl"
                        value={values.imageUrl} />
                </div>
                <Error error={errors.imageUrl} touched={touched.imageUrl} />
              </div>
              <div className="code">
                <Label htmlFor="emailField">Email Field</Label>
                <div className="stuff">
                  <textarea onBlur={handleBlur} onChange={handleChange} name="emailField" id="emailField"
                            value={values.emailField} />
                </div>
                <Error error={errors.emailField} touched={touched.emailField} />
              </div>
              <div className="code">
                <Label htmlFor="description">Sort Order</Label>
                <div className="stuff">
                  <input type="number" min="0" onBlur={handleBlur} onChange={handleChange} name="sortOrder" id="sortOrder"
                        value={values.sortOrder} />
                </div>
              </div>
              <div className="code">
                <Label htmlFor="description">Charge return fee?</Label>
                <div className="stuff">
                  <input type="checkbox" onBlur={handleBlur} onChange={handleChange} name="returnFee" id="returnFee"/>
                </div>
              </div>
               <div className="code">
                <Label htmlFor="description">Charge shipping fee?</Label>
                <div className="stuff">
                  <input type="checkbox" onBlur={handleBlur} onChange={handleChange} name="chargeShipping" id="chargeShipping"/>
                </div>
              </div>
            </div>

            <SubmitButton disabled={!isValid || !dirty}>
              Submit
            </SubmitButton>
          </form>
      }
    </Formik>
  </Modal>;
}


interface RowProps extends RouteProps {
  product: Product;
  count: ProductAndCount;
}

function Row({product}: RowProps): JSX.Element {
  const history = useHistory();

  return(<>
    <div className="tr" onClick={() => history.push(`/admin/product/${product.id}`, {product: product})}>
      <Label>Sort Order</Label>
      <div className="td small">{product.sortOrder}</div>
      <Label>Image</Label>
      <div className="td small img"><img src={product.imageUrl} alt=""/></div>
      <Label>ID</Label>
      <div className="td small">{product.id}</div>
      <Label>Name</Label>
      <div className="td large">{product.name}</div>
      <Label>Email Field</Label>
      <div className="td large">{product.emailField}</div>
      <Label>Created At</Label>
      <div className="td mid">
        {(product.createdAt && Moment(product.createdAt).format("MMM Do YYYY")) || "N/A"}
      </div>
      <Label>Updated At</Label>
      <div className="td mid">
        {(product.updatedAt && Moment(product.updatedAt).format("MMM Do YYYY")) || "N/A"}
      </div>
    </div>
  </>)
}