import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter} from 'react-router';
import { RouteProps } from 'react-router-dom';
import Title from '../../components/Title';
import Label from '../../components/Label';
import { axiosKK } from '../../services/networkRequest';
import { UserResponse, UserListResponse } from '../../models/APIModels';
import Moment from 'moment';
import { useHistory } from 'react-router-dom';
import SmallButton from '../../components/SmallButton';
import SubmitButton from '../../components/SubmitButton';
import UserContext from '../../contexts/UserContext';
import { Role } from '../../utilities/Role';

function AdminUsersListComponent({history}: RouteComponentProps): JSX.Element {
  const [users, setUsers] = useState<UserResponse[]>([]);
  const {user: loggedInUser} = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);

    axiosKK.get<UserListResponse>('/users')
      .then(({data}) => {
        setUsers(data.users);
      });
  }, []);

  return (<>
    <section className="nav-status-bar">
      <div className="status-bar-content">
        <i className="fas fa-arrow-circle-left" />
        <SmallButton onClick={() => {history.push("/admin/dashboard");}}>Back</SmallButton>
      </div>
    </section>
    <Title>Admin Management</Title>
    { loggedInUser?.role === Role.Admin &&
      <section className="search-bar">
        <SubmitButton onClick={() => {history.push('/admin/users/create');}}>+ Create New Admin Account</SubmitButton>
      </section> }
    <section className="admin-all-customers-info">
      <div className="table">
        <div className="thead">
          <div className="tr">
            <div className="td id">ID</div>
            <div className="td date">Created Date</div>
            <div className="td name">First Name</div>
            <div className="td name">Last Name</div>
            <div className="td email">Email</div>
            <div className="td name">Admin Role</div>
          </div>
        </div>
        <div className="tbody">
          {users.map(each => (<UserRow key={each.id} user={each} />))}
        </div>
      </div>
    </section>
  </>);
}

interface RowProps extends RouteProps {
  user: UserResponse;
}

function UserRow({user}: RowProps): JSX.Element {
  const history = useHistory();

  return (
    <div className="tr" onClick={() => history.push(`/admin/user/${user.id}`, {user: user})}>
      <Label>ID</Label>
      <div className="td id">{user.id}</div>
      <Label>Created At</Label>
      <div className="td date">{Moment(user.createdAt).format('dddd, MMM Do YYYY')}</div>
      <Label>First Name</Label>
      <div className="td name">{user.firstName}</div> 
      <Label>Last Name</Label>
      <div className="td name">{user.lastName}</div>
      <Label>Email</Label>
      <div className="td email">{user.email}</div>
      <Label>Admin Role</Label>
      <div className="td name">{user.role}</div>
    </div>
  );
}

export default withRouter(AdminUsersListComponent);