import React from 'react';
import { withRouter } from 'react-router';
import RegisterSection, { RegistrationProps } from '../components/RegisterSection';
import Title from '../components/Title';

interface RegistrationComponentProps extends RegistrationProps {

}

function RegistrationComponent(props: RegistrationComponentProps): JSX.Element {
  return (
    <>
      <Title>Registration</Title>
      <RegisterSection {...props} />
    </>
  );
}

export default withRouter(RegistrationComponent);