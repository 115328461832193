import { useCallback, useEffect, useState } from 'react';
import useGTM from '@elgorditosalsero/react-gtm-hook';

const useGtmEventTracking = () => {
  const {init, sendDataToGTM} = useGTM();
  const [gtmInitialised, setGtmInitialised] = useState(false);

  useEffect(() => {
    const id = process.env.REACT_APP_GTM_ID;
    const dataLayerName = process.env.REACT_APP_GTM_DATA_LAYER_NAME;

    if(!id || !dataLayerName) {
      console.error('GTM_ID or GTM_DATA_LAYER_NAME is not defined');
      return;
    }

    init({
      id: id,
      dataLayerName: dataLayerName,
    });
    setGtmInitialised(true);
    // Do not include event in the dependency list, if event is included then the effect will be called every time the
    // page is rendered, whereas we only want to call it once per event.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, gtmInitialised]);

  return useCallback((event: any) => {
    if (gtmInitialised) {
      sendDataToGTM(event);
    }
  }, [gtmInitialised, sendDataToGTM]);
};

export default useGtmEventTracking;
