import { RouteComponentProps, StaticContext, withRouter, useParams } from 'react-router';
import React, { ChangeEvent, useState, useEffect, useContext } from 'react';
import SubmitButton from '../components/SubmitButton';
import SmallPrint from '../components/SmallPrint';
import Title from '../components/Title';
import AddressLookup, { Address, validateAddress } from '../components/AddressLookup';
import 'react-day-picker/lib/style.css';
import { BookingParams, BoxData, Product, Dimensions } from '../models/APIModels';
import Prices from '../components/Prices';
import NavigationStatus from '../components/NavigationStatus';
import { Route } from '../utilities/Route';
import useGtmEventTracking from '../components/useGtmEventTracking';
import DayPickerInputValue from '../components/edit/DayPickerInputValue';
import { useDateDisabler } from '../utilities/DateDisabler';
import UserContext from '../contexts/UserContext';
import { displayLoginName } from '../components/user/DisplayLoginName';

interface DeliveryForm {
  dontKnowAddress: boolean;
  deliveryDate: Date | null;
  dontKnowDate: boolean;
  deliveryAddress: Address;
  storageRequired: boolean;
}

interface DeliveryProps extends RouteComponentProps<undefined, StaticContext, DeliveryState> {
}

interface DeliveryState {
  boxes: BoxData[];
  collectionAddress: Address;
  deliveryAddress: Address;
  collectionDate: Date;
  storageRequired: boolean;
  suitcases?: Dimensions[];
  suitcaseData?: Product;
}

function validate({deliveryAddress, deliveryDate, dontKnowAddress, dontKnowDate, storageRequired}: DeliveryForm) {
  return (dontKnowAddress || validateAddress(deliveryAddress)) && (!storageRequired || (deliveryDate || dontKnowDate));
}

function DeliveryComponent({history}: DeliveryProps): JSX.Element {
  const deliveryState = history.location.state;

  const { user } = useContext(UserContext);

  const gtmEventTracking = useGtmEventTracking();
  useEffect(() => {
    gtmEventTracking({event: 'booking_step_delivery_address'})
  }, [gtmEventTracking]);
  const [form, setForm] = useState<DeliveryForm>({
    dontKnowAddress: deliveryState.storageRequired,
    deliveryDate: null,
    dontKnowDate: deliveryState.storageRequired,
    deliveryAddress: {},
    storageRequired: deliveryState.storageRequired,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {route} = useParams<BookingParams>();
  const isValid = validate(form);

  const disabledDays = useDateDisabler(deliveryState.deliveryAddress, deliveryState.collectionDate);
  
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    })
  };

  return (<>
    <section className="login-status-bar">
      <div className="status-bar-content">
        <p className="grey"> { displayLoginName( user )} </p>
      </div>
    </section>

    <NavigationStatus step={3} />
    <Title>Return Address</Title>
    <p className="grey">Kit Keeper are experts at storing and shipping boxes all across the UK.</p>
    <form onSubmit={event => {
      setIsSubmitting(true);
      event.preventDefault();
      history.push(`/${route}/confirmation`, {...deliveryState, ...form});
    }}>
      <div className="all-coll-del">
        <AddressLookup address={form.deliveryAddress} setAddress={(deliveryAddress: Address) => {
          setForm({
            ...form,
            deliveryAddress,
          })
        }}
                       componentRestrictions={{country: 'uk'}}>
          {
            deliveryState.storageRequired &&
            <span className="each-tick">
              <label htmlFor="dontKnowAddress">Don't know Address</label>
              <input name="dontKnowAddress" onChange={changeHandler}
                     value={form.dontKnowAddress ? 'true' : 'false'}
                     type="checkbox" checked="checked" />
            </span>
          }
        </AddressLookup>
        {deliveryState.storageRequired &&
        <div className="coll-del-div">
          <div className="title-and-input">
            <div className="title">
              <section>Delivery Date:</section>
              <img alt="" src="/img/calendar-icon.png" />
            </div>

            <DayPickerInputValue
              id="deliveryDate"
              handleStateChange={(day) => setForm({...form, deliveryDate: day as any})}
              disabled={false}
              onChange= {(day) => setForm({...form, deliveryDate: day as any})}
              disabledDays={disabledDays}
            />
            <span className="each-tick">
              <label className="tick-label" htmlFor="dontKnowDate">Don't know Date</label>
              <input name="dontKnowDate" onChange={changeHandler} value={form.dontKnowDate ? 'true' : 'false'}
                     type="checkbox" checked="checked" />
            </span>

          </div>
          <div className="description">
            <p className="grey">
              <span>Note:</span> Your can order your boxes back at any time.
            </p>
          </div>
        </div>}
      </div>
      <Prices boxes={deliveryState.boxes} storageRequired={deliveryState.storageRequired}
              suitcases={ deliveryState?.suitcases } suitcaseShippingCost={ deliveryState?.suitcaseData?.shippingCost } />
      <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
        Next<img alt="" src="/img/right-arrow-circular-button-outline.png" />
      </SubmitButton>
      <SmallPrint storage={ route === Route.storage } isSuitcaseOrder={route === Route.suitcase } />
    </form>
  </>);
}

export default withRouter(DeliveryComponent);