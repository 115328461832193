import axiosBase, { AxiosError } from 'axios';
import { User } from '../contexts/UserContext';

export const isCancel = axiosBase.isCancel;
export const CancelToken = axiosBase.CancelToken;
export const loginPath = '/login';
export const axiosKK = axiosBase.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

function setToken() {
  const storedUser = localStorage.getItem('user');
  const userObject = storedUser && JSON.parse(storedUser);

  if(userObject) {
    axiosKK.defaults.headers.common['Authorization'] = 'bearer ' + userObject.token;
  }
}
setToken();

function updateStoredUser(newState: (user: User) => any) {
  const storedUser = localStorage.getItem('user');
  const userObject = storedUser && JSON.parse(storedUser);
  if (userObject) {
    const updatedUser = newState(userObject);
    localStorage.setItem('user', JSON.stringify(updatedUser));
  }
}

axiosKK.interceptors.response.use(response => {
  const updatedToken = response.data.jwt;
  if (updatedToken) {
    axiosKK.defaults.headers.common['Authorization'] = 'bearer ' + updatedToken;

    updateStoredUser(user => {
      user.token = updatedToken;

      return user;
    })

    // delete response.data.jwt;
  }

  return response;
}, function (error: AxiosError) {
  const status = error.response?.status;

  if (status === 401) {
    updateStoredUser(user => {
      delete user.token;

      return user;
    });
    if(window.location.pathname !== loginPath) {
      window.location.assign(loginPath);
    }
  }

  return Promise.reject(error);
});