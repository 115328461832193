import React, { ReactNode } from 'react';
import { Formik } from 'formik';
import { checkSuitcaseDimension } from './SuitcaseWarningModal';
import Error from './Error';
import SubmitButton from './SubmitButton';
import { Link } from './Link';
import { Dimensions } from '../models/APIModels';

interface SuitcaseCalculatorFormProps {
  updateSuitcases: (suitcase: Dimensions) => void,
  openSuitcaseWarningModal: () => void,
  openSuitcaseWeightWarningModal: (suitcase: Dimensions) => void,
  removeAllItem: () => void,
}

interface SuitcaseInitialValues {
  depth: number | '',
  height: number | '',
  width: number | '',
  weight: number | '',
}

interface SuitcaseCalculationErrors {
  depth?: string,
  height?: string,
  width?: string,
  weight?: string,
}

export function SuitcaseCalculatorForm({ updateSuitcases, openSuitcaseWarningModal, openSuitcaseWeightWarningModal, removeAllItem }: SuitcaseCalculatorFormProps) {

  const initialValues: SuitcaseInitialValues = {
    depth: '', height: '', weight: '', width: ''
  }

  return (
    <Formik initialValues={ initialValues }
            validate={ ({ depth, height, width, weight }: SuitcaseInitialValues) => {
              const errors: SuitcaseCalculationErrors = {};

              if (!depth || depth <= 0) {
                errors.depth = 'Depth must be larger than  0';
              }

              if (!height || height <= 0) {
                errors.height = 'Height must be larger than  0';
              }

              if (!width || width <= 0) {
                errors.width = 'Width must be larger than  0';
              }

              if (!weight || weight <= 0) {
                errors.weight = 'Weight must be larger than  0';
              } else if (weight > 30) {
                errors.weight = 'The maximum weight limit is 30kg'
              }

              return errors;
            } }
            onSubmit={ (values) => {

              const finalValues: Dimensions = {
                depth: values.depth || 0,
                height: values.height || 0,
                weight: values.weight || 0,
                width: values.width || 0,
              }

              if(!checkSuitcaseDimension(finalValues)) {
                openSuitcaseWarningModal();
              } else if (finalValues.weight > 20) {
                openSuitcaseWeightWarningModal(finalValues);
              } else {
                updateSuitcases(finalValues);
              }

            } }>
      {
        ({ values,
           handleChange,
           handleBlur,
           handleSubmit,
           isValid,
           dirty,
           errors,
           touched,
           resetForm
         }) => (
          <form onSubmit={ handleSubmit } name="suitcase-calculator">
            <InputContainer label="Depth (cm)" descriptiveImgSrc="/img/suitcase-depth.png">
              <input type="number" id="depth" name="depth" min="0" value={ values.depth } onChange={ handleChange } onBlur={ handleBlur } />
            </InputContainer>
            <Error error={ errors.depth } touched={ touched.depth } removeBreak />

            <InputContainer label="Height (cm)" descriptiveImgSrc="/img/suitcase-height.png">
              <input type="number" id="height" name="height" min="0" value={ values.height } onChange={ handleChange } onBlur={ handleBlur } />
            </InputContainer>
            <Error error={ errors.height } touched={ touched.height } removeBreak />

            <InputContainer label="Width (cm)" descriptiveImgSrc="/img/suitcase-width.png">
              <input type="number" id="width" name="width" min="0" value={ values.width } onChange={ handleChange } onBlur={ handleBlur } />
            </InputContainer>
            <Error error={ errors.width } touched={ touched.width } removeBreak />

            <InputContainer label="Weight (kg)" descriptiveImgSrc="/img/suitcase-weight.png">
              <input type="number" id="weight" name="weight" min="0" value={ values.weight } onChange={ handleChange } onBlur={ handleBlur } />
            </InputContainer>
            <Error error={ errors.weight } touched={ touched.weight } removeBreak />

            <SubmitButton disabled={ !isValid || !dirty }>
              Add item
            </SubmitButton>

            <Link onClick={ () => resetForm() }>Reset</Link>
            <br />
            <br />
            <Link onClick={ removeAllItem }>Remove All Items</Link>
          </form>
        )
      }
    </Formik>
  )
}

interface InputContainerProps {
  children: ReactNode,
  label: string,
  descriptiveImgSrc: string,
}

function InputContainer({ children, label, descriptiveImgSrc }: InputContainerProps) {

  return (
    <div className="suitcase-calculator-input-container">
      <h5>{ label }:</h5>
      { children }
      <div className="img-container">
        <img src={ descriptiveImgSrc } alt={`Description for ${ label }`} />
      </div>
    </div>
  )
}