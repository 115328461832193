import React from 'react';
import Modal from 'react-modal';
import SubmitButton from './SubmitButton';
import { axiosKK } from '../services/networkRequest';

const customStyles = {
  content: {
    display: 'flex',
  },
};

interface ConfirmationModalProps {
  show: boolean;
  close: () => void;
  success: () => void;
  failure: () => void;
  id: number | string;
  directory: string;
  warningMessage: string;
}

export default function ConfirmationModal({show, close, id, directory, warningMessage, success, failure}: ConfirmationModalProps): JSX.Element {

  return (<Modal
    isOpen={show}
    onRequestClose={close}
    style={customStyles}
    contentLabel="Confirmation Modal"
  >
    <section className="confirmation-modal">
      <div className="title">
        <h3>Are You Sure?</h3>
      </div>
      <div className="content">
        <p>{warningMessage}</p>
      </div>
        <div className="buttons">
          <SubmitButton onClick={() => {
            axiosKK.put(`${directory}${id}`)
              .then(() => {
                success();
              })
              .catch(() => {
                failure();
              });
            }}>
            <i className="fas fa-check" /> Yes
          </SubmitButton>
          <SubmitButton onClick={close}>
            No
          </SubmitButton>
        </div>
    </section>
  </Modal>)
}