import React from 'react';
import Modal from 'react-modal';
import SubmitButton from '../components/SubmitButton';

interface PreselectedBoxesModalProps {
  close: () => void,
  onSelectPredefinedBoxes: (preselectedBoxes: object) => void,
}

const customStyles = {
  content: {
    display: 'flex',
    maxWidth: '1000px',
  },
};

export function PreselectedBoxesModal({ close, onSelectPredefinedBoxes }: PreselectedBoxesModalProps): JSX.Element {

  return (
    <Modal
      isOpen={ true }
      onRequestClose={ close }
      style={ customStyles }
      contentLabel="Preselected Boxes Modal"
    >
      <section className="preselected-boxes-modal">
        <SubmitButton red={true} onClick={ close }>
          <i className="fas fa-times" />
        </SubmitButton>
        <h3>Choose one of our recommended student storage packages, designed for all UK students.</h3>

        <section className="selections">
          <PredefinedBox title="Small Room"
                         description="Recommended for students with single rooms who are taking some of their belongings home."
                         imgSrc='../../img/room-small.png'
                         onClick={ () => onSelectPredefinedBoxes({
                           1: 1, 2: 2, 3: 1, 5: 1, 7: 1, 9: 2
                         }) }
          />
          <PredefinedBox title="Medium Room"
                         description="Perfect for university students heading back home."
                         imgSrc='../../img/room-medium.png'
                         onClick={ () => onSelectPredefinedBoxes({
                           1: 1, 2: 2, 3: 2, 4: 2, 5: 3, 7: 1, 9: 3
                         }) }
                         isMostPopular
          />
          <PredefinedBox title="Large Room"
                         description="Ideal for international students going home for the Summer or if you're taking a year abroad."
                         imgSrc='../../img/room-large.png'
                         onClick={ () => onSelectPredefinedBoxes({
                           1: 1, 2: 4, 3: 3, 4: 2, 5: 6, 7: 2, 9: 5
                         }) }
          />
        </section>
      </section>
    </Modal>
  )
}

interface PredefinedBoxProps {
  title: string,
  description: string,
  isMostPopular?: boolean,
  imgSrc: string,
  onClick: () => void,
}

function PredefinedBox({ title, description, isMostPopular, imgSrc, onClick }: PredefinedBoxProps): JSX.Element {

  return (
    <div className={`predefined-box ${ isMostPopular ? 'popular' : '' }`} onClick={ onClick }>
      {
        isMostPopular &&
        <div className="most-popular-container">
          <h3>Most popular</h3>
        </div>
      }
      <main>
        <h4>{ title }</h4>
        <p>{ description }</p>
        <div className="img-container">
          <img src={ imgSrc } alt={`Predefined selection: ${ title }`} />
        </div>
      </main>
      <div className="select-container">
        <h3>Select pack</h3>
      </div>
    </div>
  )
}