import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps, StaticContext, withRouter, useParams } from 'react-router';
import SubmitButton from '../components/SubmitButton';
import SmallPrint from '../components/SmallPrint';
import Title from '../components/Title';
import AddressLookup, { Address, testPostCode, validateAddress } from '../components/AddressLookup';
import 'react-day-picker/lib/style.css';
import { BoxData, Product, Dimensions } from '../models/APIModels';
import Prices from '../components/Prices';
import NavigationStatus from '../components/NavigationStatus';
import { Route } from '../utilities/Route';
import useGtmEventTracking from '../components/useGtmEventTracking';
import DayPickerInputValue  from '../components/edit/DayPickerInputValue';
import { useDateDisabler } from '../utilities/DateDisabler';
import { displayLoginName } from '../components/user/DisplayLoginName';
import UserContext from '../contexts/UserContext';

interface CollectionProps extends RouteComponentProps<undefined, StaticContext, CollectionState> {
}

interface CollectionState {
  boxes: BoxData[];
  collectionAddress: Address;
  collectionDate: Date;
  storageRequired: boolean;
  suitcases?: Dimensions[];
  suitcaseData?: Product;
}

function validate(collectionAddress: Address, collectionDate: Date | undefined) {
  return validateAddress(collectionAddress) && collectionDate;
}

interface CollectionParams {
  route: string;
}

function CollectionComponent({history}: CollectionProps): JSX.Element {
  const [collectionAddress, setCollectionAddress] = useState<Address>({});
  const [collectionDate, setCollectionDate] = useState<Date>();
  const isValid = validate(collectionAddress, collectionDate);

  const {route} = useParams<CollectionParams>();
  const {boxes, storageRequired, suitcases, suitcaseData} = history.location.state;

  const disabledDays = useDateDisabler(null, null);

  const { user } = useContext(UserContext);

  const gtmEventTracking = useGtmEventTracking();
  useEffect(() => {
    gtmEventTracking({event: 'booking_step_collection_address'})
  }, [gtmEventTracking]);

  return (
    <>
      <section className="login-status-bar">
        <div className="status-bar-content">
          <p className="grey"> { displayLoginName( user )} </p>
        </div>
      </section>

      <NavigationStatus step={2} />
      <Title>Collection Address</Title>
      {route !== 'suitcase' && <p className="grey">
        We'll collect your belongings from here <br />
        This is where your packing materials will be delivered 📦
      </p>}
      <form onSubmit={event => {
        event.preventDefault();
        validate(collectionAddress, collectionDate) && history.push(`/${route}/delivery_address`, {
          boxes,
          collectionAddress,
          collectionDate: collectionDate!!,
          storageRequired,
          suitcases,
          suitcaseData,
        });
      }}>
        <div className="all-coll-del">
          <AddressLookup address={collectionAddress} setAddress={setCollectionAddress}
                         componentRestrictions={{country: 'uk'}} />
          <div className="coll-del-div">
            <div className="title-and-input">
              <div className="title">
                <section>Collection Date:</section>
                <img alt="" src="/img/calendar-icon.png" />
              </div>
              
              <DayPickerInputValue
                id="collectionDate"
                handleStateChange={((day) => setCollectionDate(day as any))}
                disabled={false}
                disabledDays={disabledDays}
              />
            </div>
            <div className="description">
              {
                !suitcases &&
                <p className="grey">
                  <span>Note:</span> Your empty boxes will be delivered here.
                </p>
              }
              <p className="grey">
                For collection, please make sure your boxes are on the ground floor.
              </p>
            </div>
          </div>

        </div>

        <p > {testPostCode(collectionAddress)}</p>


        <Prices boxes={ boxes } storageRequired={ storageRequired } suitcases={ suitcases }
                suitcaseShippingCost={ suitcaseData?.shippingCost } />

        <SubmitButton disabled={!isValid}>
          Next<img alt="" src="/img/right-arrow-circular-button-outline.png" />
        </SubmitButton>
        <SmallPrint storage={storageRequired} isSuitcaseOrder={ route === Route.suitcase } />
      </form>
    </>
  );
}


export default withRouter(CollectionComponent);