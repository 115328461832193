import React, { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import SmallButton from '../../components/SmallButton';
import Error from '../../components/Error';
import UserContext, { User } from '../../contexts/UserContext';
import { axiosKK } from '../../services/networkRequest';
import Modal from 'react-modal';
import { Formik } from 'formik';
import SubmitButton from '../../components/SubmitButton';
import Label from '../../components/Label';
import { PhoneNumberUtil } from 'google-libphonenumber';
import Moment from 'moment';
import { getChain } from '../../utilities/GetChain';

const emailRegExp = /^([^\x00-\x7F]|[a-zA-Z0-9_\-.])+@([^\x00-\x7F]|[a-zA-Z0-9_-])+\.([^\x00-\x7F]|[a-zA-Z0-9_-]){2,}(\.([^\x00-\x7F]|[a-zA-Z0-9_-]){2,})?/g;

const phoneUtil = PhoneNumberUtil.getInstance();

function ProfileComponent(): JSX.Element {
  const history = useHistory();
  const [editPersonalDetailsMode, setEditPersonalDetailsMode] = useState(false);
  const [error, setError] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <UserContext.Consumer>
      {({user, updateUser}) => (
        <>
          <section className="nav-status-bar">
            <div className="status-bar-content">
              <i className="fas fa-arrow-circle-left" />
              <SmallButton onClick={() => {history.push('/dashboard');}}>Back</SmallButton>
            </div>
          </section>
          <Title>Manage My Profile</Title>
          <section className="admin-customer-profile">
            <section className="all-content">
              <div className="name-and-date">
                <div className="name"><h3>{user?.firstName}</h3><h3>{user?.lastName}</h3></div>
                <div className="last-updated"><p>Last updated : {Moment(user?.updatedAt).format('dddd, MMM Do YYYY')}</p></div>
              </div>
              <div className="line" />
              <div className="content">
                <Label>Email</Label>
                <span>{user?.email}</span>
                <Label>Phone</Label>
                <span>{user?.phoneNumber}</span>
                <Label>Account Created At: </Label>
                <span>{Moment(user?.createdAt).format('dddd, MMM Do YYYY')}</span>
              </div>
            </section>
          </section>
          <section className="option-box-container">
            <div className="each-box" onClick={() => {
              setEditPersonalDetailsMode(true);
            }}>
              <div className="img"><i className="fas fa-sign-in-alt" /></div>
              <div className="content">
                <h5>Personal Details and Login</h5>
                <p>Edit name, mobile number and login details</p>
              </div>
            </div>
            <div className="each-box" onClick={() => {
              history.push('/update-payment-method');
            }}>
              <div className="img"><i className="far fa-credit-card" /></div>
              <div className="content">
                <h5>Update Payment Method</h5>
                <p>update payment method for recurring payments</p>
              </div>
            </div>
          </section>
          { user && <PersonalDetailsEditModal
            editMode={editPersonalDetailsMode}
            user={user}
            close={() => setEditPersonalDetailsMode(false)}
            updateUser={updateUser}
            setError={setError}
          />}
          {error && <span className="error">{error}</span>}
        </>
      )}
    </UserContext.Consumer>
  );
}

interface NewPersonalDetailsEditErrors {
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string;
}

interface PersonalDetailsEditModalProps {
  updateUser: (user: User) => void,
  close: () => void;
  editMode: boolean;
  user: User;
  setError: Dispatch<SetStateAction<null>>;
}

function PersonalDetailsEditModal({editMode, user, close, updateUser, setError}: PersonalDetailsEditModalProps): JSX.Element {

  const initialValues: User = {
    ...user
  }

  return(<Modal
    isOpen={editMode}
    onRequestClose={close}
    style={{  content: {
        display: 'flex',
      }}}
    contentLabel="Edit Personal Details"
  >
    <Formik
      initialValues={initialValues}
      validate={
        ({
          email,
          firstName,
          lastName,
          phoneNumber,
        }) => {
          const errors: NewPersonalDetailsEditErrors = {};

          if(email.length < 5) {
            errors.email = 'Email Address is too short';
          } else if(!email.match(emailRegExp)?.includes(email)) {
            errors.email = 'Email Address is Invalid';
          }

          if(!firstName || firstName.length < 2) {
            errors.firstName = 'First & Last Name must be at least 2 characters';
          }

          if(!lastName || lastName.length < 2) {
            errors.lastName = 'First & Last Name must be at least 2 characters';
          }

          if(phoneNumber.length < 9) {
            errors.phoneNumber = 'Phone Number is Too Short';
          } else {
            ValidatePhoneNumber(phoneNumber);
          }

          function ValidatePhoneNumber(phoneNumber: string) {
            const UKPhoneNumber = phoneUtil.parse(phoneNumber, 'GB');
            
            if(!phoneUtil.isValidNumber(UKPhoneNumber)) {
              const ITPhoneNumber = phoneUtil.parse(phoneNumber, 'IT');
              // const getCountryCode = ITPhoneNumber.getCountryCode();
              
              if(!phoneUtil.isValidNumber(ITPhoneNumber)) {
                errors.phoneNumber = 'Phone Number is Invalid';
              }
            }
          }

          return errors;
        }
      }
      onSubmit={(values, {setSubmitting}) => {
        setSubmitting(true);
        axiosKK.put<{user: User}>('/customer/update', values)
          .then(() => {
            updateUser(values);
          })
          .catch(e => setError(getChain(e, "response", "data", "error")))
          .finally(() => {
            setSubmitting(false);
          });
        close();
      }}
    >
      {
        ({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          errors,
          touched,
          isSubmitting,
          isValid,
        }) => 
          <form onSubmit={handleSubmit} name="personal-details-edit-form" className="personal-details-edit-form">
            <div className="modal-header">
              <h3>Edit Personal Details</h3>
              <SubmitButton red={true} onClick={close}>Close</SubmitButton>
            </div>
            <div className="modal-content">
              <div className="user-edit-inputs">
                <Label htmlFor="first-last-name">Name</Label>
                <div className="stuff">
                  <div className="each">
                    <input className="name-input" type="text" name="firstName" value={values.firstName} onBlur={handleBlur} onChange={handleChange} />
                    <label className="user-edit-label">First Name</label>
                  </div>
                  <div className="each">
                    <input className="name-input" type="text" name="lastName" value={values.lastName} onBlur={handleBlur} onChange={handleChange} />
                    <label className="user-edit-label">Last Name</label>
                  </div>
                </div>
                <Error touched={touched.firstName || touched.lastName} error={errors.firstName || errors.lastName} />
              </div>
              <div className="user-edit-inputs">
                <Label htmlFor="email">Email Address</Label>
                <div className="stuff">
                  <input className="email-input" type="text" name="email" value={values.email} onBlur={handleBlur} onChange={handleChange} />
                </div>
                <Error touched={touched.email} error={errors.email} />
              </div>
              <div className="user-edit-inputs">
                <Label htmlFor="email">Phone Number</Label>
                <label className="log-and-reg-label" htmlFor="phoneNumber">Phone (UK Number or International with Country Code)</label>
                <div className="stuff">
                  <input className="phoneNumber-input" type="text" name="phoneNumber" value={values.phoneNumber} onBlur={handleBlur} onChange={handleChange} />
                </div>
              </div>
              <Error touched={touched.phoneNumber} error={errors.phoneNumber} />
            </div>
            <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
              Update
            </SubmitButton>
          </form>
      }
    </Formik>
  </Modal>)
}

export default withRouter(ProfileComponent);