import React from 'react';

/*interface TitleMontserratProps extends React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  children: string,
}*/



export default function TitleMontserrat({children}: TitleProps): JSX.Element {
  const [first, ...rest] = children.split(' ');

  return (
    <h2 className="blue-black-title-Montserrat">
      <span>{first}</span>
      &nbsp;
      {rest.join(' ')}
    </h2>);
}

