import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router';
import { axiosKK } from '../services/networkRequest';
import { BoxData, Product, Dimensions } from '../models/APIModels';
import { Formik } from 'formik';
import SubmitButton from '../components/SubmitButton';
import SmallPrint from '../components/SmallPrint';
import Title from '../components/Title';
import Box from '../components/Box';
import Prices from '../components/Prices';
import Loading from '../components/Loading';
import NavigationStatus from '../components/NavigationStatus';
import { Route } from '../utilities/Route';
import { ShippingPriceDetails } from '../components/ShippingSpecificComponents';
import { SuitcaseMobileViewTable, SuitcaseTable } from '../components/SuitcaseTable';
import { DimensionWarningModal, WeightWarningModal } from '../components/SuitcaseWarningModal';
import { useViewport, Width } from '../utilities/Viewport';
import { SuitcaseCalculatorForm } from '../components/SuitcaseCalculator';
import useGtmEventTracking from '../components/useGtmEventTracking';
import { useHistory } from 'react-router-dom';
import { PreselectedBoxesModal } from '../components/PreselectedBoxesModal';
import { displayLoginName } from '../components/user/DisplayLoginName';
import UserContext from '../contexts/UserContext';

interface BoxForm {
  boxes: BoxData[];
}

interface BoxesApiResponse {
  message: string;
  products: BoxData[];
}

// interface BoxesProps extends RouteComponentProps<undefined, StaticContext, BoxesState> {}

// interface BoxesState {
//   storageRequired: boolean;
//   boxes: BoxData[];
//   suitcases?: Dimensions[];
//   suitcaseData?: Product,
// }

interface BoxFormErrors {
  boxes?: string;
}

interface BoxesParams {
  route: Route;
}

function BoxesComponent(): JSX.Element {
  const [boxes, setBoxes] = useState<BoxData[]>([]);
  const { route } = useParams<BoxesParams>();

  const [suitcaseData, setSuitCaseData] = useState<Product>();

  const [suitcases, setSuitcases] = useState<Dimensions[]>([]);

  const isSuitcaseOrder = route === Route.suitcase;

  const { user } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);

    if(isSuitcaseOrder) {
      axiosKK.get<{ product: Product }>('/boxes/4')
        .then(response => {
          setSuitCaseData(response.data.product);
        })
        .catch(console.error);

    } else {
      axiosKK.get<BoxesApiResponse>('/products/price')
        .then(response => {
          setBoxes(response.data.products.map(each => {
            each.count = 0;
            return each;
          }));
        })
        .catch(console.error);
    }
  }, [isSuitcaseOrder]);

  return (<>
    <section className="login-status-bar">
      <div className="status-bar-content">
        <p className="grey"> { displayLoginName( user )} </p>
      </div>
    </section>
    <NavigationStatus step={1} />
    {

      isSuitcaseOrder ?
      <SuitcaseSelection suitcases={ suitcases }
                         suitcaseData={ suitcaseData }
                         updateSuitcases={ (suitcase) => {
                           setSuitcases(prevState => ([...prevState, suitcase]));
                         } }
                         removeItem={ (idx) => {
                           setSuitcases(prevState => {
                             const newState = prevState.slice();
                             newState.splice(idx, 1);
                             return newState;
                           })
                         } }
                         removeAllItem={ () => setSuitcases([]) }
      /> :
      <StorageOrShippingSelection boxes={ boxes } setBoxes={ setBoxes } route={ route }  />
    }
  </>);
}

interface SuitcaseSelectionProps {
  suitcases: Dimensions[],
  suitcaseData?: Product,
  updateSuitcases: (suitcase: Dimensions) => void,
  removeItem: (idx: number) => void,
  removeAllItem: () => void,
}

interface SuitcaseWarningModalProps {
  dimension: boolean,
  weight?: Dimensions,
}

function SuitcaseSelection({ updateSuitcases, suitcases, removeAllItem, suitcaseData, ...props }: SuitcaseSelectionProps) {
  const { width } = useViewport();

  const gtmEventTracking = useGtmEventTracking();
  useEffect(() => {
    gtmEventTracking({event: 'booking_step_suitcase'})
  }, [gtmEventTracking]);

  const history = useHistory();

  const [suitcaseWarningModal, setSuitcaseWarningModal] = useState<SuitcaseWarningModalProps>({
    dimension: false, weight: undefined,
  });

  return (
    <>
      <Title>Custom Size, tell us more...</Title>
      <p className="grey">We just need a few measurements to get you a price for your custom items.</p>
      <div className="suitcase-selection-component">
        <section className="items-to-send-calculator">
          <div className="title-section">
            <h3>Items to Send</h3>
          </div>
          <div className="calculator">
            <SuitcaseCalculatorForm updateSuitcases={ updateSuitcases }
                                    openSuitcaseWeightWarningModal={ (suitcase) => setSuitcaseWarningModal(prevState => ({ ...prevState, weight: suitcase })) }
                                    openSuitcaseWarningModal={ () => setSuitcaseWarningModal(prevState => ({ ...prevState, dimension: true })) }
                                    removeAllItem={ removeAllItem }
            />
          </div>
        </section>
        <section className="suitcase-table-container">
          {
            width < Width.Tablet ?
            <SuitcaseMobileViewTable suitcases={ suitcases } suitcaseShippingCost={ suitcaseData?.shippingCost } { ...props } /> :
            <SuitcaseTable suitcases={ suitcases } suitcaseShippingCost={ suitcaseData?.shippingCost } { ...props } />
          }
        </section>
      </div>

      <div className="suitcase-price-and-next">
        <Prices boxes={[]} storageRequired={ false } suitcases={ suitcases } suitcaseShippingCost={ suitcaseData?.shippingCost } />

        <SubmitButton disabled={ suitcases.length <= 0 }
                      onClick={ () => {
                        history.push(`/suitcase/collection_address`, {
                          boxes: [], storageRequired: false, suitcases, suitcaseData
                        });
                      } }>
          Next<img alt="" src="/img/right-arrow-circular-button-outline.png"/>
        </SubmitButton>
        <SmallPrint isSuitcaseOrder />
      </div>

      {
        suitcaseWarningModal.dimension &&
        <DimensionWarningModal close={ () => setSuitcaseWarningModal(prevState => ({ ...prevState, dimension: false })) } />
      }
      {
        suitcaseWarningModal.weight &&
        <WeightWarningModal close={ () => setSuitcaseWarningModal(prevState => ({ ...prevState, weight: undefined })) } 
                            mainButtonAction={ () => {
                              updateSuitcases(suitcaseWarningModal.weight!);
                              setSuitcaseWarningModal(prevState => ({ ...prevState, weight: undefined }));
                            } }
        />
      }
    </>
  )
}

interface StorageOrShippingSelectionProps {
  route: Route;
  boxes: BoxData[],
  setBoxes: Dispatch<SetStateAction<BoxData[]>>,
}

function StorageOrShippingSelection({ route, boxes, setBoxes }: StorageOrShippingSelectionProps) {

  const history = useHistory();
  const initialValues: BoxForm = {
    boxes: [],
  };

  const storageRequired = route.includes(Route.storage);
  const effect = storageRequired ? 'booking_step_storage' : 'booking_step_ship';

  const gtmEventTracking = useGtmEventTracking();
  useEffect(() => {
    gtmEventTracking({event: effect});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtmEventTracking]);

  return (
    <>
      <Title>{`What do you want to ${storageRequired ? "store" : "ship"}?`}</Title>
      {
        storageRequired ?
        <SubmitButton type="button"
                      onClick={ () => {
                        history.push(`/storage/boxes/packages`);
                      } }>
          Suggested Packages
        </SubmitButton> :
        <p className="grey">Kit Keeper are experts at storing and shipping boxes all across the UK.</p>
      }
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        validate={values => {
          const errors : BoxFormErrors = {};

          if(!values.boxes || values.boxes.length === 0 || values.boxes.reduce((acc, each) => each.count + acc, 0) === 0) {
            errors.boxes = "No boxes selected";
          }

          return errors;
        }}
        onSubmit={() => history.push(`/${route}/collection_address`, {boxes: boxes.filter(value => value.count), storageRequired})}
        validateOnChange={true}
      >
        {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldValue,
          }) => (
          <form onSubmit={handleSubmit}>
            <section className="boxes-and-cost-section">
              <div className="all-items">
                {
                  boxes && boxes.length ?
                  boxes.sort((a, b) => a.sortOrder - b.sortOrder).map((box, index) =>
                    // First time after closing the modal, on change item use reducer to combine the counts of preselected & new item count,
                    // afterwards just use boxes
                    <Box key={box.id} box={ { ...box, count: values.boxes.find(b => b.id === box.id)?.count || box.count } }
                         setBox={ boxChange(index, boxes, (boxes) => {
                           setBoxes(boxes);
                         }, setFieldValue)}
                         handleChange={handleChange} handleBlur={handleBlur} storageRequired={storageRequired} />,
                  ) :
                  <Loading />
                }
              </div>
              {storageRequired ? null : <ShippingPriceDetails boxes={boxes} />}
            </section>
            {storageRequired ? <Prices boxes={values.boxes} storageRequired={storageRequired} /> : null}
            <SubmitButton disabled={!isValid}  isSubmitting={isSubmitting}>
              Next<img alt="" src="/img/right-arrow-circular-button-outline.png"/>
            </SubmitButton>
            <SmallPrint storage={storageRequired} />
            {
              history.location.pathname === '/storage/boxes/packages' &&
              <PreselectedBoxesModal close={ () => {
                history.push('/storage/boxes');
              } }
                                     onSelectPredefinedBoxes={ preselectedBoxes => {
                                       boxes.forEach((box) => {
                                         box.count = 0;
                                       });

                                       for(const [key, value] of Object.entries(preselectedBoxes)) {
                                         const box = boxes.find(box => box.id === Number(key));

                                         // If box is already in newState, add new value
                                         if (box) {
                                           box.count = value;
                                         }
                                       }

                                       setFieldValue("boxes", boxes, true);
                                       history.push('/storage/boxes');
                                     } }
              />
            }
          </form>
        )}
      </Formik>
    </>
  )
}

function boxChange(i: number, boxes: BoxData[], setBoxes: (boxes: BoxData[]) => void,
                   setFieldValue: (field: string,
                                   value: any,
                                   shouldValidate?: boolean) => void) : (box: BoxData) => BoxData {
  return (box: BoxData) => {
    boxes[i] = box;
    setBoxes(boxes);

    setFieldValue("boxes", boxes, true);

    return box;
  };
}

export default withRouter(BoxesComponent);