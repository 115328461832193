import { toMoney } from '../utilities/MoneyConversion';
import { Link } from './Link';
import React from 'react';
import Label from './Label';
import { Dimensions } from '../models/APIModels';

interface SuitcaseTableProps {
  suitcases: Dimensions[],
  removeItem: (idx: number) => void,
  suitcaseShippingCost?: number,
}

function volumetricWeight (suitcase: Dimensions) {
  const { width, depth, height } = suitcase;
  return (width * depth * height) / 5000;
}

export function SuitcaseTable({ suitcases, removeItem, ...props }: SuitcaseTableProps) {

  return (
    <div className="table suitcase-table">
      <div className="thead">
        <div className="tr">
          <div className="td">Dimensions (cm)</div>
          <div className="td">Weight (kg)</div>
          <div className="td">Volumetric Weight (kg)</div>
          <div className="td">Price</div>
          <div className="td">Remove</div>
        </div>
      </div>
      <div className="tbody">
        { suitcases.map((suitcase, i) =>
          <Row key={ i } suitcase={ suitcase } removeItem={ () => removeItem(i) } { ...props } />) }
      </div>
    </div>
  )
}

interface RowProps {
  suitcase: Dimensions,
  removeItem: () => void,
  suitcaseShippingCost?: number,
}

function Row({ suitcase, removeItem, suitcaseShippingCost }: RowProps) {

  const { width, depth, height, weight } = suitcase;

  return (
    <div className="tr">
      <div className="td">{ depth } x { height } x { width }</div>
      <div className="td">{ weight }</div>
      <div className="td">{ volumetricWeight(suitcase) }</div>
      <div className="td">{ suitcaseShippingCost && toMoney(suitcaseShippingCost) }</div>
      <div className="td"><Link onClick={ removeItem }>Remove</Link></div>
    </div>
  )
}

export function SuitcaseMobileViewTable({ suitcases, removeItem, ...props }: SuitcaseTableProps) {

  return (
    <div className="suitcase-mobile-view-table">
      {
        suitcases.map((suitcase, idx) =>
          <MobileRow idx={ idx } suitcase={ suitcase } removeItem={ () => removeItem(idx) } key={ idx } { ...props } /> )
      }
    </div>
  )
}

interface MobileRowProps extends RowProps {
  idx: number
}

export function MobileRow({ idx, suitcase, removeItem, suitcaseShippingCost }: MobileRowProps) {

  const { height, weight, width, depth } = suitcase;

  return (
    <div className="mobile-row">
      <Label>Item { idx + 1 }</Label>

      <ul>
        <li>
          <span className="title">Dimensions (cm)</span>
          <h4 className="blue">{ depth } x { height } x { width }</h4>
        </li>
        <li>
          <span className="title">Weight (kg)</span>
          <h4 className="blue">{ weight }</h4>
        </li>
        <li>
          <span className="title">Volumetric Weight (kg)</span>
          <h4 className="blue">{ volumetricWeight(suitcase)  }</h4>
        </li>
        <li>
          <span className="title">Price</span>
          <h4 className="blue">{ suitcaseShippingCost && toMoney(suitcaseShippingCost) }</h4>
        </li>
        <li>
          <Link color="#008dc9" onClick={ removeItem }>Remove Item</Link>
        </li>
      </ul>
    </div>
  )
}