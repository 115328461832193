import { AxiosError } from 'axios';
import { Dispatch, SetStateAction } from 'react';

export default function errorMessage(setNetworkError: Dispatch<SetStateAction<string | undefined>>) {
  return (error: string | Error | AxiosError) => {
    const jsonError = JSON.parse(JSON.stringify(error));
    let errorMessage: string;

    if(jsonError.hasOwnProperty('config')) {
      errorMessage = (error as AxiosError).response?.data.error || (error as AxiosError).response?.data.message;
    } else if(jsonError.hasOwnProperty('message')) {
      errorMessage = (error as Error).message;
    } else {
      errorMessage = error as string;
    }

    console.error(errorMessage);
    setNetworkError(errorMessage);
  };
}