import React from 'react';
import Modal from 'react-modal';
import SubmitButton from './SubmitButton';
import { Dimensions } from '../models/APIModels';
import { useViewport } from '../utilities/Viewport';
import { Width } from '../utilities/Viewport';

interface SuitcaseWarningModalProps {
  close: () => void,
}

export function DimensionWarningModal({ close }: SuitcaseWarningModalProps) {

  const { width } = useViewport();

  return (
    <Modal
      isOpen={ true }
      onRequestClose={ close }
      style={{
        content: {
          display: 'flex',
        },
      }}
      contentLabel="Dimension Warning Modal"
    >
      <div className="suitcase-warning-modal">
        <section>
          <h3>Dimension Warning</h3>
          <h5 className="bold">Non - Conveyable Parcel</h5>

          <p>
            The longest dimension can not be more than 160cm and the other two be more than 120cm combined.
          </p>
          {
            width > Width.Tablet &&
            <SubmitButton type="button" onClick={ close }>
              OK I understand
            </SubmitButton>
          }
        </section>
        <section>
          <div className="img-container">
            <img src="/img/suitcase-size-limit.png" alt="Suitcase size limit" />
          </div>
        </section>
      </div>
      {
        width <= Width.Tablet &&
        <SubmitButton type="button" onClick={ close }>
          OK I understand
        </SubmitButton>
      }

    </Modal>
  )
}

export interface WeightWarningModalProps extends SuitcaseWarningModalProps {
  mainButtonAction: () => void,
}

export function WeightWarningModal({ close, mainButtonAction }: WeightWarningModalProps) {

  const { width } = useViewport();

  return (
    <Modal
      isOpen={ true }
      onRequestClose={ close }
      style={{
        content: {
          display: 'flex',
        },
      }}
      contentLabel="Dimension Warning Modal"
    >
      <div className="suitcase-warning-modal">
        <section>
          <h3>Weight Warning</h3>
          <h5 className="bold">This item is over 20kg</h5>

          <p>
            Items over 20kg are subject to a <span className="blue bold">£0.50 per kg</span> additional charge. <span className="blue">Max weight is 30kg</span>
          </p>
          {
            width > Width.Tablet &&
            <SubmitButton type="button" onClick={ mainButtonAction }>
              Add and Contine
            </SubmitButton>
          }
        </section>
        <section>
          <div className="img-container">
            <img src="/img/suitcase-weight-limit.png" alt="Suitcase weight limit" />
          </div>
        </section>
      </div>
      {
        width <= Width.Tablet &&
        <SubmitButton type="button" onClick={ mainButtonAction }>
          Add and Contine
        </SubmitButton>
      }

    </Modal>
  )
}

export function checkSuitcaseDimension(values: Dimensions): boolean {
  let pass: boolean;

  const { width, depth, height } = values;

  const checkTwoSides = () => {
    let counter = 0;
    if (width + depth > 120) counter++;
    if (width + height > 120) counter++;
    if (height + depth > 120) counter++;

    return counter;
  }

  if (width > 160 || depth > 160 || height > 160) {
    pass = false;
  } else pass = checkTwoSides() <= 2;

  return pass;
}