import { RouteComponentProps, StaticContext, useParams, withRouter } from 'react-router';
import React, { useEffect, useState } from 'react';
import { BookingParams, BoxData, Dimensions, Product } from '../models/APIModels';
import {
  boxToMoney,
  monthlyCostToMoney,
  orderCostToMoney,
  purchaseCostToMoney,
  suitcaseOrderToMoney,
  toMoney,
} from '../utilities/MoneyConversion';
import SubmitButton from '../components/SubmitButton';
import { Address } from '../components/AddressLookup';
import NavigationStatus from '../components/NavigationStatus';
import { User, UserState } from '../contexts/UserContext';
import { adminCheck } from '../utilities/Role';
import useGtmEventTracking from '../components/useGtmEventTracking';
import { COLLECTION_FEE, SHIPPING_FEE } from '../constants';
import Title from '../components/Title';
import { displayLoginName } from '../components/user/DisplayLoginName';

interface ConfirmationProps extends RouteComponentProps<{}, StaticContext, ConfirmationState> {
  user?: User,
  isFirstTimeTriggered?: boolean,
  updateTrigger: () => void,
  userState: UserState
}

interface ConfirmationState {
  boxes: BoxData[];
  collectionAddress: Address;
  collectionDate: Date;
  deliveryAddress: Address;
  dontKnowAddress: boolean;
  deliveryDate: Date;
  dontKnowDate: boolean;
  storageRequired: boolean;
  suitcases?: Dimensions[];
  suitcaseData?: Product;
  isFirstTimeTriggered?: boolean,
  fromConfirmation?: boolean
}

function ConfirmationComponent({history, user, isFirstTimeTriggered, updateTrigger, userState, ...props}: ConfirmationProps): JSX.Element {
  const [userLoggedIn] = useState(user && !adminCheck(user));
  const {boxes, collectionAddress, deliveryAddress, storageRequired, suitcases, suitcaseData} = history.location.state;
  const {route} = useParams<BookingParams>();
  const [error] = useState<string>();

  const totalBoxes =  boxes.filter(it => it.chargeShipping).map(it => it.count);
  const totalBoxesCount = totalBoxes?.reduce((a, b) => a + b, 0);
  const shippingRate = SHIPPING_FEE;
  const deliveryCost = shippingRate * totalBoxesCount;

  const gtmEventTracking = useGtmEventTracking();
  useEffect(() => {
    gtmEventTracking({event: 'booking_step_confirmation'})
  }, [gtmEventTracking]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="login-status-bar">
        <div className="status-bar-content">
          <p className="grey"> { displayLoginName( user )} </p>
        </div>
      </section>

      <NavigationStatus step={4} />
      <div className="App-content">
        {error && <span className="error">{error}</span>}
        <Title>Confirmation</Title>

        <div className="grey">Kit Keeper are experts at storing and shipping boxes all across the UK.</div>

        {
          suitcases ?
          <SuitcasePriceList suitcaseData={suitcaseData} suitcases={suitcases} /> :
          <StorageOrShippingPriceList boxes={boxes}
                                      storageRequired={storageRequired}
                                      deliveryCost={deliveryCost}
                                      totalBoxesCount={totalBoxesCount}
          />
        }

        <div className="all-address">
          <div className="address-individual">
            <div className="title">
              <span>Collection Address:</span>
              <img src="/img/location-icon.png" alt="" />
            </div>
            <div className="full-address">
              {Object.values(collectionAddress).map((each, i) => <span key={i}>{each}</span>)}
            </div>
          </div>
          <div className="address-individual">
            <div className="title">
              <span>Delivery Address:</span>
              <img src="/img/truck.png" alt="" />
            </div>
            <div className="full-address">
              {Object.values(deliveryAddress).map((each, i) => <span key={i}>{each}</span>)}
            </div>
          </div>
        </div>
        {
          userLoggedIn ?
          <div className="nextBtnInConfirmation">
            <SubmitButton isSubmitting={false} type="submit" onClick={() => {
              history.push(`/${route}/payment`, {...history.location.state, isFirstTimeTriggered});
              updateTrigger();
            }}>
              Next<img alt="" src="/img/right-arrow-circular-button-outline.png" />
            </SubmitButton>
          </div>
                       :
          <div className="login">
            <div>
              <h2>Login to continue...</h2>
              <span className="grey">Don't have an account? </span>
              <SubmitButton onClick={event => {
                event.preventDefault();
                history.push(`/register`, {...history.location.state, fromConfirmation: true});
              }}>Register now</SubmitButton>
            </div>
            <SubmitButton type="button" onClick={(event) => {
              event.preventDefault();
              history.push(`/login`, {...history.location.state, fromConfirmation: true});
            }}>
              <div>
                <img alt="" src="/img/right-arrow-circular-button-outline.png" />Login
              </div>
            </SubmitButton>
          </div>
        }
      </div>
    </>
  );
}

export default withRouter(ConfirmationComponent);

export interface StorageOrShippingPriceListProps {
  storageRequired: boolean,
  boxes: BoxData[],
  totalBoxesCount: number,
  deliveryCost: number,
}

function StorageOrShippingPriceList({ storageRequired, totalBoxesCount, deliveryCost, boxes }: StorageOrShippingPriceListProps) {
  const deliveryFeeString = process.env.REACT_APP_DELIVERY_FEE
  if(!deliveryFeeString) {
    throw new Error("No delivery fee set")
  }
  const deliveryFee = parseInt(deliveryFeeString);


  const TotalSuitcasesCount =  boxes.filter(it => it.name === "Suitcase").length;
  const TotalOwnboxesCount =  boxes.filter(it => it.name === "Own box").length;
  const SCOBCount =  boxes.filter(it => it.name === "Suitcase / Own box").length;
  const TotalBoxesCount = boxes.length

  const deliveryCostRequired = SCOBCount+ TotalSuitcasesCount + TotalOwnboxesCount !== TotalBoxesCount


  return (
    <>
      <div className="all-cost">
        <span>Cost:</span>
        <div className="cost-total">
          <h4>{ storageRequired ? "Empty boxes" : "Total Cost" }</h4>
          <h3 className="kkUltraViolet">{   /*  updated colour from blue to kkUltraViolet */
            storageRequired ?
            purchaseCostToMoney(boxes) :
            orderCostToMoney(boxes, deliveryCostRequired)
          }</h3>
        </div>
        {storageRequired &&
        <div className="cost-total">
          <h4>Monthly payment</h4>
          <h3 className="kkUltraViolet">{monthlyCostToMoney(boxes)}</h3>  {/*  updated colour from blue to kkUltraViolet */}
        </div>}
      </div>

      <div className="all-order">
        <span>Order:</span>
        {
          boxes.map(box =>
               (box.count > 0 &&
            <div key={box.id} className="order-total">
              <h4>{box.name} x {box.count}</h4>
              <h3>{boxToMoney(box)}</h3>
            </div>)
          )
        }
        <div className="order-total">
          <h4 className="total">{storageRequired ? 'Total for empty boxes' : 'Total'}</h4> <h3 className="kkUltraViolet">{purchaseCostToMoney(boxes)}</h3>
        </div>


      </div>

      {!storageRequired && <div className="all-order">
        <span>Shipping Cost:</span>
        <div className="order-total">
          <h4>Box x {totalBoxesCount}</h4>
          <h3>{toMoney(deliveryCost)}</h3>
        </div>
        <div className="order-total">
          <h4 className="total">Total</h4>
          <h3 className="kkUltraViolet">{toMoney(deliveryCost)}</h3>  {/* updated colour from blue to kkUltraViolet*/}
        </div>
      </div>}
      
      {deliveryCostRequired &&
        <div className='all-order'>
        <span>Delivery of Empty Boxes (Compulsory):</span>
        <div className="order-total">
          <h4 className="total">Total</h4>
          <h3 className="kkUltraViolet">{toMoney(deliveryFee)}</h3> {/* updated colour from blue to kkUltraViolet*/}
        </div>
        </div>}
        {storageRequired &&
        <div className='all-order'>
        <span>Collection fee: </span>
        <div className="order-total">
          <h4 className="total">Total</h4>
          <h3 className="kkUltraViolet">{toMoney(COLLECTION_FEE)}</h3>  {/* updated colour from blue to kkUltraViolet*/}
        </div>
        </div>}
    </>
  )
}

interface SuitcasePriceListProps {
  suitcases: Dimensions[],
  suitcaseData?: Product,
}

function SuitcasePriceList({ suitcases, suitcaseData }: SuitcasePriceListProps) {

  return (
    <>
      <div className="all-cost">
        <span>Cost:</span>
        <div className="cost-total">
          <h4>Total cost</h4>
          <h3 className="kkUltraViolet">{    /*  updated colour from blue to kkUltraViolet */
            suitcaseOrderToMoney(suitcases.length, suitcaseData?.shippingCost)
          }</h3>
        </div>
      </div>

      <div className="all-order">
        <span>Suitcase Order:</span>
        {
          suitcases.map((suitcase, i) =>
            <div key={ i } className="order-total">
              <h4>{ suitcase.depth } x { suitcase.height } x { suitcase.width }</h4>
              <h3>{ suitcaseData?.shippingCost && toMoney(suitcaseData.shippingCost) }</h3>
            </div>
          )
        }
      </div>
    </>
  )
}