import React from 'react';
import { toMoney } from '../utilities/MoneyConversion';
import { COLLECTION_FEE, REDELIVER_FEE } from '../constants';

interface SmallPrintProps {
  isSuitcaseOrder?: boolean;
  storage?: boolean;
}

export default function SmallPrint({ storage, isSuitcaseOrder }: SmallPrintProps) {
  const deliveryFeeString = process.env.REACT_APP_DELIVERY_FEE
  if(!deliveryFeeString) {
    throw new Error("No delivery fee set")
  }
  const deliveryFee = parseInt(deliveryFeeString);
  return <p className="grey">
    {
      isSuitcaseOrder ?
      'We recommend allowing an extra 1-2 days in case of delay due to increased processing times.' :
      `25kg maximum weight per box. Delivery of empty boxes (if ordered): ${toMoney(deliveryFee)}.`
    }
    {
      storage &&
      <>
        <br />
        Collection of boxes: {toMoney(COLLECTION_FEE)}. Redelivery of boxes: {toMoney(REDELIVER_FEE)}/box, to be paid at the end of storage period.
        (1 month minimum storage charge.)
        <br />
        All prices include VAT.
      </>
    }
  </p>;
}