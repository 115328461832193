import React from 'react';
import Modal from 'react-modal';
import SubmitButton from '../components/SubmitButton';
import { axiosKK } from '../services/networkRequest';

const customStyles = {
  content: {
    display: 'flex',
  },
};

interface DeleteConfirmationModalProps {
  show: boolean;
  close: () => void;
  success: () => void;
  failure: () => void;
  id: number | string;
  directory: string;
  warningMessage: string;
}

export default function DeleteConfirmationModal({show, close, id, directory, warningMessage, success, failure}: DeleteConfirmationModalProps): JSX.Element {
  
  return (<Modal
    isOpen={show}
    onRequestClose={close}
    style={customStyles}
    contentLabel="Confirmation Modal"
  >
    <section className="confirmation-modal">
      <div className="title">
        <h3>Are You Sure?</h3>
      </div>
      <div className="content">
        <p>{warningMessage}</p>
      </div>
      <div className="buttons">
        <SubmitButton red={true} onClick={() => {
          axiosKK.delete(`${directory + id}`)
            .then(() => {
              success();
            })
            .catch(() => {
              failure();
            });
        }
        }>
          <i className="fas fa-trash-alt" /> Confirm
        </SubmitButton>
        <SubmitButton onClick={close}>
          Cancel
        </SubmitButton>
      </div>
    </section>
  </Modal>)
}