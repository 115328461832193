import Title from './Title';
import { Formik } from 'formik';
import { axiosKK } from '../services/networkRequest';
import SubmitButton from './SubmitButton';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { AxiosError } from 'axios';
import errorMessage from '../services/errorMessage';
import { User } from '../contexts/UserContext';
import { Link } from 'react-router-dom';
import useGTM from "@elgorditosalsero/react-gtm-hook";
import { adminCheck } from '../utilities/Role';
import { BoxData, Dimensions, Product } from '../models/APIModels';
import { Address } from './AddressLookup';

export interface LoginProps extends RouteComponentProps {
  postLogin: (user: User) => void;
  close?: () => void;
}

interface LoginErrors {
  email?: string
  password?: string
}

interface ConfirmationState {
  boxes: BoxData[];
  collectionAddress: Address;
  collectionDate: Date;
  deliveryAddress: Address;
  dontKnowAddress: boolean;
  deliveryDate: Date;
  dontKnowDate: boolean;
  storageRequired: boolean;
  suitcases?: Dimensions[];
  suitcaseData?: Product;
  isFirstTimeTriggered?: boolean,
  fromConfirmation?: boolean
}

export default function LoginSection({history, postLogin, close}: LoginProps) {
  const [hover, setHover] = useState<number | null>(null);
  const [networkError, setNetworkError] = useState<string>();

  const active = 'rollover';
  const onMouseLeave = () => setHover(null);

  const confirmationState = history.location.state as ConfirmationState;

  const initialValues = {
    email: '',
    password: '',
  };

  const { sendDataToGTM } = useGTM();

  return <div className="log-and-reg-page">
    <Title>Login</Title>
    <div className="log-and-reg-content">
      <section className={hover === 1 ? active : ''} onMouseEnter={() => setHover(1)} onMouseLeave={onMouseLeave}>
        <div className="form-outer">
          <Formik
            initialValues={initialValues}
            validate={({email, password}) => {
              const errors: LoginErrors = {};

              if(!email || email.length < 4) {
                errors.email = 'No email';
              }

              if(!password || password.length < 4) {
                errors.password = 'No password';
              }

              return errors;
            }}
            onSubmit={({email, password}, {setSubmitting}) => {
              setSubmitting(true);
              axiosKK.post<{ user: User, customer: User, jwt: string }>('/login' +
                '', {email, password})
                .then((response) => {
                  sendDataToGTM({event: 'account_login'});
                  const userResponse = response.data.customer || response.data.user;
                  localStorage.setItem("user", JSON.stringify({...userResponse, token: response.data.jwt}));
                  if(confirmationState && confirmationState.fromConfirmation) {
                    history.goBack();
                  } else {
                    history.push( adminCheck(userResponse) ? "/admin/dashboard" : "/dashboard");
                  }
                  postLogin(userResponse);
                })
                .catch(error => {
                  errorMessage(setNetworkError)(error);
                  setSubmitting(false);
                })
                .catch((error: Error | AxiosError) => {
                  const jsonError = JSON.parse(JSON.stringify(error));

                  if(jsonError.hasOwnProperty('response')) {
                    console.error((error as AxiosError).response?.data);
                  } else if(jsonError.hasOwnProperty('message')) {
                    console.error(error.message);
                  } else {
                    console.error(error);
                  }
                  setSubmitting(false);
                });
              }}
          >
            {
              ({
                 values,
                 handleSubmit,
                 handleChange,
                 isSubmitting,
                 isValid,
                 handleBlur,
               }) =>
                <form className="log-and-reg" onSubmit={handleSubmit}>
                  <div className="close-btn-div">
                    {close && <button className="close-btn" type="button" onClick={event => {
                      event.preventDefault();
                      close();
                    }}><i className="fas fa-window-close" /></button>}
                  </div>
                  <div className="form-details">
                    <div className="each">
                      <section><input id="user`name" name="email" onBlur={handleBlur} onChange={handleChange}
                                      value={values.email} type="email" /></section>
                      <label className="log-and-reg-label" htmlFor="email">Email</label>
                    </div>
                    <div className="each">
                      <section><input id="password" name="password" onBlur={handleBlur} onChange={handleChange}
                                      value={values.password} type="password" /></section>
                      <label className="log-and-reg-label" htmlFor="password">Password</label>
                    </div>
                  </div>
                  {networkError && <span className="error">{networkError}</span>}
                  <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
                    <img alt="" src="/img/right-arrow-circular-button-outline.png" />Login
                  </SubmitButton>
                  <Link to="/forgot-password">Forgot Password?</Link>
                </form>
            }
          </Formik>
        </div>
      </section>
    </div>
  </div>;
}