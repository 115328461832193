import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Order } from '../../models/APIModels';
import Moment from 'moment';
import SubmitButton from '../../components/SubmitButton';
import { axiosKK } from '../../services/networkRequest';
import FileDownload from 'js-file-download';

interface OrdersProps {
  orders?: Order[];
}

export default function OrdersDisplayComponent({orders}: OrdersProps): JSX.Element {

  let numberOfItemPerPage: number = 6;

  const pageAmount = orders && Math.ceil(orders?.length / numberOfItemPerPage);
  const [pageCount, setPageCount] = useState(1);
  const [firstItem, setFirstItem] = useState(0);
  const [lastItem, setLastItem] = useState(6);

  return (<>
    <section className="admin-customer-order">
      <div className="button-bar">
        <SubmitButton disabled={firstItem <= 0} onClick={() => {
          setFirstItem(firstItem - numberOfItemPerPage); 
          setLastItem(lastItem - numberOfItemPerPage);
          setPageCount(pageCount- 1);
        }}><i className="fas fa-chevron-left" /></SubmitButton>
      </div>      
      <section className="admin-customer-eachOrder">
        {orders?.slice(firstItem, lastItem).map(order => (
          <OrdersList key={order.id} order={order} />
          ))}
      </section>
      <div className="button-bar">
        <SubmitButton disabled={pageCount >= (pageAmount || 10)} onClick={() => {
          setFirstItem(firstItem + numberOfItemPerPage); 
          setLastItem(lastItem + numberOfItemPerPage);
          setPageCount(pageCount + 1);
        }}><i className="fas fa-chevron-right" /></SubmitButton>
      </div>
    </section>
  </>)
}

interface OrdersListProps {
  order: Order;
}

function OrdersList ({order}: OrdersListProps): JSX.Element {
  const history = useHistory();

  return (
    <div className="eachOrder">
      <div className="content-and-button">
        <div className="id-and-city">
          <p className="orderId">{order?.id}</p>
          <p className="city">{order?.city}</p>
          <p className="orderType">{order?.storageRequired ? 'Storage Order' : 'Shipping Order'}</p>
        </div>
        <div className="buttons">
          <SubmitButton onClick={() => history.push(`/admin/order/${order.id}`, {order})}>
            View
          </SubmitButton>
          <SubmitButton red={true} disabled={!order?.id} onClick={() => {
            axiosKK.get(`/order/${order?.id}/label`, {
              responseType: 'blob',
            })
              .then(response => {
                FileDownload(response.data, `order-${order?.id}-labels.pdf`);
              });
          }}>
            <i className="fas fa-file-download" />
          </SubmitButton>
        </div>
      </div>
      <div className="orderDates">
        <div className="line" />
        <p className="updatedAt">Last Updated: {Moment(order.updatedAt).format('HH:mm, dddd, MMM Do YYYY')}</p>
      </div>
    </div>
  )
}