import React, { useState } from 'react';
import { Order } from '../../models/APIModels';
import Moment from 'moment';
import Label from '../../components/Label';
import SubmitButton from '../../components/SubmitButton';
import { useHistory } from 'react-router-dom';
import { toMoney } from '../../utilities/MoneyConversion';

interface OrdersProps {
  orders?: Order[];
}

export default function OrderComponent({orders}: OrdersProps): JSX.Element {
  const history = useHistory();
  const [showMore, setShowMore] = useState(false);

  return <>
    <section className="all-orders-info">
      {orders?.slice(0, showMore ? orders.length : 3).map(order => {
        const orderId = order.id;
        const orderBoxes = order.orderBoxes;

        return (
          <div key={orderId} className="each-order">
            <div className="borders">
              <div className="top-border">
                {order?.suitcaseOrder ? 'Suitcase Order' : order?.storageRequired ? 'Storage Order' : 'Shipping Order'}
              </div>
              <div className="second-border">
                <p className="roomPack">{order?.roomPack ? 'Room is Packed' : 'Room is Not Packed'}</p>
                <p className="date">Order Created At: {Moment(order?.createdAt).format('dddd, MMM Do YYYY')}</p>
              </div>
            </div>
            <div className="content">
              <div className="id-and-city">
                <p className="id">{order.id}</p>
                <p className="city">{order.city}</p>
              </div>
              <div className="inner-line"/>
              {
                !order?.suitcaseOrder &&
                <div className="order-details">
                  <Label htmlFor={`box-details-${orderId}`}>Product Details</Label>
                  <div className="details">
                    <Label htmlFor={`box-product-${orderId}`}>Ordered Products Include</Label>
                    <p className="td" id={`box-eachItem-${orderId}`}>{
                      orderBoxes.map(orderBox => {
                        return (<span key={orderBox.id}>{`${orderBox?.box?.name}, `}</span>);
                      })
                    }</p>
                  </div>
                </div>
              }
              <div className="cost-details">
                <Label htmlFor={`cost-details-${orderId}`}>Cost Details</Label>
                <div className="details">
                  <Label htmlFor={`cost-initialCost-${orderId}`}>Initial Cost</Label>
                  <p className="td" id={`cost-initalCost-${orderId}`}>{toMoney(order.initialCost)}</p>
                  <Label htmlFor={`cost-monthlyCost-${orderId}`}>Monthly Cost</Label>
                  <p className="td" id={`cost-monthlyCost-${orderId}`}>{toMoney(order.monthlyCost)}</p>
                </div>
              </div>
              <div className="all-buttons">
                <SubmitButton onClick={() => history.push(`/order/${order.id}`)}>
                  Details
                </SubmitButton>
              </div>
            </div>
            <div className="orderDates">
                <div className="line" />
                <p className="updatedAt">Last Updated: {Moment(order.updatedAt).format('HH:mm, dddd, MMM Do YYYY')}</p>
              </div>
          </div>
        );
      })}
    </section>
    <section className="showMoreBtn">
      {orders && orders?.length >= 3 && <SubmitButton onClick={() => {
        if (showMore) {
          setShowMore(false);
        } else {
          setShowMore(true);
        }}}>
        {showMore ? <><span>Show Less</span><i className="fas fa-chevron-up" /></>: 
                    <><span>Show More</span><i className="fas fa-chevron-down" /></>}
      </SubmitButton>}
    </section>
  </>;
}

