import React, { Dispatch, SetStateAction, useState } from 'react';
import { Formik } from 'formik';
import { axiosKK } from '../services/networkRequest';
import SubmitButton from './SubmitButton';
import Error from './Error';
import { RouteComponentProps } from 'react-router';
import errorMessage from '../services/errorMessage';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { AxiosError } from 'axios';
import { RoleSelection } from './RoleSelection';
import useGTM from "@elgorditosalsero/react-gtm-hook";
import { BoxData, Dimensions, Product } from '../models/APIModels';
import { Address } from './AddressLookup';
import { User, UserState } from '../contexts/UserContext';

export interface RegistrationProps extends RouteComponentProps {
  postRegistration: () => any;
  close?: () => void;
  path: string;
  createAdminAccount: boolean;
  setUserState: Dispatch<SetStateAction<UserState>>;
  userState: UserState;
}

interface RegistrationErrors {
  email?: string,
  password?: string,
  confirm?: string,
  firstname?: string,
  lastname?: string,
  phoneNumber?: string,
  role?: string,
}

interface ConfirmationState {
  boxes: BoxData[];
  collectionAddress: Address;
  collectionDate: Date;
  deliveryAddress: Address;
  dontKnowAddress: boolean;
  deliveryDate: Date;
  dontKnowDate: boolean;
  storageRequired: boolean;
  suitcases?: Dimensions[];
  suitcaseData?: Product;
  isFirstTimeTriggered?: boolean,
  fromConfirmation?: boolean
}

const emailRegExp = /^([^\x00-\x7F]|[a-zA-Z0-9_\-.])+@([^\x00-\x7F]|[a-zA-Z0-9_-])+\.([^\x00-\x7F]|[a-zA-Z0-9_-]){2,}(\.([^\x00-\x7F]|[a-zA-Z0-9_-]){2,})?/g;

const phoneUtil = PhoneNumberUtil.getInstance();

export default function RegistrationSection({ history, postRegistration, close, path, createAdminAccount, setUserState }: RegistrationProps) {

  const [hover, setHover] = useState<number | null>(null);
  const [networkError, setNetworkError] = useState<string>();

  const active = 'rollover';
  const onMouseLeave = () => setHover(null);

  const initialValues = createAdminAccount ?
    {
      email: '',
      password: '',
      confirm: '',
      firstname: '',
      lastname: '',
      phoneNumber: '',
      role: ''
    } :
    {
      email: '',
      password: '',
      confirm: '',
      firstname: '',
      lastname: '',
      phoneNumber: '',
    };

  const { sendDataToGTM } = useGTM();
  const confirmationState = history.location.state as ConfirmationState;

  let user: User | undefined;

  return <div className="log-and-reg-page">
    <div className="log-and-reg-content">
      <section className={hover === 1 ? active : ''} onMouseEnter={() => setHover(1)} onMouseLeave={onMouseLeave}>
        <div className="form-outer">
          <Formik
            initialValues={initialValues}
            validate={({
                         email,
                         password,
                         firstname,
                         lastname,
                         phoneNumber,
                         confirm,
                         role,
                       }) => {
              const errors: RegistrationErrors = {};

              if(email.length < 5) {
                errors.email = 'Email Address is too short';
              } else if(!email.match(emailRegExp)?.includes(email)) {
                errors.email = 'Email Address is Invalid';
              }

              if(!firstname || firstname.length < 2) {
                errors.firstname = 'First Name must be at least 2 characters';
              }

              if(!lastname || lastname.length < 2) {
                errors.lastname = 'Last Name must be at least 2 characters';
              }

              if(!password || password.length < 8) {
                errors.password = 'Password must be at least 8 characters';
              } else if(!confirm || confirm !== password) {
                errors.confirm = "Confirm Password did not match";
              }

              if(phoneNumber.length < 9) {
                errors.phoneNumber = 'Phone Number is too short';
              } else {
                ValidatePhoneNumber(phoneNumber);
              }

              if(createAdminAccount && role?.length === 0) {
                errors.role = 'Role is not registered';
              }

              function ValidatePhoneNumber(phoneNumber: string) {
                const UKPhoneNumber = phoneUtil.parse(phoneNumber, 'GB');
                
                if(!phoneUtil.isValidNumber(UKPhoneNumber)) {
                  errors.phoneNumber = 'Phone Number is Invalid';
                }
              }

              return errors;
            }}
            onSubmit={(values, {setSubmitting}) => {
              setSubmitting(true);
              axiosKK.post(path, values)
                .then((response) => {
                  sendDataToGTM({event: 'account_register'});
                  if(confirmationState && confirmationState.fromConfirmation) {
                    const userResponse = response.data.customer || response.data.user;
                    localStorage.setItem("user", JSON.stringify({...userResponse, token: response.data.jwt}));
                    user = userResponse as User;
                    setUserState(u => ({
                      ...u,
                      updateUser: (user: User) => {
                        setUserState(u2 => ({
                          ...u2,
                          user
                        }));
                      },
                      user: user
                    }));

                    axiosKK.defaults.headers.common['Authorization'] = 'bearer ' + response.data.jwt;

                    history.goBack();
                  } else {
                    history.push("/dashboard");
                    postRegistration();
                  }
                })
                .catch(error => {
                  errorMessage(setNetworkError)(error);
                  setSubmitting(false);
                })
                .catch((error: Error | AxiosError) => {
                  const jsonError = JSON.parse(JSON.stringify(error));

                  if(jsonError.hasOwnProperty('response')) {
                    console.error((error as AxiosError).response?.data);
                  } else if(jsonError.hasOwnProperty('message')) {
                    console.error(error.message);
                  } else {
                    console.error(error);
                  }
                  setSubmitting(false);
                });
            }}
          >
            {
              ({
                 values,
                 handleSubmit,
                 handleChange,
                 isSubmitting,
                 isValid,
                 handleBlur,
                errors,
                touched,
               }) =>
                <form className="log-and-reg" onSubmit={handleSubmit}>
                  <div className="close-btn-div">
                  {
                    close &&
                    <button className="close-btn" type="button" onClick={event => {
                      event.preventDefault();
                      close();
                    }}>
                      <i className="fas fa-window-close" />
                    </button>
                  }
                  </div>
                  <div className="form-details">
                    <div className="each">
                      <section><input id="firstname" name="firstname" onBlur={handleBlur} onChange={handleChange}
                                      value={values.firstname} /></section>
                      <label className="log-and-reg-label" htmlFor="firstname">First Name</label>
                      <Error touched={touched.firstname} error={errors.firstname} />
                    </div>
                    <div className="each">
                      <section><input id="lastname" name="lastname" onBlur={handleBlur} onChange={handleChange}
                                      value={values.lastname} /></section>
                      <label className="log-and-reg-label" htmlFor="lastname">Last Name</label>
                      <Error touched={touched.lastname} error={errors.lastname} />
                    </div>
                    <div className="each">
                      <section><input id="email" name="email" onBlur={handleBlur} onChange={handleChange}
                                      value={values.email} type="email" /></section>
                      <label className="log-and-reg-label" htmlFor="username">Email</label>
                      <Error touched={touched.email} error={errors.email} />
                    </div>
                    <div className="each">
                      <section><input id="password" name="password" onBlur={handleBlur} onChange={handleChange}
                                      value={values.password} type="password" /></section>
                      <label className="log-and-reg-label" htmlFor="password">Password</label>
                      <Error touched={touched.password} error={errors.password} />
                    </div>
                    <div className="each">
                      <section><input id="confirm" name="confirm" onBlur={handleBlur} onChange={handleChange}
                                      value={values.confirm} type="password" /></section>
                      <label className="log-and-reg-label" htmlFor="confirm">Confirm Password</label>
                      <Error touched={touched.confirm} error={errors.confirm} />
                    </div>
                    <div className="each">
                      <section><input id="phoneNumber" name="phoneNumber" onBlur={handleBlur} onChange={handleChange}
                                      value={values.phoneNumber} type="phonNumber" /></section>
                      <label className="log-and-reg-label" htmlFor="phoneNumber">Phone (UK Number)</label>
                      <Error touched={touched.phoneNumber} error={errors.phoneNumber} />
                    </div>
                    {
                      createAdminAccount &&
                      <RoleSelection name="role" onBlur={handleBlur} onChange={handleChange} touched={touched} errors={errors} value={values.role} />
                    }
                  </div>
                  {networkError && <span className="error">{networkError}</span>}
                  <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
                    <img alt="" src="/img/right-arrow-circular-button-outline.png" />Register
                  </SubmitButton>
                </form>
            }
          </Formik>
        </div>
      </section>
    </div>
  </div>;
}
