import React, { useContext, useEffect, useState } from 'react';
import SubmitButton from '../../components/SubmitButton';
import { RouteComponentProps, useParams, withRouter } from 'react-router';
import { Customer, CustomerResponse } from '../../models/APIModels';
import Title from '../../components/Title';
import Label from '../../components/Label';
import SmallButton from '../../components/SmallButton';
import { axiosKK } from '../../services/networkRequest';
import Moment from 'moment';
import Modal from 'react-modal';
import { Formik } from 'formik';
import { PhoneNumberUtil } from 'google-libphonenumber';
import PaymentsDisplayComponent from '../../components/admin/Payments';
import OrdersDisplayComponent from '../../components/admin/Orders';
import UserContext from '../../contexts/UserContext';
import { Role } from '../../utilities/Role';

const emailRegExp = /^([^\x00-\x7F]|[a-zA-Z0-9_\-.])+@([^\x00-\x7F]|[a-zA-Z0-9_-])+\.([^\x00-\x7F]|[a-zA-Z0-9_-]){2,}(\.([^\x00-\x7F]|[a-zA-Z0-9_-]){2,})?/g;

const phoneUtil = PhoneNumberUtil.getInstance();

interface CustomerParams {
  id: string;
}

function AdminCustomerComponent({history}: RouteComponentProps): JSX.Element {
  const {id} = useParams<CustomerParams>();
  const [user, setUser] = useState<Customer>();
  const [editMode, setEditMode] = useState(false);
  const {user: loggedInUser} = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);

    if(!user) {
      axiosKK.get<CustomerResponse>(`/customer/details/${id}`)
        .then(({data}) => {
          setUser(data.customer);
        });
    }
  }, [user, setUser, id]);

  return (<>
    <section className="nav-status-bar">
      <div className="status-bar-content">
        <i className="fas fa-arrow-circle-left" />
        <SmallButton onClick={() => {history.push("/admin/customers")}}>Back</SmallButton>
      </div>
    </section>
    <Title>Customer Details</Title>
    { loggedInUser?.role === Role.Admin &&
      <section className="admin-customer-profile-button-section">
        {!editMode &&
        <SubmitButton red={true} onClick={() => setEditMode(true)}>
          Edit
        </SubmitButton>}
      </section> }

    <section className="admin-customer-profile">
      <section className="all-content">
        <div className="name-and-date">
          <div className="name"><h3>{user?.firstName}</h3><h3>{user?.lastName}</h3></div>
          <div className="last-updated"><p>Last updated : {Moment(user?.updatedAt).format('dddd, MMM Do YYYY')}</p>
          </div>
        </div>
        <div className="line" />
        <div className="content">
          {user?.email && <>
            <Label>Email</Label>
            <span>{user.email}</span> </>}
          {user?.phoneNumber && <>
            <Label>Phone</Label>
            <span>{user.phoneNumber}</span> </>}
          {user?.createdAt && <>
            <Label>Created At</Label>
            <span>{Moment(user?.createdAt).format('dddd, MMM Do YYYY')}</span> </>}
        </div>
      </section>
    </section>

    {user?.orders.length !== 0 &&
      loggedInUser?.role === Role.Admin && <>
        <div className="line" />
        <Title>Customer Recent and Recurring Payments</Title>
        <PaymentsDisplayComponent orders={user?.orders} /> </>}

    {user?.orders.length !== 0 && <>
      <div className="line" />
      <Title>Customer Orders</Title>
      <OrdersDisplayComponent orders={user?.orders} />
    </>}

    {user &&
      loggedInUser?.role === Role.Admin &&
        <PersonalDetailsEditModal
          editMode={editMode}
          user={user}
          close={() => setEditMode(false)}
          updateUser={(updated: Customer) => {
            setUser(updated);
          }} />}
  </>);
}

interface NewPersonalDetailsEditValues {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

interface NewPersonalDetailsEditErrors {
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string;
}

interface PersonalDetailsEditModalProps {
  updateUser: (user: Customer) => void,
  close: () => void;
  editMode: boolean;
  user: Customer;
}

function PersonalDetailsEditModal({editMode, user, close, updateUser}: PersonalDetailsEditModalProps): JSX.Element {

  const initialValues: NewPersonalDetailsEditValues = {
    id: user?.id,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phoneNumber: (user?.phoneNumber || ''),
  }

  return(<Modal
    isOpen={editMode}
    onRequestClose={close}
    style={{  content: {
        display: 'flex'
      }}}
    contentLabel="Edit Personal Details"
  >
    <Formik
      initialValues={initialValues}
      validate={
        ({
          email,
          firstName,
          lastName,
          phoneNumber,
        }) => {
          const errors: NewPersonalDetailsEditErrors = {};

          if(email.length < 5) {
            errors.email = 'Email Address is too short';
          } else if(!email.match(emailRegExp)?.includes(email)) {
            errors.email = 'Email Address is Invalid';
          }

          if(!firstName || firstName.length < 2) {
            errors.firstName = 'First & Last Name must be at least 2 characters';
          }

          if(!lastName || lastName.length < 2) {
            errors.lastName = 'First & Last Name must be at least 2 characters';
          }

          if(phoneNumber.length < 9) {
            errors.phoneNumber = 'Phone Number is Too Short';
          } else {
            ValidatePhoneNumber(phoneNumber);
          }

          function ValidatePhoneNumber(phoneNumber: string) {
            const UKPhoneNumber = phoneUtil.parse(phoneNumber, 'GB');
            
            if(!phoneUtil.isValidNumber(UKPhoneNumber)) {
              const ITPhoneNumber = phoneUtil.parse(phoneNumber, 'IT');
              // const getCountryCode = ITPhoneNumber.getCountryCode();
              
              if(!phoneUtil.isValidNumber(ITPhoneNumber)) {
                errors.phoneNumber = 'Phone Number is Invalid';
              }
            }
          }

          return errors;
        }
      }
      onSubmit={(values, {setSubmitting}) => {
        setSubmitting(true);
        axiosKK.put<{user: Customer}>(`/customer/update`, values)
          .then(({data}) => {
            updateUser(data.user);
          })
          .finally(() => {
            setSubmitting(false);
          });
        close();
      }}
    >
      {
        ({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          errors,
          touched,
          isSubmitting,
          isValid,
        }) => 
          <form onSubmit={handleSubmit} name="personal-details-edit-form" className="personal-details-edit-form">
            <div className="modal-header">
              <h3>Edit Personal Details</h3>
              <SubmitButton red={true} onClick={close}>Close</SubmitButton>
            </div>
            <div className="modal-content">
              <div className="user-edit-inputs">
                <Label htmlFor="first-last-name">Name</Label>
                <div className="stuff">
                  <div className="each">
                    <input className="name-input" type="text" name="firstName" value={values.firstName} onBlur={handleBlur} onChange={handleChange} />
                    <label className="user-edit-label">First Name</label>
                  </div>
                  <div className="each">
                    <input className="name-input" type="text" name="lastName" value={values.lastName} onBlur={handleBlur} onChange={handleChange} />
                    <label className="user-edit-label">Last Name</label>
                  </div>
                </div>
                <Error touched={touched.firstName || touched.lastName} error={errors.firstName || errors.lastName} />
              </div>
              <div className="user-edit-inputs">
                <Label htmlFor="email">Email Address</Label>
                <div className="stuff">
                  <input className="email-input" type="text" name="email" value={values.email} onBlur={handleBlur} onChange={handleChange} />
                </div>
                <Error touched={touched.email} error={errors.email} />
              </div>
              <div className="user-edit-inputs">
                <Label htmlFor="email">Phone Number</Label>
                <label className="log-and-reg-label" htmlFor="phoneNumber">Phone (UK Number or International with Country Code)</label>
                <div className="stuff">
                  <input className="phoneNumber-input" type="text" name="phoneNumber" value={values.phoneNumber} onBlur={handleBlur} onChange={handleChange} />
                </div>
              </div>
              <Error touched={touched.phoneNumber} error={errors.phoneNumber} />
            </div>
            <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
              Update
            </SubmitButton>
          </form>
      }
    </Formik>
  </Modal>)
}

export default withRouter(AdminCustomerComponent);

interface ErrorProps {
  error?: string;
  touched?: boolean;
}

function Error({ error, touched }: ErrorProps) : JSX.Element | null {
  return touched && error ? <><br /><span className="error">{error}</span></> : null;
}