import { BoxData } from '../models/APIModels';
import {
  orderCostToMoney,
  purchaseCostToMoney,
  toMoney,
} from '../utilities/MoneyConversion';
/* import Title from './Title';  NO LONGER USED, USING TitleMontserrat INSTEAD */
import TitleMontserrat from './TitleMontserrat';
import Label from './Label';
import React from 'react';
import { SHIPPING_FEE } from '../constants';


interface ShippingPriceDetailsProps {
  boxes: BoxData[];
}

export function ShippingPriceDetails({boxes}: ShippingPriceDetailsProps) : JSX.Element {
 
  const totalBoxes =  boxes.filter(it => it.chargeShipping).map(it => it.count);
  const totalBoxesCount = totalBoxes?.reduce((a, b) => a + b, 0);
  const shippingRate = SHIPPING_FEE;
  const deliveryCost = shippingRate * totalBoxesCount;

  return(<>
    <section className="total-shipping-cost-box">
      <section className="all-content">
        <div className="kkUltraViolet-h2"><TitleMontserrat>Your Total Shipping Cost</TitleMontserrat></div>
        <div className="all-shipping">
          <div className="line" />
          <Label>Shipping Rate</Label>
          <DisplayShippingRate totalBoxesCount={totalBoxesCount} shippingRate={shippingRate} />
          {totalBoxesCount >= 1 && <p>Rate for the purchase of {totalBoxesCount} boxes</p>}
        </div>
        {(deliveryCost >= 1) && <div className="payment-figure"><h4>Total</h4><span className="cost">{toMoney(deliveryCost)}</span></div>}
      </section>
      <section className="all-cost-and-total">
        <section className="all-cost">
          <div className="line" />
          <Label>Item Cost</Label>
          <div className="item-summary">
            {boxes.map(box =>
              (box.count > 0 &&
                <div className="each-box" key={ box.id }>
                  <div className="each-title"><h4>{box.count} * {box.name}</h4></div>
                  <div className="each">
                    <span>Purchase Cost</span>
                    <span>{toMoney(box.purchaseCost * box.count)}</span>
                  </div>
                </div>
              ))
            }
          </div>
          {purchaseCostToMoney(boxes) !== '£0.00' && <div className="payment-figure"><h4>Total</h4><span className="cost">{purchaseCostToMoney(boxes)}</span></div>}
        </section>
        <section className="total">
          <div className="line" />
          <Label>Total Cost</Label>
          <CalculateTotalCost boxes={boxes} />
        </section>
      </section>
    </section>
  </>);
}

interface CalculateTotalCostProps {
  boxes: BoxData[];
}

function CalculateTotalCost({ boxes }: CalculateTotalCostProps): JSX.Element {
  const deliveryFeeString = process.env.REACT_APP_DELIVERY_FEE
  if(!deliveryFeeString) {
    throw new Error("No delivery fee set")
  }
  const deliveryFee = parseInt(deliveryFeeString);
  const deliveryCostRequired = boxes.some((x) => x.name !== "Suitcase / Own box" && x.count > 0);
  return (<>
    <div className="cost-summary">
      <div className="each">
        <span>Order Cost</span>
        <span>{orderCostToMoney(boxes, false)}</span>
      </div>
      {deliveryCostRequired &&
      <div className="each">
        <span>Delivery of empty boxes (compulsory)</span>
        <span>{toMoney(deliveryFee)}</span>
      </div> }
    </div>
    <div className="payment-figure-FinalCountdown">
      <h3 className="cost">{orderCostToMoney(boxes, deliveryCostRequired)}</h3>
    </div>
  </>);
}

interface DisplayShippingRateProps {
  totalBoxesCount: number;
  shippingRate: number;
}

function DisplayShippingRate({totalBoxesCount, shippingRate} : DisplayShippingRateProps): JSX.Element {

  return(<>
    {totalBoxesCount === 0 && <div className="box-rate"><span className="noBox">Select Box to Begin!</span></div>}
    {totalBoxesCount >= 1 && <div className="box-rate"><h4>{toMoney(shippingRate)}</h4><span>/ Box</span></div>}
  </>)
}