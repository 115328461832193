import { BoxData, Dimensions } from '../models/APIModels';
import { monthlyCostToMoney, orderCostToMoney, purchaseCostToMoney, suitcaseOrderToMoney } from '../utilities/MoneyConversion';
import React from 'react';

interface PricesProps {
  boxes: BoxData[];
  storageRequired: boolean;
  suitcases?: Dimensions[];
  suitcaseShippingCost?: number;
}

export default function Prices({ boxes, storageRequired, suitcases, suitcaseShippingCost }: PricesProps) {


  const TotalSuitcasesCount =  boxes.filter(it => it.name === "Suitcase").length;
  const TotalOwnboxesCount =  boxes.filter(it => it.name === "Own box").length;
  const SCOBCount =  boxes.filter(it => it.name === "Suitcase / Own box").length;
  const TotalBoxesCount = boxes.length

  const deliveryCostRequired = SCOBCount+ TotalSuitcasesCount + TotalOwnboxesCount !== TotalBoxesCount


  return <div className={ storageRequired ? 'payment-figure payment-figure-storageRequired' : 'payment-figure' }>
    <h4>{ storageRequired ? 'Materials' : 'Total' }</h4>
    <span className="cost">{
      suitcases ?
      suitcaseOrderToMoney(suitcases.length, suitcaseShippingCost) :
      storageRequired ?
        purchaseCostToMoney(boxes) :
        orderCostToMoney(boxes, deliveryCostRequired)
    }</span>
    {storageRequired && (
      <>
        <span className="barrier" />
        <span className="cost">{monthlyCostToMoney(boxes)}</span>
        <h4>Monthly payment</h4>
      </>)}
  </div>;
}