import { RouteComponentProps, StaticContext, useParams, withRouter } from 'react-router';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  BookingParams,
  BoxCount,
  BoxData,
  Discount,
  DiscountResponse,
  MakeOrderRequest,
  MakeOrderResponse,
  MakeSuitcaseOrderRequest,
  Product,
  Dimensions,
  CheckoutItem,
  CheckoutProduct,
  Order,
  MakePaymentRequest,
} from '../models/APIModels';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  loadStripe,
  PaymentIntent,
  SetupIntent,
  StripeError,
  ConfirmCardPaymentData,
  ConfirmCardSetupOptions,
  StripeCardElement, StripeCardNumberElement,
} from '@stripe/stripe-js';
import SubmitButton from '../components/SubmitButton';
import { Formik } from 'formik';
import Label from '../components/Label';
import AddressLookup, { Address, simplifyAddress, validateAddress } from '../components/AddressLookup';
import Title from '../components/Title';
import {
  boxToMoney,
  monthlyCostToMoney,
  purchaseCostToMoney,
  toMoney,
  orderCostToMoney, suitcaseOrderToMoney,
} from '../utilities/MoneyConversion';
import { axiosKK } from '../services/networkRequest';
import NavigationStatus from '../components/NavigationStatus';
import { StorageOrShippingPriceListProps } from './Confirmation';
import useGtmEventTracking from '../components/useGtmEventTracking';
import errorMessage from '../services/errorMessage';
import { ThreeDSModal } from '../components/ThreeDSModal';
import { PaymentStatus } from '../models/APIModels';
import { COLLECTION_FEE, SHIPPING_FEE } from '../constants';
import UserContext from '../contexts/UserContext';
import { displayLoginName } from '../components/user/DisplayLoginName';

interface PaymentProps extends RouteComponentProps<undefined, StaticContext, PaymentState> {
}

interface PaymentState {
  boxes: BoxData[];
  collectionAddress: Address;
  collectionDate: Date;
  deliveryAddress?: Address;
  dontKnowAddress: boolean;
  deliveryDate?: Date;
  dontKnowDate: boolean;
  storageRequired: boolean;
  suitcases?: Dimensions[];
  suitcaseData?: Product;
  eecProducts?: object[];
  items?: object[];
  orderId?: number;
  discount?: Discount;
  isFirstTimeTriggered?: boolean,
  existingOrder?: Order
}

export interface PaymentFormErrors {
  address?: string;
  name?: string;
  cardNumberValidation?: string,
  cvcValidation?: string,
  expiryDateValidation?: string,
  agreeToTerms?: string,
}

export interface PaymentFormInitialValues {
  name: string,
  cardNumberValidation: boolean,
  cvcValidation: boolean,
  expiryDateValidation: boolean,
  address: string,
  agreeToTerms: boolean,
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!!);

const cardInputStyle = {
  style: {
    base: {
      fontSize: '14px',
      color: '#424770',
      fontWeight: '500',
      fontFamily: 'Montserrat, sans-serif',
      '::placeholder': {
        color: '#9e9e9e',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

function PaymentComponent({history}: PaymentProps): JSX.Element {

  const { state: parsedState } = history.location;

  const [state, setState] = useState<PaymentState | undefined>(parsedState);

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (state) {
      localStorage.setItem('payment-state', JSON.stringify(state));
    } else {
      const paymentState = localStorage.getItem('payment-state')!;
      const newState = JSON.parse(paymentState) as PaymentState;

      setState(newState);
    }

  }, [state]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (<>
    <section className="login-status-bar">
      <div className="status-bar-content">
        <p className="grey"> { displayLoginName( user )} </p>
      </div>
    </section>
    <NavigationStatus step={5} />
    <Title>Payment</Title>
    <p className="grey">Kit Keeper are experts at storing and shipping boxes all across the UK.</p>

    {
      state && <PaymentDefinedStateComponent paymentState={ state }
                                             push={ history.push }
                                             pathname={ history.location.pathname } />
    }
  </>);
}

interface PaymentDefinedStateComponentProps {
  push: (path: string, state?: PaymentState) => void,
  paymentState: PaymentState,
  pathname: string,
}

function PaymentDefinedStateComponent({ paymentState: state, push, pathname }: PaymentDefinedStateComponentProps) {

  const {boxes, collectionAddress, deliveryAddress, suitcases, storageRequired, suitcaseData } = state;

  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();
  const [billingAddress, setBillingAddress] = useState<Address>({});
  const [orderId, setOrderId] = useState<number>();
  const [appliedDiscount, setAppliedDiscount] = useState<Discount>();
  const {route} = useParams<BookingParams>();

  const totalBoxes =  boxes.filter(it => it.chargeShipping).map(it => it.count);
  const totalBoxesCount = totalBoxes?.reduce((a, b) => a + b, 0);
  const shippingRate = SHIPPING_FEE;
  const deliveryCost = shippingRate * totalBoxesCount;

  const [billingEventSentToGTM, setBillingEventSentToGTM] = useState(false);
  const [paymentEventSentToGTM, setPaymentEventSentToGTM] = useState(false);

  const [note, setNote] = useState<string>('');

  // 3D Security
  const frontendUrl = process.env.REACT_APP_FRONTEND_URL;
  const [threeDSRedirectUrl, setThreeDSRedirectUrl] = useState<string>('');
  const [intentClientSecret, setIntentClientSecret] = useState<string | undefined>(undefined);
  const [paymentError, setPaymentError] = useState<string | undefined>(undefined);
  const [paymentMethodId, setPaymentMethodId] = useState<string | undefined>(undefined);
  const [cardNumber, setCardNumber] = useState<StripeCardElement | StripeCardNumberElement | null>(null)
  const [subscriptionCreated, setSubscriptionCreated] = useState<boolean>(false);

  let orderType: string;
  if(suitcases) {
    orderType = "Suitcase";
  } else {
    if(storageRequired) {
      orderType = "Box Storage";
    } else {
      orderType = "Box Shipping";
    }
  }

  const eecCheckoutProducts: CheckoutProduct[] = [];
  const beginCheckoutItems: CheckoutItem[] = [];


  if(suitcases && suitcaseData) {
    for(let i = 0; suitcases.length < i; i++) {

      eecCheckoutProducts.push({
        name: suitcaseData.name,
        id: suitcaseData.id,
        price: (suitcaseData.purchaseCost / 100).toFixed(2),
        brand: 'Kit Keeper',
        category: orderType,
        quantity: 1,
        affiliation: 'Online Store'
      });

      let item: CheckoutItem = {
        item_id: suitcaseData.id.toString(),
        item_name: suitcaseData.name,
        affiliation: 'Online Store',
        currency: 'GBP',
        index: i,
        item_brand: 'Kit Keeper',
        item_category: orderType,
        price: (suitcaseData.purchaseCost / 100).toFixed(2),
        quantity: '1',
      };
      if(appliedDiscount) {
        item.coupon = appliedDiscount.discountCode;
        item.discount = appliedDiscount.discountPercentage + '%';
      }
      beginCheckoutItems.push(item);
    }
  } else {
    let storageCost = 0;

    boxes.forEach(function(box, i) {

      if(orderType === 'Storage') {
        storageCost += box.storageCost;
      }

      eecCheckoutProducts.push({
        name: box.name,
        id: box.id,
        price: (box.purchaseCost / 100).toFixed(2),
        brand: 'Kit Keeper',
        category: orderType,
        quantity: 1,
        affiliation: 'Online Store',
      });

      let item: CheckoutItem = {
        item_id: box.id.toString(),
        item_name: box.name,
        affiliation: 'Online Store',
        currency: 'GBP',
        index: i,
        item_brand: 'Kit Keeper',
        item_category: orderType,
        price: (box.purchaseCost / 100).toFixed(2),
        quantity: '1',
      };
      if(appliedDiscount) {
        item.coupon = appliedDiscount.discountCode;
        item.discount = appliedDiscount.discountPercentage + '%';
      }
      beginCheckoutItems.push(item);
    })

    if(storageCost > 0) {
      eecCheckoutProducts.push({
        name: 'Box Storage',
        price: (storageCost / 100).toFixed(2),
        brand: 'Kit Keeper',
        category: 'Storage',
        quantity: 1,
        affiliation: 'Online Store'
      });

      beginCheckoutItems.push({
        item_name: 'Box Storage',
        price: (storageCost / 100).toFixed(2),
        item_brand: 'Kit Keeper',
        item_category: 'Storage',
        quantity: '1',
        affiliation: 'Online Store',
        currency: 'GBP',
        index: 0
      })
    }
  }

  const gtmEventTracking = useGtmEventTracking();

  useEffect(() => {
    if(state?.isFirstTimeTriggered) {
      gtmEventTracking({
        event: 'begin_checkout',
        ecommerce: {
          items: beginCheckoutItems,
        },
      });

      gtmEventTracking({
        event: 'eec_checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
            },
            products: eecCheckoutProducts,
          },
        },
      });
      gtmEventTracking({event: 'booking_step_payment'});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtmEventTracking, state?.isFirstTimeTriggered]);

  const eecBillingObject = {
    event: 'eec_checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step: 2
        }
      }
    }
  };

  const billingInfoObject = {
    event: 'add_billing_info',
    ecommerce: {
      items: beginCheckoutItems,
    }
  };

  const eecPaymentObject = {
    event: 'eec_checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step: 3
        }
      }
    }
  };

  const paymentInfoObject = {
    event: 'add_payment_info',
    ecommerce: {
      currency: 'GBP',
      value: Number.parseFloat((suitcases ? suitcaseOrderToMoney(suitcases.length, suitcaseData?.shippingCost, appliedDiscount)
                                          : (storageRequired ? purchaseCostToMoney(boxes, appliedDiscount, true)
                                                             : orderCostToMoney(boxes, true, appliedDiscount))).substring(1)),
      coupon: appliedDiscount ? appliedDiscount.discountCode : null,
      payment_type: 'Credit Card',
      items: beginCheckoutItems,
    }
  };

  const eventTrackingCallback = useCallback((data : any) => {
    gtmEventTracking(data);
  }, [gtmEventTracking]);

  const initialValues: PaymentFormInitialValues = {
    name: '',
    cardNumberValidation: false,
    cvcValidation: false,
    expiryDateValidation: false,
    address: '',
    agreeToTerms: false,
  }

  function handleIntent(intent: PaymentIntent | SetupIntent, orderId: number | undefined, finalState: PaymentState) {
    const action = intent.next_action
    if(intent.status === 'requires_action' && action?.type === 'redirect_to_url') {
      //  Modal that contains an iframe
      const url = action?.redirect_to_url?.url;

      if(url) {
        setThreeDSRedirectUrl(url);

        push(`/${route}/payment/3ds-secure`);
      }
    } else if(intent.status === 'succeeded') {
      push(`/${route}/success`, { ...finalState, orderId });
    }
  }
  
  function handlePaymentStatus(data: MakeOrderResponse, finalState: PaymentState, cardNumber: StripeCardElement | StripeCardNumberElement, paymentMethodId: string) {
    switch (data.paymentStatus) {
      case PaymentStatus.noneNeeded:
      case PaymentStatus.succeeded:
      case PaymentStatus.trialing:
        if(storageRequired && !subscriptionCreated && data.order.id) {
          const paymentRequest: MakePaymentRequest = {
            orderId: data.order.id,
            paymentMethodId: paymentMethodId
          }
          
          axiosKK.post<MakeOrderResponse>('/order/createSubscription', paymentRequest).then(response => {
            if(response.data.paymentStatus !== PaymentStatus.succeeded && response.data.setupIntentSecret && stripe !== null && cardNumber) {
              setSubscriptionCreated(true);
              handlePaymentStatus(response.data, { ...state, orderId }, cardNumber, paymentMethodId);
            }
          }).catch(error => {
            setPaymentError(error.message);
          }).finally(() => {
            push(`/${ route }/success`, { ...state, orderId });
          });
        }
        push(`/${route}/success`, { ...finalState, orderId: data.order.id });
        break;

      case PaymentStatus.requiresAction:

        const confirmData: ConfirmCardPaymentData = {
          payment_method: {
            card: cardNumber,
          },
          return_url: `${frontendUrl}/${route}/back-to-payment`,
        }

        const confirmOptions: ConfirmCardSetupOptions = {
          handleActions: false,
        }

        if(stripe) {
          if(data.paymentIntentSecret) {
            stripe.confirmCardPayment(data.paymentIntentSecret, confirmData, confirmOptions)
              .then(({paymentIntent}) => {
                if(paymentIntent) {
                  handleIntent(paymentIntent, data.order.id, finalState);
                }
              })
          } else if(data.setupIntentSecret) {
            stripe.confirmCardSetup(data.setupIntentSecret, confirmData, confirmOptions)
              .then(({ setupIntent }) => {
                if(setupIntent) {
                  handleIntent(setupIntent, data.order.id, finalState);
                }
              })
          }
        }

        break;

      case PaymentStatus.requiresPaymentMethod:
        setPaymentError('Please try another payment method');
        break;

    }
  }

  const handleRetrieveIntent = useCallback((intent: PaymentIntent | SetupIntent | undefined, error: StripeError | undefined) => {

    setSubscriptionCreated(true);
    
    if (error) {
      setPaymentError(error.message);
    } else {

      if(intent?.status === PaymentStatus.succeeded) {

        if (storageRequired && !subscriptionCreated) {
          if(orderId && paymentMethodId) {
            const paymentRequest: MakePaymentRequest = {
              orderId: orderId,
              paymentMethodId: paymentMethodId
            }

            axiosKK.post<MakeOrderResponse>('/order/createSubscription', paymentRequest).then(response => {
              if(response.data.paymentStatus !== PaymentStatus.succeeded && response.data.setupIntentSecret && stripe !== null && cardNumber) {
                setSubscriptionCreated(true);
                handlePaymentStatus(response.data, { ...state, orderId }, cardNumber, paymentMethodId);
              }
            }).catch(error => {
              setPaymentError(error.message);
            });
          } else {
            setPaymentError('Insufficient card or order details');
          }

        } else {
          push(`/${ route }/success`, { ...state, orderId });
        }

      } else if (intent?.status === PaymentStatus.requiresPaymentMethod) {
        setPaymentError('Please try another payment method');
      }
    }
    // eslint-disable-next-line 
  }, [orderId, subscriptionCreated, paymentMethodId]);
  
  useEffect(() => {
    let listener = (event: MessageEvent) => {
      if (event.origin === frontendUrl && event.data === '3DS-authentication-complete') {

        intentClientSecret && stripe!.retrievePaymentIntent(intentClientSecret)
          .then(({ paymentIntent, error }) => {
            handleRetrieveIntent(paymentIntent, error);
          });
      }
    };
    window.addEventListener('message', listener);
    
    return () => {
      window.removeEventListener('message', listener);
    }
  });

  return (
    <>
      <Formik initialValues={ initialValues }
              validateOnMount={true}
              validate={({ name, cardNumberValidation, cvcValidation, expiryDateValidation, agreeToTerms }) => {
                const errors: PaymentFormErrors = {}

                setBillingAddress(collectionAddress);
                if(!billingEventSentToGTM) {
                  setBillingEventSentToGTM(true);
                  eventTrackingCallback(eecBillingObject);
                  eventTrackingCallback(billingInfoObject);
                }

                if(!validateAddress(billingAddress)) {
                  errors.address = 'Invalid address';
                }

                if(!name || name?.length < 5) {
                  errors.name = 'too short of a name';
                }

                if(!cardNumberValidation) {
                  errors.cardNumberValidation = "Card number incomplete";
                }

                if(!cvcValidation) {
                  errors.cardNumberValidation = "CVC incomplete";
                }

                if(!expiryDateValidation) {
                  errors.cardNumberValidation = "Expiry date incomplete";
                }

                if(!agreeToTerms) {
                  errors.agreeToTerms = 'You must agree to the terms and conditions';
                }

                if(Object.keys(errors).length === 0 && !paymentEventSentToGTM) {
                  setPaymentEventSentToGTM(true);
                  eventTrackingCallback(eecPaymentObject);
                  eventTrackingCallback(paymentInfoObject);
                }

                return errors;
              }}
              onSubmit={async ({name}) => {
                setSubmitting(true);

                if(!stripe || !elements) {
                  // Stripe.js has not loaded yet. Make sure to disable
                  // form submission until Stripe.js has loaded.
                  throw new Error('Payment gateway not prepared');
                }

                // Get a reference to a mounted CardElement. Elements knows how
                // to find your CardElement because there can only ever be one of
                // each type of element.
                const cardNumber = elements.getElement(CardNumberElement);
                const cvcNumber = elements.getElement(CardCvcElement);
                const expiryDate = elements.getElement(CardExpiryElement);

                if(!(cardNumber && cvcNumber && expiryDate)) {
                  throw Error('Card elements not ready');
                }
                
                setCardNumber(cardNumber);

                const {paymentMethod, error} = await stripe.createPaymentMethod({
                  type: 'card',
                  card: cardNumber,
                  billing_details: {
                    name,
                    address: {
                      city: billingAddress.city,
                      line1: billingAddress.line1,
                      line2: billingAddress.line2,
                      postal_code: billingAddress.postCode,
                      state: billingAddress.county
                    }
                  }
                });

                if(error) {
                  errorMessage(setError)(error.toString());
                  throw error;

                }

                if(!paymentMethod) {
                  errorMessage(setError)("Unable to Retrieve Payment Method");
                  throw new Error('Payment method not prepared');
                }
                
                setPaymentMethodId(paymentMethod.id);

                const suitcaseRequest: MakeSuitcaseOrderRequest = {
                  suitcases: suitcases ? suitcases : [],
                  city: state.collectionAddress.city,
                  collectionAddress: simplifyAddress(state.collectionAddress),
                  collectionDate: state.collectionDate.getTime(),
                  deliveryAddress: state.deliveryAddress && simplifyAddress(state.deliveryAddress),
                  deliveryDate: state.deliveryDate?.getTime(),
                  discountCode: appliedDiscount?.discountCode,
                  note,
                  paymentMethodId: paymentMethod.id,
                  country: state.collectionAddress.country,
                  collectionCountry: state.collectionAddress.country,
                  collectionPostcode: state.collectionAddress.postCode,
                  deliveryCountry: state.deliveryAddress?.country,
                  deliveryPostcode: state.deliveryAddress?.postCode
                }

                const request: MakeOrderRequest = {
                  boxes: state.boxes.reduce((acc, each) => {
                    acc[each.id] = each.count;
                    return acc;
                  }, {} as BoxCount),
                  city: state.collectionAddress.city,
                  collectionAddress: simplifyAddress(state.collectionAddress),
                  collectionDate: state.collectionDate.getTime(),
                  deliveryAddress: state.deliveryAddress && simplifyAddress(state.deliveryAddress),
                  deliveryDate: state.deliveryDate?.getTime(),
                  roomPack: false,
                  storageRequired: state.storageRequired,
                  discountCode: appliedDiscount?.discountCode,
                  note,
                  paymentMethodId: paymentMethod.id,
                  collectionCountry: state.collectionAddress.country,
                  collectionPostcode: state.collectionAddress.postCode,
                  deliveryCountry: state.deliveryAddress?.country,
                  deliveryPostcode: state.deliveryAddress?.postCode
                };

                const finalState = {
                  boxes: boxes,
                  collectionAddress: collectionAddress,
                  collectionDate: state.collectionDate,
                  deliveryAddress: deliveryAddress,
                  dontKnowAddress: deliveryAddress == null,
                  deliveryDate: state.deliveryDate,
                  dontKnowDate: state.deliveryDate == null,
                  storageRequired: storageRequired,
                  suitcases: suitcases,
                  suitcaseData: suitcaseData,
                  eecProducts: eecCheckoutProducts,
                  items: beginCheckoutItems,
                  orderId: orderId,
                  discount: appliedDiscount,
                }

                axiosKK.post<MakeOrderResponse>(suitcases ? '/order/create/suitcase' : '/order/create', suitcases ? suitcaseRequest : request)
                  .then(async ({ data }) => {
                    const order = data.order;
                    setOrderId(order.id);

                    setIntentClientSecret(data.paymentIntentSecret);

                    handlePaymentStatus(data, finalState, cardNumber, paymentMethod.id);
                    
                    return order.id;
                  })
                  .catch(error => {
                    errorMessage(setError)(error);
                  })
                  .finally(() => setSubmitting(false));
              }}>
        {
          ({
             values,
             handleChange,
             handleSubmit,
             isValid,
             setFieldValue,
           }) =>
            <form className="payment" onSubmit={handleSubmit}>
              <section className="content">
                <section className="all-details">
                  <div className="address">
                    <Label>
                      Billing Address:
                    </Label>
                    <div className="all-coll-del">
                      <div className="selection-a">
                        <>
                          <button onClick={() => {
                            setBillingAddress(collectionAddress);
                            if(!billingEventSentToGTM) {
                              setBillingEventSentToGTM(true);
                              eventTrackingCallback(eecBillingObject);
                              eventTrackingCallback(billingInfoObject);
                            }
                          }}>use collection address
                          </button>

                          {deliveryAddress && validateAddress(deliveryAddress) &&
                            <button onClick={() => {
                              setBillingAddress(deliveryAddress);
                              if(!billingEventSentToGTM) {
                                setBillingEventSentToGTM(true);
                                eventTrackingCallback(eecBillingObject);
                                eventTrackingCallback(billingInfoObject);
                              }
                            }}>use delivery address</button>
                          }
                        </>
                      </div>

                      <div className="selection-a">

                        <font COLOR="#9e9e9e">Please use collection address for international payment cards</font>

                      </div>

                          <AddressLookup
                        address={billingAddress}
                        setAddress={setBillingAddress}
                        callback={eventTrackingCallback}
                        eecBillingInfo={eecBillingObject}
                        billingInfo={billingInfoObject}
                        billingEventSentToGTM={billingEventSentToGTM}
                        setBillingEventSentToGTM={setBillingEventSentToGTM} />
                    </div>
                  </div>
                  <div className="payment">
                    <Label>
                      Payment:
                    </Label>
                    <div className="card-info">
                      <div className="title-bar">
                        <h5>Card Details:</h5>
                        <img src="img/card-icon.png" alt="" />
                      </div>
                      <div className="input-bar">
                        <div className="each">
                          <section><input onChange={handleChange} id="name"
                                          value={values.name} /></section>
                          <label className="payment-label">Cardholder Name</label>
                        </div>
                        {/*<CardElement />*/}
                        <div className="each">
                          <section><CardNumberElement options={cardInputStyle} onChange={ event => {
                            setFieldValue("cardNumberValidation", event.complete);
                          } } /></section>
                          <label className="payment-label">Card Number</label>
                        </div>
                        <div className="each-tgt">
                          <div className="each">
                            <section><CardCvcElement options={cardInputStyle} onChange={ event => {
                              setFieldValue("cvcValidation", event.complete);
                            } } /></section>
                            <label className="payment-label">CVC</label>
                          </div>
                          <div className="each">
                            <section><CardExpiryElement options={cardInputStyle} onChange={ event => {
                              setFieldValue("expiryDateValidation", event.complete);
                            }} /></section>
                            <label className="payment-label">Expiry Date</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="all-order">
                  {
                    suitcases ?
                    <div className="order-info">
                      <div className="order-total">
                        <h4>Total</h4>
                        <h3 className="kkUltraViolet">{ suitcaseOrderToMoney(suitcases.length, suitcaseData?.shippingCost, appliedDiscount) }</h3>
                      </div>
                    </div> :
                    <StorageOrShippingFinalPriceList storageRequired={ storageRequired }
                                                     appliedDiscount={ appliedDiscount }
                                                     boxes={ boxes }
                                                     totalBoxesCount={ totalBoxesCount }
                                                     deliveryCost={ deliveryCost } />
                  }

                  <>
                    <DiscountComponent successfulDiscount={discount => setAppliedDiscount(discount)} />
                    <>
                      {appliedDiscount && appliedDiscount.recurring && <p>{appliedDiscount.discountPercentage}% monthly discount applied</p>}
                      {appliedDiscount && appliedDiscount.upFront && <p>{appliedDiscount.discountPercentage}% order discount applied</p>}
                    </>

                    <NoteComponent note={note} updateNote={updatedNote => setNote(updatedNote)} />
                  </> 
                </section>
              </section>

              <span className="terms-and-conditions">
              <input name="dontKnowAddress"
                     onChange={ () => {
                       setFieldValue("agreeToTerms", !values.agreeToTerms);
                     } }
                     value={ values.agreeToTerms ? 'true' : 'false' }
                     type="checkbox" />

              <label htmlFor="dontKnowAddress">I Agree to the
                <a href="https://www.kitkeeper.co.uk/terms-and-conditions/" target="_blank" rel="noreferrer">
                  Terms & Conditions
                </a>
              </label>
            </span>

              {
                submitting && <h3 className="kkUltraViolet">Payment is being processed, please do not refresh the page.</h3>
              }

              {
                error && <h3 className="error">Error: </h3>
              }
              {
                error && <h3 className="error">{ error }</h3>
              }
              {
                paymentError && <h3 className="error">Payment Error: </h3>
              }
              {
                paymentError && <h3 className="error">{ paymentError }</h3>
              }
              {
                error && <h3 className="error">Error detected: Your order has been placed, and support@kitkeeper.co.uk will be contacting you</h3>
              }
              {
                paymentError && <h3 className="error">Error detected: Your order has been placed, and support@kitkeeper.co.uk will be contacting you</h3>
              }


              <SubmitButton className="payment-btn" disabled={ !isValid } isSubmitting={submitting}>
                Confirm Payment<img alt="" src="/img/right-arrow-circular-button-outline.png" />
              </SubmitButton>
            </form>
        }
      </Formik>

      {
        pathname.includes('3ds-secure') &&
        <ThreeDSModal url={ threeDSRedirectUrl } />
      }
    </>
  )

}

interface StorageOrShippingFinalPriceListProps extends StorageOrShippingPriceListProps {
  appliedDiscount?: Discount,
  existingOrder?: Order
}

function StorageOrShippingFinalPriceList({ boxes, storageRequired, totalBoxesCount, deliveryCost, appliedDiscount }:StorageOrShippingFinalPriceListProps) {
  const deliveryFeeString = process.env.REACT_APP_DELIVERY_FEE
  if(!deliveryFeeString) {
    throw new Error("No delivery fee set")
  }
  const deliveryFee = parseInt(deliveryFeeString);

  const TotalSuitcasesCount =  boxes.filter(it => it.name === "Suitcase").length;
  const TotalOwnboxesCount =  boxes.filter(it => it.name === "Own box").length;
  const SCOBCount =  boxes.filter(it => it.name === "Suitcase / Own box").length;
  const TotalBoxesCount = boxes.length

  const deliveryCostRequired = SCOBCount+ TotalSuitcasesCount + TotalOwnboxesCount !== TotalBoxesCount;



  return (
    <>
      <div className="order-info">
        <span>Order:</span>
        {
          boxes.map(box =>
             (box.count > 0 &&
            <div key={box.id} className="order-total">
              <h4>{box.name} x {box.count}</h4>
              <h3>{boxToMoney(box)}</h3>
            </div>)
          )
        }
        <div className="order-total">
          <h4>Total</h4>
          <h3 className="kkUltraViolet">{purchaseCostToMoney(boxes)}</h3>
        </div>
      </div>
      {deliveryCostRequired && <div className="order-info">
        <span>Empty box delivery:</span>
        <div className="order-total">
          <h4>Total</h4>
          <h3 className="kkUltraViolet">{toMoney(deliveryFee)}</h3>
        </div>
      </div>}
      {
          storageRequired &&
         <div className="order-info">
            <span>Collection fee:</span>
            <div className="order-total">
              <h4>Total</h4>
              <h3 className="kkUltraViolet">{toMoney(COLLECTION_FEE)} </h3>
            </div>
         </div>
        }
      {!storageRequired && <div className="order-info">
        <span>Shipping Cost:</span>
        <div className="order-total">
          <h4>Box x {totalBoxesCount}</h4>
          <h3>{toMoney(deliveryCost)}</h3>
        </div>
        <div className="order-total">
          <h4>Total</h4>
          <h3 className="kkUltraViolet">{toMoney(deliveryCost)}</h3>
        </div>
      </div>}
      <div className="order-info amount">
        <div className="order-total">
          <h4 className="totalName">{ storageRequired ? "Empty boxes" : "Total Cost" }</h4>
          <h3 className="kkUltraViolet totalAmount">{
            storageRequired ?
            purchaseCostToMoney(boxes, appliedDiscount) :
            orderCostToMoney(boxes, deliveryCostRequired, appliedDiscount)
          }</h3>
        </div>
        {
          storageRequired &&
          <div className="order-total">
            <h4 className="totalName">Monthly payment</h4>
            <h3 className="kkUltraViolet totalAmount">{monthlyCostToMoney(boxes, appliedDiscount)}</h3>
          </div>
        }
        
      </div>
    </>
  )
}

interface DiscountCodeProps {
  successfulDiscount: (discount: Discount) => void;
}

function DiscountComponent(props: DiscountCodeProps) : JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [discountCode, setDiscountCode] = useState<string>("");

      return (<div className="discount-info">
        <Label htmlFor="discount">Discount:</Label>&nbsp;
        <div className="input-and-btn">
          <input id="discount" type="text" placeholder="Enter Discount Code" value={discountCode} onChange={(event) => setDiscountCode(event.target.value.toUpperCase())}/>
          <SubmitButton type="button" isSubmitting={submitting} onClick={(event) => {
            event.preventDefault();
            setSubmitting(true);

            axiosKK.get<DiscountResponse>(`/discountCode/${discountCode}`)
              .then(({data}) => {
                props.successfulDiscount(data?.discount);
              })
              .catch(err => {
                console.error(err);
              })
              .finally(() => {
                setSubmitting(false);
              })
            }}>
            Submit
            </SubmitButton>
          </div>
        </div>
      )
}

function PaymentWrapper(props: PaymentProps) {
  return <Elements stripe={stripePromise}>
    <PaymentComponent {...props} />
  </Elements>;
}

interface NoteComponentProps {
  note: string,
  updateNote: (note: string) => void,
}

function NoteComponent({ note, updateNote }: NoteComponentProps) {
  return (
    <div className="discount-info note">
      <Label htmlFor="discount">Note:</Label>&nbsp;
      <textarea name="note" id="note"
                value={ note } maxLength={ 255 }
                onChange={ (event) => updateNote(event.target.value) } />
    </div>
  )
}

export default withRouter(PaymentWrapper);