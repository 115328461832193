import React, { useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router';
import Title from '../components/Title';
import { Formik } from 'formik';
import { axiosKK } from '../services/networkRequest';
import errorMessage from '../services/errorMessage';
import SubmitButton from '../components/SubmitButton';
import { Link } from 'react-router-dom';
import { Customer, UserResponse } from '../models/APIModels';

interface ResetPasswordErrors {
  token?: string;
  password?: string;
  passwordConfirmation?: string;
}

interface ResetPasswordParams {
  token: string;
}

interface ResetPasswordData {
  token: string;
  password: string;
  passwordConfirmation: string;
}

function ResetPasswordComponent({history}: RouteComponentProps): JSX.Element {
  const [successMessage, setSuccessMessage] = useState<string>();
  const [networkError, setNetworkError] = useState<string>();
  const {token} = useParams<ResetPasswordParams>();

  const initialValues: ResetPasswordData = {
    token,
    password:'',
    passwordConfirmation:'',
  };

  return <div className="log-and-reg-page">
    <Title>Reset Password</Title>
    <div className="log-and-reg-content">
      <section>
        <div className="form-outer">
          <Formik
            initialValues={initialValues}
            validate={({password, passwordConfirmation, token}) => {
              const errors: ResetPasswordErrors = {};

              if(!token || token.length === 0) {
                errors.token = 'No token provided';
              }

              if(password.length < 8) {
                errors.password = 'Password too short';
              }

              if(password !== passwordConfirmation) {
                errors.passwordConfirmation = 'Password and confirmation don\'t match';
              }

              return errors;
            }}
            onSubmit={({password, passwordConfirmation, token}, {setSubmitting}) => {
              setSubmitting(true);
              axiosKK.post<{customer?: Customer, user?:UserResponse}>('/resetPassword', {password, passwordConfirmation, token})
                .then(({data}) => {
                  if(data.user) {
                    history.push('/admin/dashboard');
                  } else {
                    history.push('/dashboard');
                  }
                  setSuccessMessage("We have emailed you a reset link");
                })
                .catch(errorMessage(setNetworkError))
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {
              ({
                 values,
                 handleSubmit,
                 handleChange,
                 isSubmitting,
                 isValid,
                 handleBlur,
               }) =>
                <form className="log-and-reg" onSubmit={handleSubmit}>
                  <div className="close-btn-div">
                  </div>
                  <div className="form-details">
                    <p className="grey">Please enter your new password</p>
                    <div className="each">
                      <section><input id="password" name="password" onBlur={handleBlur} onChange={handleChange}
                                      value={values.password} type="password" /></section>
                      <label className="log-and-reg-label" htmlFor="password">Password</label>
                    </div>
                  </div>
                  <div className="form-details">
                    <div className="each">
                      <section><input id="passwordConfirmation" name="passwordConfirmation" onBlur={handleBlur} onChange={handleChange}
                                      value={values.passwordConfirmation} type="password" /></section>
                      <label className="log-and-reg-label" htmlFor="passwordConfirmation">Confirm Password</label>
                    </div>
                  </div>
                  <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
                    <img alt="" src="/img/right-arrow-circular-button-outline.png" />Send
                  </SubmitButton>
                  {networkError && <span className="error">{networkError}</span>}
                  {successMessage && <span className="successMessage">{successMessage}, <Link to="/login">Back to Login</Link></span>}
                </form>
            }
          </Formik>
        </div>
      </section>
    </div>
  </div>;
}

export default withRouter(ResetPasswordComponent);