import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import PaymentsDisplayComponent from '../../components/user/Payments';
import { PreviousOrderListResponse, Order } from '../../models/APIModels';
import { axiosKK } from '../../services/networkRequest';
import SmallButton from '../../components/SmallButton';
import { RouteComponentProps, withRouter } from 'react-router';
import SubmitButton from '../../components/SubmitButton';
import { toMoney } from '../../utilities/MoneyConversion';
import Moment from 'moment';

function PaymentsComponent({history}: RouteComponentProps): JSX.Element {
  
  const [orders, setOrders] = useState<Order[]>([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    axiosKK.get<PreviousOrderListResponse>("/order/previous")
    .then(result => {
      setOrders(result.data.previousOrders);
    });
  }, []);

  const allMonthlyCosts = orders?.filter(order => !order.orderComplete).map(order => order.monthlyCost);
  const allMonthlyCostsTotal = allMonthlyCosts?.reduce((a, b) => a + b, 0);
  
  return (<>
    <section className="nav-status-bar">
        <div className="status-bar-content">
          <i className="fas fa-arrow-circle-left"/>
          <SmallButton onClick={() => {history.push("/dashboard")}}>Back</SmallButton>
        </div>
      </section>
    <Title>Your Recurring Payments</Title>
    {allMonthlyCostsTotal > 0 ? 
      <p className="grey">View your recurring payments and editing you payment methods here.</p>: 
      <p className="grey noRecurringPayments">You do not have any recurring payments. Any future recurring payments will be displayed here.</p>
    }
    {allMonthlyCostsTotal > 0 && 
      <section className="customer-payment">
        <div className="display-payment-block display-recurring">
          <div className="each-type recurring-payments">
            <Title>Recurring Payments</Title>
            <div className="line" />
            <div className="monthly-rate">
              <h4>{allMonthlyCostsTotal && toMoney(allMonthlyCostsTotal)}</h4>
              <div>/ month</div>
            </div>
            <div className="all-monthly-payments">
              <MonthlyPayment orders={orders} total={allMonthlyCostsTotal} />
            </div>
          </div>
          <section className="option-box-container">
              <div className="each-box" onClick={() => {
                history.push('/update-payment-method');
              }}>
                <div className="img"><i className="far fa-credit-card" /></div>
                <div className="content">
                  <h5>Update Payment Method</h5>
                  <p>update payment method for recurring payments</p>
                </div>
              </div>
            </section>
        </div>
      </section>}

    <div className="line" />
    <Title>Your Recent Transactions</Title>
    <section className="customer-payment">
      <div className="display-payment-block display-transactions">
        <div className="each-type all-transactions">
          <Title>All Transactions</Title>
          <div className="line" />
          {orders.slice(0, showMore ? orders.length : 3).map(order => (
            <PaymentsDisplayComponent key={order.id} id={order.id} />
          ))}
          <section className="showMoreBtn">
            {orders && orders?.length >= 3 && <SubmitButton onClick={() => {
              if (showMore) {
                setShowMore(false);
              } else {
                setShowMore(true);
              }}}>
              {showMore ? <><span>Show Less</span><i className="fas fa-chevron-up" /></>: 
                      <><span>Show More</span><i className="fas fa-chevron-down" /></>}
            </SubmitButton>}
          </section>
        </div>
      </div>
    </section>
  </>)
}

interface MonthlyPaymentProps {
  orders?: Order[];
  total?: number;
}

function MonthlyPayment({orders, total}: MonthlyPaymentProps): JSX.Element {
  const history = useHistory();
  const [showMore, setShowMore] = useState(false);
  
  return (<>
    <p className="grey">This is a full list of orders with recurring payments</p>
    <section className="monthlyPayment">  
      <div className="all-cost-layout">
        {orders?.filter(order => !order.orderComplete).slice(0, showMore ? orders.length : 3).map(order => 
          <div key={order.id} className="each">
            <div className="left-container">
              <div onClick={() => history.push(`/order/${order.id}`)} className="orderId">{order.id}</div>
              <div className="details">
                <h6>{order.storageRequired ? 'Storage' : 'Shipping'} Order</h6>
                <span className="updatedAt">Last Payment: {Moment(order?.updatedAt).format('Do MMM YYYY')}</span>
                <p>This is an order to{' '}{order.city},{' '}
                  <span onClick={() => history.push(`/order/${order.id}`)}>click here</span> for more details.
                </p>
              </div>
            </div>
            <div className="cost"><span className="cost">{toMoney(order.monthlyCost)}</span></div>
          </div>)
        }
        <section className="showMoreBtn">
          {orders && orders?.filter(order => !order.orderComplete).length >= 3 && <SubmitButton onClick={() => {
            if (showMore) {
              setShowMore(false);
            } else {
              setShowMore(true);
            }}}>
            
              {showMore ? <><span>Show Less</span><i className="fas fa-chevron-up" /></>: 
                        <><span>Show More</span><i className="fas fa-chevron-down" /></>}
          </SubmitButton>}
        </section>
      </div>
      <div className="total">
        <span>Total</span>
        <span>{total && toMoney(total)}</span>
      </div>
    </section>
  </>)
}

export default withRouter(PaymentsComponent);