import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch, useHistory } from 'react-router-dom';
import './styles/styles.scss';
import BoxesComponent from './pages/Boxes';
import CollectionComponent from './pages/Collection';
import DeliveryComponent from './pages/Delivery';
import ConfirmationComponent from './pages/Confirmation';
import PaymentComponent from './pages/Payment';
import BackToPaymentComponent from './pages/BackToPayment';
import SuccessComponent from './pages/Success';
import LandingComponent from './pages/Landing';
import LoginComponent from './pages/Login';
import RegistrationComponent from './pages/Register';
import ForgotPasswordComponent from './pages/ForgotPassword';
import ResetPasswordComponent from './pages/ResetPassword';
import UserDashboardComponent from './pages/User/Dashboard';
import UserOrderComponent from './pages/User/Order';
import UserOrderEditComponent from './pages/User/OrderEdit';
import UserProfileComponent from './pages/User/Profile';
import UserPaymentsComponent from './pages/User/Payments';
import UserUpdatePaymentComponent from './pages/User/UpdatePayment';
import AdminDashboardComponent from './pages/Admin/Dashboard';
import AdminUserComponent from './pages/Admin/User';
import AdminUsersListComponent from './pages/Admin/Users';
import AdminUsersCreateComponent from './pages/Admin/UsersCreate';
import AdminOrderComponent from './pages/Admin/Order';
import AdminOrderEditComponent from './pages/Admin/OrderEdit';
import AdminCustomerComponent from './pages/Admin/Customer';
import AdminCustomersComponent from './pages/Admin/Customers';
import AdminDiscountComponent from './pages/Admin/Discounts';
import AdminProductComponent from './pages/Admin/Product';
import AdminProductsComponent from './pages/Admin/Products';
import Header from './components/Header';
import UserContext, { User, UserState } from './contexts/UserContext';
import { axiosKK } from './services/networkRequest';
import { Role } from './utilities/Role';
import { RouteComponentProps } from 'react-router';
import useGTM from '@elgorditosalsero/react-gtm-hook';

const loginPath = "/login";
const adminDashboardPath = "/admin/dashboard"

function LoginRedirect() {
  return <Redirect to={ loginPath } />;
}

function AdminRedirect() {
  return <Redirect to={ adminDashboardPath } />;
}

interface PrivateRouteProps extends RouteProps {
  user?: User,
  redirectComponent: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>,
  roles?: string[],
}

interface NoReturnRouteProps extends RouteProps {
  redirectPath: string,
}

function PrivateRoute({ component: Component, user, redirectComponent, roles, ...props }: PrivateRouteProps) {
  let allowed = user && (!roles || roles.includes(user.role));

  return (<Route { ...props } component={allowed ? Component : redirectComponent} />);
}

function NoReturnRoute({ component: Component, redirectPath, ...props }: NoReturnRouteProps) {
  let allowed = useHistory().action !== "POP";

  return (<Route { ...props } component={allowed ? Component : () => <Redirect to={redirectPath} />} />);
}

function App() {
  let user: User | undefined;
  const userString = localStorage.getItem('user');
  const [gtmInitialised, setGtmInitialised] = useState(false);

  if(userString) {
    try {
      user = JSON.parse(userString);
      if(user?.token) {
        axiosKK.defaults.headers.common['Authorization'] = 'bearer ' + user.token;
      }
    } catch(error) {
      // do nothing
    }
  }

  const { init, UseGTMHookProvider } = useGTM();

  useEffect(() => {
    if(!gtmInitialised) {
      const id = process.env.REACT_APP_GTM_ID;
      const dataLayerName = process.env.REACT_APP_GTM_DATA_LAYER_NAME;

      if(!id || !dataLayerName) {
        console.error('GTM_ID or GTM_DATA_LAYER_NAME is not defined');
        return;
      }

      init({
        id: id,
        dataLayerName: dataLayerName,
      });
      setGtmInitialised(true);
    }
  }, [gtmInitialised, init, setGtmInitialised]);

  const history = useHistory();
  const [userState, setUserState] = useState<UserState>({
    updateUser: () => {},
    user,
  });
  useEffect(() => {
    setUserState(u => ({
      ...u,
      updateUser: (user: User) => {
        setUserState(u2 => ({
          ...u2,
          user
        }));
      }
    }));

    const mapScriptId = "google-maps";
    const map = document.getElementById(mapScriptId)
    if(!map) {
      const script = document.createElement("script");

      script.id = mapScriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&libraries=places`;

      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      }
    }
  }, []);

  const [isFirsTimeConfirmationTrigger, setIsFirstTimeConfirmationTrigger] = useState(true);

  return (
    <Router>
      <UserContext.Provider value={userState}>
        <UseGTMHookProvider>
          <div className="App">
            <Header />
            <section className="App-white-box">
              <Switch>
                <Route exact={true} component={() => <LoginComponent postLogin={(user) => {
                  setUserState({
                    ...userState,
                    user
                  })
                }} />} path={loginPath} />

                <Route exact={true} component={() => <RegistrationComponent postRegistration={() => history.push("/dashboard")} path="/register" createAdminAccount={ false } setUserState={setUserState} userState={userState}/>} path="/register" />
                <Route exact={true} component={ForgotPasswordComponent} path="/forgot-password" />
                <Route exact={true} component={ResetPasswordComponent} path="/password-reset/:token" />
                <Route exact={true} component={LandingComponent} path="/" />
                <Route exact={true} component={BoxesComponent} path="/:route(storage|shipping|suitcase)/boxes" />
                <Route exact={true} component={BoxesComponent} path="/:route(storage)/boxes/packages" />
                <Route exact={true} component={CollectionComponent} path="/:route(storage|shipping|suitcase)/collection_address" />
                <Route exact={true} component={DeliveryComponent} path="/:route(storage|shipping|suitcase)/delivery_address" />
                <Route exact={true} component={() => <ConfirmationComponent user={userState.user}
                                                                            isFirstTimeTriggered={ isFirsTimeConfirmationTrigger }
                                                                            updateTrigger={ () => setIsFirstTimeConfirmationTrigger(false)}
                                                                            userState={userState}
                />} path="/:route(storage|shipping|suitcase)/confirmation" />

                <Route exact={true} component={PaymentComponent} path="/:route(storage|shipping|suitcase)/payment" />
                <Route exact={true} component={PaymentComponent} path="/:route(storage|shipping|suitcase)/payment/3ds-secure" />
                <Route exact={true} component={BackToPaymentComponent} path="/:route(storage|shipping|suitcase)/back-to-payment"  />

                <NoReturnRoute exact={true} redirectPath="/dashboard" component={SuccessComponent} path="/:route(storage|shipping|suitcase)/success" />
                <PrivateRoute user={userState.user} exact={true} redirectComponent={LoginRedirect} component={UserDashboardComponent} path="/dashboard" />
                <PrivateRoute user={userState.user} exact={true} redirectComponent={LoginRedirect} component={UserOrderComponent} path="/order/:id" />
                <PrivateRoute user={userState.user} exact={true} redirectComponent={LoginRedirect} component={UserOrderEditComponent} path="/order/:id/edit" />
                <PrivateRoute user={userState.user} exact={true} redirectComponent={LoginRedirect} component={UserProfileComponent} path="/profile" />
                <PrivateRoute user={userState.user} exact={true} redirectComponent={LoginRedirect} component={UserPaymentsComponent} path="/payments"/>
                <PrivateRoute user={userState.user} exact={true} redirectComponent={LoginRedirect} component={UserUpdatePaymentComponent} path="/update-payment-method"/>
                <PrivateRoute user={userState.user} exact={true} redirectComponent={AdminRedirect} roles={[Role.Admin, Role.Warehouse]} component={AdminDashboardComponent} path="/admin/dashboard" />
                <PrivateRoute user={userState.user} exact={true} redirectComponent={AdminRedirect} roles={[Role.Admin]} component={AdminUserComponent} path="/admin/user/:id" />
                <PrivateRoute user={userState.user} exact={true} redirectComponent={AdminRedirect} roles={[Role.Admin]} component={AdminUsersListComponent} path="/admin/users" />
                <PrivateRoute user={userState.user} exact={true} redirectComponent={AdminRedirect} roles={[Role.Admin]} component={AdminUsersCreateComponent} path="/admin/users/create" />
                <PrivateRoute user={userState.user} exact={true} redirectComponent={AdminRedirect} roles={[Role.Admin, Role.Warehouse]} component={AdminOrderComponent} path="/admin/order/:id" />
                <PrivateRoute user={userState.user} exact={true} redirectComponent={AdminRedirect} roles={[Role.Admin]} component={AdminOrderEditComponent} path="/admin/order/:id/edit" />
                <PrivateRoute user={userState.user} exact={true} redirectComponent={AdminRedirect} roles={[Role.Admin, Role.Warehouse]} component={AdminCustomersComponent} path="/admin/customers" />
                <PrivateRoute user={userState.user} exact={true} redirectComponent={AdminRedirect} roles={[Role.Admin, Role.Warehouse]} component={AdminCustomerComponent} path="/admin/customer/:id" />
                <PrivateRoute user={userState.user} exact={true} redirectComponent={AdminRedirect} roles={[Role.Admin]} component={AdminDiscountComponent} path="/admin/discounts" />
                <PrivateRoute user={userState.user} exact={true} redirectComponent={AdminRedirect} roles={[Role.Admin]} component={AdminProductComponent} path="/admin/product/:id" />
                <PrivateRoute user={userState.user} exact={true} redirectComponent={AdminRedirect} roles={[Role.Admin]} component={AdminProductsComponent} path="/admin/products" />
                <Route exact={true} component={NotFound} />
              </Switch>
            </section>
          </div>
        </UseGTMHookProvider>
      </UserContext.Provider>
    </Router>
  );
  
}

function NotFound(): JSX.Element {
  return <h2>Unknown</h2>;
}

export default App;
