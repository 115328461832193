import React from 'react';

interface NavigationStatusProps {
  step: number
}

function NavigationStatus({step}: NavigationStatusProps): JSX.Element {
  const steps: JSX.Element[] = [];
  const stepLimit = 6;


  for(let i = 1; i <= stepLimit; i++) {
    const active = 'indicator-line active';
    const inactive = 'indicator-line';

    steps.push((
      <div key={i} className={step >= i ? active : inactive}>
        <span className={step >= i ? 'active' : undefined}>
          {i}
        </span>
      </div>
    ));
  }

  return (
    <div className="App-numerical-indicator">
      {steps.filter((_, i) => i < 3)}
      <div className="indicator-line box-indicator">
        <span className="box"><img src="/img/box-icon-white.png" alt="" /></span>
      </div>
      {steps.filter((_, i) => i >= 3)}
    </div>);
}

export default NavigationStatus;