import { RouteComponentProps, StaticContext, withRouter } from 'react-router';
import React, { useContext, useEffect } from 'react';
import NavigationStatus from '../components/NavigationStatus';
import { BoxData, CheckoutItem, CheckoutProduct, Dimensions, Discount, Product } from '../models/APIModels';
import { Address } from '../components/AddressLookup';
import { orderCostToMoney, purchaseCostToMoney, suitcaseOrderToMoney } from '../utilities/MoneyConversion';
import useGtmEventTracking from '../components/useGtmEventTracking';
import { displayLoginName } from '../components/user/DisplayLoginName';
import UserContext from '../contexts/UserContext';

interface PaymentProps extends RouteComponentProps<undefined, StaticContext, PaymentState> {
}

interface PaymentState {
  boxes: BoxData[];
  collectionAddress: Address;
  collectionDate: Date;
  deliveryAddress?: Address;
  dontKnowAddress: boolean;
  deliveryDate?: Date;
  dontKnowDate: boolean;
  storageRequired: boolean;
  suitcases?: Dimensions[];
  suitcaseData?: Product;
  eecProducts?: CheckoutProduct[];
  items?: CheckoutItem[];
  orderId?: number;
  discount?: Discount;
}

function SuccessComponent({history}: PaymentProps): JSX.Element | null {
  const {boxes, suitcases, storageRequired, suitcaseData, eecProducts, items, orderId, discount} = history.location.state;
  const cost =  suitcases ? suitcaseOrderToMoney(suitcases.length, suitcaseData?.shippingCost, discount) : (storageRequired ? purchaseCostToMoney(boxes, discount, true) : orderCostToMoney(boxes, true, discount));

  const { user } = useContext(UserContext);

  const deliveryFeeString = process.env.REACT_APP_DELIVERY_FEE
  if(!deliveryFeeString) {
    throw new Error("No delivery fee set")
  }
  const deliveryFee = parseInt(deliveryFeeString);
  
  let revenue = cost.substring(1);
  const eecPurchaseObject = {
    event: 'eec_purchase',
    ecommerce: {
      currencyCode: 'GBP',
      purchase: {
        actionField: {
          id: orderId,
          revenue: revenue,
          shipping: suitcases ? cost : deliveryFee / 100,
          affiliation: 'Online Store',
          coupon: discount?.discountCode
        },
        products: eecProducts
      }
    }
  };

  const purchaseObject = {
    event: 'purchase',
    ecommerce: {
      transaction_id: orderId,
      affiliation: 'Online Store',
      value: Number.parseFloat(revenue),
      shipping: suitcases? cost : deliveryFee / 100,
      currency: 'GBP',
      coupon: discount?.discountCode,
    },
    items: items
  };

  const gtmEventTracking = useGtmEventTracking();
  useEffect(() => {
    gtmEventTracking({event: 'booking_step_purchase'})
    gtmEventTracking(eecPurchaseObject)
    gtmEventTracking(purchaseObject)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtmEventTracking]);

  return (
    <>
      <section className="login-status-bar">
        <div className="status-bar-content">
          <p className="grey"> { displayLoginName( user )} </p>
        </div>
      </section>
      <NavigationStatus step={6} />
      <div className="App-content">
        <div className="success">
          <h2 className="kkUltraViolet-h2">Success!</h2>
          <h2>Thank you for your order</h2>
          <span>Check your email for an order confirmation</span>
          {/*<span>Stay in the know, track your order in <Link to="/account">My account</Link></span>*/}
          <h3>
            <button className="submitButton" onClick={() => window.open("https://www.kitkeeper.co.uk/pre-post-12-collection", "_blank")}>
              Request Collection Time-Slot
            </button>
          </h3>
        </div>
      </div>
    </>);
}

export default withRouter(SuccessComponent);