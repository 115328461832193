import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Order, PaymentResponse, OrderResponse } from '../../models/APIModels';
import { toMoney } from '../../utilities/MoneyConversion';
import Label from '../../components/Label';
import Moment from 'moment';
import SubmitButton from '../../components/SubmitButton';
import { axiosKK } from '../../services/networkRequest';

interface OrderParams {
  id: number;
}

export default function PaymentsDisplayComponent({id}: OrderParams): JSX.Element {
  const history = useHistory();

  const [order, setOrder] = useState<Order>();
  const payments = order?.payments || [];

  useEffect(() => {
    if(!order) {
      axiosKK.get<OrderResponse>(`order/${id}`)
        .then(response => {
          setOrder(response.data.order);
        });
    }
  }, [order, id]);

  return (<>
    {payments.length > 0 && <section className="eachPayments">
      <div onClick={() => history.push(`/order/${id}`)} className="left-column">
        <p>{id}</p>
      </div>
      <main>
        <div className="header">
          <div className="container">
            <div className="block">
              <h6>Order Placed</h6>
              <p>{Moment(order?.createdAt).format('dddd, MMM Do YYYY')}</p>
            </div>
            <div className="block orderTypeBlk">
              <h4 className="orderType">{order?.storageRequired ? 'Storage' : 'Shipping'} Order</h4>
            </div>
          </div>
          <div className="container">
            <SubmitButton onClick={() => history.push(`/order/${id}`)}>
              View<img alt="" src="/img/right-arrow-circular-button-outline.png" />
            </SubmitButton>
          </div>
          
        </div>
        <div className="payments-update-block">
          {payments.map((payment, index) => <EachPayment key={index} payment={payment} />)}
        </div>
        <div className="lastUpdated">
          <p>Last updated : {Moment(order?.updatedAt).format('dddd, MMM Do YYYY')}</p>
        </div>
      </main>
    </section>}
  </>);
}

interface EachPaymentProps {
  payment: PaymentResponse
}

function EachPayment({payment}: EachPaymentProps): JSX.Element {

  return (
    <div className="eachPay">
      <div className={payment.succeeded ? 'status succeeded' : 'status fail'}><p>{payment.status}</p></div>
      <div className="total">
        <Label>Amount:</Label>
        <p>{toMoney(payment.amount)}</p>
      </div>
      <span className="createdAt">Transaction made on {Moment(payment?.createdAt).format('dddd, MMM Do YYYY')}</span>
    </div>
  )
}