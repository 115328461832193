import React, { useState } from 'react';
import SubmitButton from '../../components/SubmitButton';
import { Order, OrderResponse } from '../../models/APIModels';
import Moment from 'moment';
import Label from '../../components/Label';
import { axiosKK } from '../../services/networkRequest';
import Modal from 'react-modal';
import { Formik } from 'formik';
import AddressLookup, { Address, validateAddress } from '../../components/AddressLookup';
import errorMessage from '../../services/errorMessage';
import { AxiosError } from 'axios';
import DayPickerInputValue  from '../../components/edit/DayPickerInputValue';
import { useDateDisabler } from '../../utilities/DateDisabler';

interface OrderModalProps {
  update: (updated: Order) => void;
  close: () => void;
  editMode: boolean;
  order: Order;
}

interface OrderEditDeliveryDetailsErrors {
  deliveryDate?: string;
  deliveryAddress?: string;
}

export default function OrderEditDeliveryDetailsModal({editMode, order, close, update}: OrderModalProps): JSX.Element {
  const disableDelivery = order.statuses.reduce<boolean>((previous, status) => {
    return previous && status.statusId >= 5;
  }, false);

  const [deliveryAddress, setDeliveryAddress] = useState<Address>({});
  const [networkError, setNetworkError] = useState<string>();
  
  // 0 = Sunday, 6 = Saturday. 
  const currentDayOfWeek = Moment().day()
  let daysToAdd = 3;
  if(currentDayOfWeek === 3 || currentDayOfWeek === 4 || currentDayOfWeek === 5) {
    daysToAdd = 5;
  } else if (currentDayOfWeek === 6) {
    daysToAdd = 4;
  }
  
  // Do not allow edits to delivery details if delivery date is within 3 working days of current date.
  const cutOffTime = Moment().add(daysToAdd, 'd').set('hour', 23).set('minute', 59).set('second', 59);
  const deliveryDateIsBeforeCutOff = order.deliveryDate && Moment(order?.deliveryDate).isBefore(cutOffTime);
  const disabledDays = useDateDisabler(null, new Date(order.collectionDate));

  const initialValues: Order = {
    ...order
  }

  return (<Modal
    isOpen={editMode}
    onRequestClose={close}
    style={{  content: {
        display: 'flex',
      }}}
    contentLabel="Edit Delivery Details"
  >
    <Formik
      initialValues={initialValues}
      validate={({deliveryAddress: deliveryAddressString, deliveryDate}: Order) => {
        const errors: OrderEditDeliveryDetailsErrors = {};

        if(Moment(deliveryDate).isBefore(order?.collectionDate) || Moment(deliveryDate).isSame(order?.collectionDate, 'date')) {
          errors.deliveryDate = `Delivery date must be after collection date on ${Moment(order?.collectionDate).format('dddd, MMM Do YYYY')}. 
                                Alternatively, edit your collection details first`
        }

        // Only authenticate address if it is not identical to the original
        if (order?.deliveryAddress !== deliveryAddressString || !order.deliveryAddress) {

          if (!deliveryAddressString || deliveryAddressString.length <= 0) {
            errors.deliveryAddress = 'Delivery address is invalid';
          } else if (!validateAddress(deliveryAddress)) {
            errors.deliveryAddress = 'Delivery address is incomplete';
          }
        }

        return errors;
      }}
      onSubmit={(values: Order, {setSubmitting, resetForm}) => {
        setSubmitting(true);
        axiosKK.put<OrderResponse>('/order/update', {
          ...values, 
          "deliveryCountry": deliveryAddress.country,
          "deliveryPostcode": deliveryAddress.postCode
        }
        )
          .then(({data}) => {
            update(data.order);
          })
          .catch(error => {
            errorMessage(setNetworkError)(error);
            setSubmitting(false);
          })
          .catch((error: Error | AxiosError) => {
            const jsonError = JSON.parse(JSON.stringify(error));

            if(jsonError.hasOwnProperty('response')) {
              console.error((error as AxiosError).response?.data);
            } else if(jsonError.hasOwnProperty('message')) {
              console.error(error.message);
            } else {
              console.error(error);
            }
            setSubmitting(false);
          })
          .finally(() => {
            setSubmitting(false);
            resetForm();
            close();
          });
      }}
    >
      {
        ({
          values,
          isSubmitting,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit,
          errors,
          isValid,
          dirty,
          }) => <>
          <form onSubmit={handleSubmit} className="order-edit-form" name="order-edit-form">
            <div className="modal-header">
              <h3>Edit Delivery Details</h3>
              <SubmitButton red={true} onClick={close}>Close</SubmitButton>
            </div>
            <div className="modal-content">
            {deliveryDateIsBeforeCutOff? <div className="error-block"><p>Can not change delivery details within 3 working days of delivery date</p></div> : null}
              <div className="order-edit-inputs">
                <Label htmlFor="date">Delivery Date</Label>
                <div className="current-info">
                  <div className="current-label"><h6>Current</h6></div>
                   <div className="info"><span>{order.deliveryDate ? Moment(order.deliveryDate).format('dddd, MMM Do YYYY') : "Not Set"}</span></div>
                </div>
                <div className="DO_NOT_REMOVE_THIS_DIV">
                  <div className="dateLookup">
                    <div className="title-and-input">
                      <div className="title">
                        <section>Delivery Date:</section>
                        <img alt="" src="/img/calendar-icon.png" />
                      </div>
                      <span className="error">{errors.deliveryDate}</span>
                      {deliveryDateIsBeforeCutOff? 
                        <span className="error">Cannot edit date</span> : 
                        <DayPickerInputValue 
                         id="deliveryDate" 
                         handleFormChange={setFieldValue}
                         value={values.deliveryDate}
                         disabled={disableDelivery} 
                         onChange={handleChange} 
                         handleBlur={handleBlur}
                         disabledDays={disabledDays}
                       />
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-edit-inputs">
                <Label htmlFor="date">Delivery Address</Label>
                <div className="current-info">
                  <div className="current-label"><h6>Current</h6></div>
                  <div className="info"><span>{order.deliveryAddress || "Not Set" }</span></div>
                </div>
                <div className="stuff">
                <span className="error">{errors.deliveryAddress}</span>
                {deliveryDateIsBeforeCutOff? 
                  <span className="error">Cannot edit address</span> : 
                  <AddressLookup name="deliveryAddress" address={deliveryAddress} setAddress={(address, addressString) => {
                    setFieldValue("deliveryAddress", addressString, true);
                    setDeliveryAddress(address);
                  }}
                    componentRestrictions={{country: 'uk'}} defaultValue={values.deliveryAddress} />}
                </div>
              </div>              
            </div>
            {networkError && <span className="error">{networkError}</span>}
            <SubmitButton disabled={deliveryDateIsBeforeCutOff || !isValid || !dirty} isSubmitting={isSubmitting}>
              Update
            </SubmitButton>
          </form>
        </>
      }
    </Formik>
  </Modal>);
}