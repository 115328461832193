export interface BoxData {
  id: number;
  name: string;
  description: string;
  sortOrder: number;
  purchaseCost: number;
  shippingCost: number;
  storageCost: number;
  count: number;
  imageUrl: string;
  dimensions: Dimensions;
  returnFee: boolean;
  chargeShipping: boolean;
}

export interface Dimensions {
  depth: number,
  height: number,
  width: number,
  weight: number,
}

export interface Discount {
  deletedAt: number;
  createdAt: number;
  updatedAt: number;
  discountCode: string;
  discountPercentage: number;
  expires: number;
  recurring: boolean;
  upFront: boolean;
  id: number;
}

export interface DiscountAndCount extends Discount {
  count: number;
}

export interface MakeOrderResponse {
  order: Order,
  paymentIntentSecret?: string,
  setupIntentSecret?: string,
  jwtSecret: string,
  paymentStatus: PaymentStatus,
}

export enum PaymentStatus {
  succeeded = 'succeeded',
  requiresAction = 'requires_action',
  requiresPaymentMethod = 'requires_payment_method',
  trialing = 'trialing',
  noneNeeded = 'none_needed'
}

export interface MakePaymentRequest {
  orderId: number;
  paymentMethodId: string;
}

export interface UpdatePaymentRequest {
  paymentMethodId: string;
}

export interface MakePaymentResponse {
  session: string;
}

export interface BoxCount {
  [key: number]: number;
}

export interface BookingParams {
  route: string;
}

export interface MakeOrderRequest {
  city?: string;
  boxes: BoxCount;
  collectionAddress: string;
  deliveryAddress?: string;
  collectionDate: number;
  deliveryDate?: number;
  roomPack: boolean;
  storageRequired: boolean;
  discountCode?: string;
  note: string;
  paymentMethodId: string;
  collectionCountry?: String;
  deliveryCountry?: String;
  collectionPostcode?: String;
  deliveryPostcode?: String;
}

export interface MakeSuitcaseOrderRequest {
  city?: string;
  collectionAddress: string;
  deliveryAddress?: string;
  collectionDate: number;
  deliveryDate?: number;
  suitcases: Dimensions[];
  discountCode?: string;
  note: string;
  paymentMethodId: string;
  country?: String;
  collectionCountry?: String;
  deliveryCountry?: String;
  collectionPostcode?: String;
  deliveryPostcode?: String;
}

export interface Order extends MakeOrderRequest {
  id: number;
  createdAt: number;
  updatedAt: number;
  payments: PaymentResponse[];
  statuses: StatusResponse[];
  customer: UserResponse;
  orderBoxes: OrderBoxesResponse[];
  initialCost: number;
  monthlyCost: number;
  customerId: number;
  orderComplete: boolean;
  roomPackIncluded?: boolean;
  suitcaseOrder: boolean;
  notes: Note[];
  collectionCountry?: String;
  deliveryCountry?: String;
  collectionPostcode?: String;
  deliveryPostcode?: String;
}

export interface Note {
  id: number,
  orderId: number,
  text: string,
  updatedAt: number,
  createdAt: number,
}

export interface OrderResponse {
  order: Order;
}

export interface OrderListResponse {
  orders: Order[];
}

export interface PreviousOrderListResponse {
  previousOrders: Order[];
}

export interface StatusResponse {
  id: number; 
  orderId: number; 
  customerId: number; 
  status: {id: number, type: string}; //Do this rather than make a new model
  statusId: number;
  updatedAt: number;
}

export interface OrderBoxesResponse {
  id: number;
  barcode: string;
  boxId: number;
  orderId: number;
  box: BoxData;
  dimensions: Dimensions;
  shippingCost: number;
}

export interface PaymentResponse {
  id: number;
  orderId: number;
  customerId: number;
  amount: number;
  succeeded: boolean;
  createdAt: number;
  stripeId?: string;
  status: string;
}

export interface Customer {
  orders: Order[];
  payments?: PaymentResponse[];
  id: number;
  createdAt: number;
  updatedAt: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  stripeId: string;
  phoneNumber?: string;
}

export interface UserResponse {
  id: number;
  createdAt: number;
  updatedAt: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  admin?: boolean;
  role?: string;
}

export interface CustomerListResponse {
  customers: Customer[];
}

export interface CustomerResponse {
  customer: Customer;
}

export interface UserListResponse {
  users: UserResponse[];
}

export interface DiscountResponse {
  discount: Discount;
}

export interface ProductResponse {
  product: Product;
}

export interface DiscountListResponse {
  discounts: DiscountAndCount[];
}

export interface ProductAndCount extends Product {
  count: number;
}

export interface ProductListResponse {
  products: ProductAndCount[];
}

export interface Product extends BoxData {
  createdAt: number;
  emailField: string;
  updatedAt: number;
}

export interface CheckoutProduct {
  name?: string;
  id?: number;
  price: string;
  brand: string;
  category: string;
  quantity: number;
  affiliation: string;
  coupon?: string;
  discount?: string;
  currency?: string;
}

export interface CheckoutItem {
  item_id?: string,
  item_name: string,
  affiliation: string,
  coupon?: string,
  currency: string,
  discount?: string,
  index: number,
  item_brand: string,
  item_category: string,
  price: string,
  quantity: string,
}

export interface BankHolidays {
  "england-and-wales": BankHolidayDivision;
  "northern-ireland": BankHolidayDivision;
  "scotland": BankHolidayDivision;
}

interface BankHolidayDivision {
  division: string;
  events: BankHoliday[];
}

export interface BankHoliday {
  bunting: boolean;
  date: string;
  notes: string;
  title: string;
}