import { Role } from '../utilities/Role';
import Error from './Error';
import React, { ChangeEventHandler, FocusEventHandler } from 'react';
import { FormikErrors, FormikTouched } from 'formik/dist/types';

interface RoleProps {
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  errors: FormikErrors<{ role: string }>;
  touched: FormikTouched<{ role: boolean | undefined }>;
  value?: string;
  name: string;
}

export function RoleSelection({onBlur, onChange, errors, name, touched, value}: RoleProps) {
  return (
    <div className="each each-radio">
      <section>
        <div className="radio-container">

          <input type="radio" id="role_admin" name={name} onBlur={onBlur} onChange={onChange} value={Role.Admin} checked={value === Role.Admin} />
          <label htmlFor="admin">Admin</label>
        </div>
        <div className="radio-container">
          <input type="radio" id="role_warehouse" name={name} onBlur={onBlur} onChange={onChange}
                 value={Role.Warehouse} checked={value === Role.Warehouse} />
          <label htmlFor="warehouse">Warehouse</label>
        </div>
      </section>
      <label className="log-and-reg-label" htmlFor="role">Role (For Administrative Use)</label>
      <Error touched={touched.role} error={errors.role} />
    </div>
  );
}