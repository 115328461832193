import { SHIPPING_FEE } from '../constants';
import { BoxData, Discount } from '../models/APIModels';

const deliveryFeeString = process.env.REACT_APP_DELIVERY_FEE
if(!deliveryFeeString) {
  throw new Error("No delivery fee set")
}
const deliveryFee = parseInt(deliveryFeeString);

export function toMoney(num: number) : string {
  return `£${(num / 100).toFixed(2)}`;
}

export function boxToMoney(box: BoxData, discount?: Discount) {
  const discountMultiplier = discount?.recurring === false ? (1 - discount.discountPercentage / 100) : 1;
  return toMoney(box.count * box.purchaseCost * discountMultiplier);
}

export function shippingOrderBoxToMoney(box: BoxData, discount?: Discount) {
  const discountMultiplier = discount?.recurring === false ? (1 - discount.discountPercentage / 100) : 1;
  const { count, purchaseCost, shippingCost, chargeShipping } = box;
  const modifiedShippingCost = chargeShipping === false ? 0 : shippingCost;
  return toMoney((count * purchaseCost) + (count * modifiedShippingCost) * discountMultiplier);  
}

export function monthlyCostToMoney(boxes: BoxData[], discount?: Discount) {
  const discountMultiplier = discount?.recurring ? (1 - discount.discountPercentage / 100) : 1;

  const cost = boxes ? boxes.reduce((acc, box) => acc + box.count * box.storageCost, 0) : 0;
  return toMoney(cost * discountMultiplier);
}

export function purchaseCostToMoney(boxes: BoxData[], discount?: Discount, includeEmptyBoxesRate: boolean = false) : string {
  const discountMultiplier = discount?.upFront ? (1 - discount.discountPercentage / 100) : 1;

  let cost = boxes ? boxes.reduce((acc, box) => acc + box.count * box.purchaseCost, 0) : 0;

  if (includeEmptyBoxesRate) cost += deliveryFee;
  return toMoney(cost * discountMultiplier);
}

export function orderCostToMoney(boxes: BoxData[], includeEmptyBoxesRate: boolean, discount?: Discount) : string {
  const discountMultiplier = discount?.recurring === false ? (1 - discount.discountPercentage / 100) : 1;
  const itemsWithShippingCosts = boxes.filter(it => it.chargeShipping);
  const itemsWithoutShippingCosts = boxes.filter(it => !it.chargeShipping);
  const { purchaseCostToMoney, count } = itemsWithShippingCosts.reduce(({purchaseCostToMoney, count}, box) => {
    return {
      purchaseCostToMoney: purchaseCostToMoney + (box.count * box.purchaseCost),
      count: count + box.count
    };
  }, {purchaseCostToMoney: 0, count: 0});
  
  
  const orderCostWithShipping = purchaseCostToMoney + (SHIPPING_FEE * count);

  let orderCostingWithoutShipping = 0;
  if(itemsWithoutShippingCosts.length){
    for(let i =0; i< itemsWithoutShippingCosts.length; i+=1){
      const {purchaseCost, count} = itemsWithoutShippingCosts[i];
      orderCostingWithoutShipping += (purchaseCost*count);
    }
  }

  return toMoney(boxes ? (includeEmptyBoxesRate ? (orderCostingWithoutShipping + orderCostWithShipping + deliveryFee) * discountMultiplier : (orderCostingWithoutShipping + orderCostWithShipping) * discountMultiplier) : 0);
}


export function suitcaseOrderToMoney(suitcaseQuantity: number, suitcaseShippingCost?: number, discount?: Discount) : string {
  const discountMultiplier = discount ? (1 - discount.discountPercentage / 100) : 1;

  return suitcaseShippingCost ? toMoney(suitcaseQuantity * suitcaseShippingCost * discountMultiplier) : '£0.00';
}