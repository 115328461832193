import React, { useContext, useEffect, useState } from 'react';
import SubmitButton from '../../components/SubmitButton';
import SmallButton from '../../components/SmallButton';
import { RouteComponentProps, useParams, withRouter, StaticContext } from 'react-router';
import { Order, OrderResponse } from '../../models/APIModels';
import Moment from 'moment';
import Title from '../../components/Title';
import Label from '../../components/Label';
import { axiosKK } from '../../services/networkRequest';
import FileDownload from 'js-file-download';
import Barcode from '../../components/Barcode';
import StatusBlock from '../../components/StatusBlock';
import PaymentBlock from '../../components/PaymentBlock';
import PaymentStatusBlock from '../../components/PaymentStatusBlock';
import { Role } from '../../utilities/Role';
import UserContext from '../../contexts/UserContext';
import { NoteBlock } from '../../components/NoteBlock';
import { OrderBoxesDisplay } from '../User/Order';

interface OrderParams {
  id: string;
}

interface OrderComponentState {
  order?: Order;
}

function AdminOrderComponent({history}: RouteComponentProps<undefined, StaticContext, OrderComponentState>): JSX.Element {

  const { id } = useParams<OrderParams>();
  const [order, setOrder] = useState(history.location?.state?.order);
  const statuses = order?.statuses || [];
  const customer = order?.customer;
  const payments = order?.payments || [];
  const notes = order?.notes || [];
  const orderBoxes = order?.orderBoxes || [];
  const { user: loggedInUser } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    if(!order) {
      axiosKK.get<OrderResponse>(`/order/${id}`)
        .then(({data}) => {
          setOrder(data.order);
        });
    }
  }, [order, id]);

  return (<>
      <section className="nav-status-bar">
        <div className="status-bar-content">
          <i className="fas fa-arrow-circle-left"/>
          <SmallButton className="submitButton" onClick={() => {history.push('/admin/dashboard')}}>Back</SmallButton>
        </div>
      </section>
      <Title>{`${order?.suitcaseOrder ? 'Suitcase' : order?.storageRequired ? 'Storage' : 'Shipping'} Order Overview`}</Title>
      <section className="print-doc-and-items-block">
        <div className="print-doc admin-print-doc">
          <div className="top-border">Basic Info</div>
          <div className="second-border">
            <p className="roomPack">{order?.roomPackIncluded ? 'Room Pack is Included' : 'Room Pack is Not Included'}</p>
            <p className="date">Order Created At: {Moment(order?.createdAt).format('dddd, MMM Do YYYY')}</p>
          </div>
          <div className="basic-block">
            <div className="id-city-requirement">
              <div className="id-and-city">
                <p className="id">{order?.id}</p>
                <p className="city">{order?.city}</p>
                {customer && <div onClick={() => history.push(`/admin/customer/${customer.id}`)} className="name"><p>{customer?.firstName} {customer?.lastName}</p></div>}
              </div>
            </div>
            <div className="addresses-block">
              <div className="each">
                <Label>Collection Address:</Label>
                <p>{order?.collectionAddress}</p>
                <p>{Moment(order?.collectionDate).format('dddd, MMM Do YYYY')}</p>
              </div>
              <div className="each">
                <Label>Delivery Address:</Label>
                <p>{order?.deliveryAddress}</p>
                <p>{order?.deliveryDate && Moment(order.deliveryDate).format('dddd, MMM Do YYYY')}</p>
              </div>
            </div>
          </div>

          {
            order?.suitcaseOrder &&
            <div className="customer-print-doc">
              <div className="items-block">
                {orderBoxes.map((orderBox, index) => {
                  return (<OrderBoxesDisplay
                    key={index}
                    id={orderBox.id}
                    suitcaseOrder={order?.suitcaseOrder}
                    barcode={orderBox?.barcode}
                    orderBox={orderBox} />);
                })}
              </div>
            </div>

          }

          <NoteBlock notes={ notes } />
          <StatusBlock statuses={statuses} />
          <PaymentStatusBlock payments={payments} />
          {
            loggedInUser?.role === Role.Admin &&
            <PaymentBlock initialCost={order?.initialCost} monthlyCost={order?.monthlyCost} storageRequired={ order?.storageRequired } />
          }

          <span />

        </div>
        {
          !order?.suitcaseOrder &&
          <div className="print-doc admin-print-doc barcode-doc">
            <div className="top-border">Barcodes</div>
            <div className="items-block">
              <div className="table">
                <div className="thead">
                  <div className="tr">
                    <div className="td img">Image</div>
                    <div className="td name">Item Name</div>
                    <div className="td code">Barcode</div>
                  </div>
                </div>
                <div className="tbody">

                  {orderBoxes.map(orderBox => {
                    const orderBoxDetail = orderBox?.box;
                    return(<div key={orderBox.id} className="tr">
                      <div className="td img"><img src={orderBoxDetail?.imageUrl} alt=""/></div>
                      <div className="td name">{orderBoxDetail?.name}</div>
                      <div className="td code"><Barcode>{orderBox?.barcode}</Barcode></div>
                    </div>);
                  })}
                </div>
              </div>
            </div>
          </div>
        }
      </section>

      <div className="btn-row">
        { loggedInUser?.role === Role.Admin &&
          <SubmitButton red={true} onClick={() => history.push(`/admin/order/${id}/edit`)}>
            Edit
          </SubmitButton> }
        <SubmitButton disabled={!order} onClick={() => {
          axiosKK.get(`/order/${order?.id}/label`, {
            responseType: 'blob',
          })
            .then(response => {
              FileDownload(response.data, `order-${id}-labels.pdf`);
            });
        }}>
          Download
        </SubmitButton>
      </div>
    </>
  );
}

export default withRouter(AdminOrderComponent);