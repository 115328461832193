import React from 'react';
import Modal from 'react-modal';

interface ThreeDSModalProps {
  url: string,
}

export function ThreeDSModal({ url }: ThreeDSModalProps) {
  
  return (
    <Modal isOpen={ true } contentLabel="3DS Modal">
      <div className="threeds-iframe-container">
        <iframe title="Bank iframe" src={ url } />
      </div>
    </Modal>
  )
}