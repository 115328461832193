import React, { useEffect, useState } from 'react';
import SmallButton from '../../components/SmallButton';
import { RouteComponentProps, useParams, withRouter } from 'react-router';
import { Order, OrderResponse } from '../../models/APIModels';
import Moment from 'moment';
import Title from '../../components/Title';
import { axiosKK } from '../../services/networkRequest';
import OrderEditCollectionDetailsModal from '../../components/edit/OrderEditCollectionDetailsModal';
import OrderEditDeliveryDetailsModal from '../../components/edit/OrderEditDeliveryDetailsModal';

interface OrderParams {
  id: string;
}

function OrderEditComponent({history}: RouteComponentProps): JSX.Element {
  const {id} = useParams<OrderParams>();
  const [order, setOrder] = useState<Order>();
  const [editCollectionMode, setEditCollectionMode] = useState(false);
  const [editDeliveryMode, setEditDeliveryMode] = useState(false);
  const [showOnSuccessfulUpdate, setShowOnSuccessfulUpdate] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if(!order) {
      axiosKK.get<OrderResponse>(`order/${id}`)
        .then(response => {
          setOrder(response.data.order);
        });
    }
  }, [order, id]);

  useEffect(() => {
    if (showOnSuccessfulUpdate) {
      setTimeout(() => {
        setShowOnSuccessfulUpdate(false);
      }, 5000);
    }

  }, [showOnSuccessfulUpdate]);

  return (<>
    <section className="nav-status-bar">
      <div className="status-bar-content">
        <i className="fas fa-arrow-circle-left" />
        <SmallButton onClick={() => {history.push(`/order/${id}`);}}>Back</SmallButton>
      </div>
    </section>
    <Title>{`Edit ${order?.storageRequired ? 'Storage' : 'Shipping'} Order Details`}</Title>
    <p className="grey">For {order?.storageRequired ? 'Storage' : 'Shipping'} Order {order?.id} to {order?.city}</p>
    <section className="option-box-container option-box-container_order-edit">
      <div className="each-box" onClick={() => {setEditCollectionMode(true)}}>
        <div className="img"><i className="fas fa-sign-in-alt" /></div>
        <div className="content">
          <h5>Change Collection Details</h5>
          {
            order?.collectionAddress &&
            <p>{order.collectionAddress}</p>
          }
          {
            order?.collectionDate &&
            <p className="date">Collect on {Moment(order.collectionDate).format('dddd, MMM Do YYYY')}</p>
          }
        </div>
      </div>
      <div className="each-box" onClick={() => {setEditDeliveryMode(true)}}>
        <div className="img"><i className="fas fa-sign-in-alt" /></div>
        <div className="content">
          <h5>Change Delivery Details</h5>
          {
            order?.deliveryAddress &&
            <p>{order.deliveryAddress}</p>
          }
          {
            order?.deliveryDate &&
            <p className="date">Deliver on {Moment(order.deliveryDate).format('dddd, MMM Do YYYY')}</p>
          }
        </div>
      </div>
    </section>
    { showOnSuccessfulUpdate && <p className="green">Your details are successfully updated</p> }
    {
      order &&
      <OrderEditCollectionDetailsModal
        editMode={editCollectionMode}
        order={order}
        close={() => setEditCollectionMode(false)}
        update={(updated: Order) => {
          setOrder(updated);
          setShowOnSuccessfulUpdate(true);
        }}
      />
    }
    {
      order &&
      <OrderEditDeliveryDetailsModal
        editMode={editDeliveryMode}
        order={order}
        close={() => setEditDeliveryMode(false)}
        update={(updated: Order) => {
          setOrder(updated);
          setShowOnSuccessfulUpdate(true);
        }}
      />
    }
  </>);
}

export default withRouter(OrderEditComponent);