import { Address } from '../components/AddressLookup';
import { useEffect, useState } from 'react';
import { BankHolidays } from '../models/APIModels';
import { populateDisabledDays } from './CollectionDateConstraints';
import moment from 'moment/moment';
import { axiosKK } from '../services/networkRequest';

export function useDateDisabler(address: Address | null, startDate: Date | null) {
  const [bankHolidays, setBankHolidays] = useState<BankHolidays>();
  const [disabledDays, setDisabledDays] = useState<any[]>([]);

  useEffect(() => {
    if(bankHolidays) {
      axiosKK.get('time')
        .then(response => {
          return response && response.data && response.data.time ?
                 new Date(response.data.time) :
                 new Date();
        })
        .catch(() => new Date())
        .then(date => {
          setDisabledDays(populateDisabledDays(bankHolidays, address, date, startDate != null ? [{before: moment(startDate).add(1, 'days').toDate()}] : []));
        });
    } else {
      fetch('https://www.gov.uk/bank-holidays.json')
        .then(response => response.json())
        .then(data => setBankHolidays(data))
        .catch(console.log);
    }
    // eslint-disable-next-line
  }, [address, bankHolidays]);

  return disabledDays;
}