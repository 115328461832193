import React from 'react';
import Loading from './Loading';

interface SubmitProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  isSubmitting?: boolean;
  red?: boolean;
}

export default function SubmitButton({children, isSubmitting, red, className, type, onClick, ...props}: SubmitProps): JSX.Element {
  if(isSubmitting) {
    className += ' spinning';
  }
  
  return (
    <button 
      className={red ? 'submitButton red' : 'submitButton'} 
      type={type || (onClick ? "button" : "submit")} 
      onClick={onClick} {...props}>

      <div className={className}>
        {isSubmitting ? <Loading /> : children}
      </div>
    </button>
  );
}