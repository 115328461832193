import { useState, useEffect } from 'react';

interface ViewportReturnObject {
  width: number,
  height: number,
}

export function useViewport(): ViewportReturnObject {

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleWindowResize() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { width, height };
}

export enum Width {
  SmallPhone = 350,
  Phone = 600,
  Tablet = 900,
  LargeTablet = 1200,
  NarrowDesktop = 1400,
  Desktop = 1600,
}