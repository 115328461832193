import { BoxData } from '../models/APIModels';
import React, { useEffect, useRef, useState } from 'react';
import { toMoney } from '../utilities/MoneyConversion';

export interface BoxProps {
  box: BoxData,
  setBox: (box: BoxData) => BoxData,
  handleChange: {
    (e: React.ChangeEvent<any>): void; <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
                                                                                        ? void
                                                                                        : ((e: (string | React.ChangeEvent<any>)) => void)
  },
  handleBlur: {
    (e: React.FocusEvent<any>): void; <T = any>(fieldOrEvent: T): T extends string ? ((e: any) => void)
                                                                                   : void
  },
  storageRequired: boolean,
}

export default function Box({ box, setBox, handleChange, handleBlur, storageRequired}: BoxProps): JSX.Element {
  const [showFront, setShowFront] = useState(true);
  const selectionRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      const node = event.target as Node;
      if(!selectionRef?.current?.contains(node) &&
        !inputRef?.current?.contains(node)) {
        setShowFront(true);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectionRef, inputRef]);

  function updateCount(newCount: number) {
    if(newCount < 0) {
      newCount = 0;
    }
    setBox({
      ...box,
      count: newCount,
    });
  }

  const cost = toMoney(storageRequired ? box.purchaseCost : box.shippingCost);

  return <div className="item-div">
    {showFront ?
     <div className="item-div-front">
       <div className="img-and-name">
        <img onClick={() => setShowFront(!showFront)} className="info" src="/img/information-icon.png" alt="information" />
        <img className={`item item${box.name}`} src={box.imageUrl} alt="" />
        <span>{`${box.name}: ${cost}`}</span>
       </div>
       <div className="input">
         <input onChange={handleChange} onBlur={handleBlur} value={box.count} id={box.id.toString()} />
         <div onClick={() => {
           updateCount(box.count - 1);
         }}>-</div>
         <div onClick={() => {
           updateCount(box.count + 1);
         }}>+</div>
       </div>
     </div> :
     <div className="item-div-back">
       <img onClick={() => setShowFront(!showFront)} className="info" src="/img/close-icon.png" alt="close" />
       <div><h6>{`${box.name}:`}</h6> {cost}</div>
       <div className="item-description">{box.description}</div>
     </div>
    }
  </div>;
}