import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, StaticContext } from 'react-router';
import { useParams } from 'react-router-dom';
import Title from '../../components/Title';
import Label from '../../components/Label';
import { axiosKK } from '../../services/networkRequest';
import { UserResponse } from '../../models/APIModels';
import SmallButton from '../../components/SmallButton';
import SubmitButton from '../../components/SubmitButton';
import Error from '../../components/Error';
import Modal from 'react-modal';
import UserContext from '../../contexts/UserContext';
import { Formik } from 'formik';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import Moment from 'moment';
import { Role } from '../../utilities/Role';
import { RoleSelection } from '../../components/RoleSelection';

const emailRegExp = /^([^\x00-\x7F]|[a-zA-Z0-9_\-.])+@([^\x00-\x7F]|[a-zA-Z0-9_-])+\.([^\x00-\x7F]|[a-zA-Z0-9_-]){2,}(\.([^\x00-\x7F]|[a-zA-Z0-9_-]){2,})?/g;

Modal.setAppElement('body');
const customStyles = {
  content: {
    display: 'flex',
  },
};

interface UserParams {
  id: string;
}

interface UserComponentState {
  user?: UserResponse;
}

function AdminUserComponent({history}: RouteComponentProps<undefined, StaticContext, UserComponentState>): JSX.Element {
  const { id } = useParams<UserParams>();
  const [user, setUser] = useState(history.location.state.user);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { user: loggedInUser } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    if(!user) {
      axiosKK.get<{ user: UserResponse }>(`/users/${id}`)
        .then(({data}) => {
          setUser(data.user);
        });
    }
  });

  return <>
    <section className="nav-status-bar">
      <div className="status-bar-content">
        <i className="fas fa-arrow-circle-left" />
        <SmallButton onClick={() => {history.push("/admin/users");}}>Back</SmallButton>
      </div>
    </section>
    <Title>User Details</Title>

    { loggedInUser?.role === Role.Admin &&
      <section className="admin-customer-profile-button-section">
        {!editMode &&
        <SubmitButton onClick={() => setEditMode(true)}>
          Edit
        </SubmitButton>}
        {user && user.id !== loggedInUser?.id &&
        <SubmitButton red={true} onClick={() => {setShowModal(true);}}>
          <i className="fas fa-trash-alt" /> Delete User
        </SubmitButton>}
      </section> }

    <section className="admin-customer-profile">
      <section className="all-content">
        <div className="name-and-date">
          <div className="name"><h3>{user?.firstName}</h3><h3>{user?.lastName}</h3></div>
          <div className="last-updated"><p>Last updated : {Moment(user?.updatedAt).format('dddd, MMM Do YYYY')}</p></div>
        </div>
        <div className="line" />
        <div className="content">
          <Label>Email</Label>
          <span>{user?.email}</span>
          <Label>Admin Status</Label>
          <span>{ user?.role }</span>
          <Label>Admin Created At</Label>
          <span>{Moment(user?.createdAt).format('dddd, MMM Do YYYY')}</span>
        </div>
      </section>
    </section>

    { loggedInUser?.role === Role.Admin && user &&
      <UserEditModal
        editMode={editMode}
        user={user}
        close={() => setEditMode(false)}
        update={(updated: UserResponse) => {
          setUser(updated);
        }}
      />
    }    
    
    <DeleteConfirmationModal
      show={showModal}
      directory="/users/"
      id={id}
      warningMessage="This account will be permanently deleted, and details attached with this account will not longer be accessible."
      success={() => {
        setShowModal(false);
        history.push("/admin/users");
      }}
      failure={() => setShowModal(true)}
      close={() => setShowModal(false)} />
  </>;
}

interface UserEditModalProps {
  update: (updated: UserResponse) => void;
  close: () => void;
  editMode: boolean;
  user: UserResponse;
}

interface NewUserEditErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
}

function UserEditModal({editMode, user, close, update}: UserEditModalProps): JSX.Element {

  const initialValues: UserResponse = {
    ...user
  }

  return(<Modal
    isOpen={editMode}
    onRequestClose={close}
    style={customStyles}
    contentLabel="Edit User"
  >
    <Formik
      initialValues={initialValues}
      validate={
        ({
          email,
          firstName,
          lastName,
        }) => {
          const errors: NewUserEditErrors = {};

          if(email.length < 5) {
            errors.email = 'Email Address is too short';
          } else if(!email.match(emailRegExp)?.includes(email)) {
            errors.email = 'Email Address is Invalid';
          }

          if(!firstName || firstName.length < 2) {
            errors.firstName = 'First & Last Name must be at least 2 characters';
          }

          if(!lastName || lastName.length < 2) {
            errors.lastName = 'First & Last Name must be at least 2 characters';
          }

          return errors;
        }
      }
      onSubmit={(values, {setSubmitting}) => {
        setSubmitting(true);
        axiosKK.put<{user: UserResponse}>(`/users/${user.id}`, values)
          .then(() => {
            update(values);
          })
          .finally(() => {
            setSubmitting(false);
          });
        close();
      }}
    >
      {
        ({
          values,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          errors,
          touched,
          isValid,
        }) => <>
          <form onSubmit={handleSubmit} name="user-edit-form" className="user-edit-form">
            <div className="modal-header">
              <h3>Edit User</h3>
              <SubmitButton red={true} onClick={close}>Close</SubmitButton>
            </div>
            <div className="modal-content">
              <div className="user-edit-inputs">
                <Label htmlFor="first-last-name">Name</Label>
                <div className="stuff">
                  <div className="each">
                    <input className="name-input" type="text" name="firstName" value={values.firstName} onBlur={handleBlur} onChange={handleChange} />
                    <label className="user-edit-label">First Name</label>
                  </div>
                  <div className="each">
                    <input className="name-input" type="text" name="lastName" value={values.lastName} onBlur={handleBlur} onChange={handleChange} />
                    <label className="user-edit-label">Last Name</label>
                  </div>
                </div>
                <Error touched={touched.firstName || touched.lastName} error={errors.firstName || errors.lastName} />
              </div>
              <div className="user-edit-inputs">
                <Label htmlFor="email">Email Address</Label>
                <div className="stuff">
                  <input className="email-input" type="text" name="email" value={values.email} onBlur={handleBlur} onChange={handleChange} />
                </div>
                <Error touched={touched.email} error={errors.email} />
              </div>
              <RoleSelection name="role" errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} value={values.role} />
            </div>

            <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
              Update
            </SubmitButton>
          </form>
        </>
      }

    </Formik>

  </Modal>)
}

export default withRouter(AdminUserComponent);