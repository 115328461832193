import React from 'react';

interface LinkProps {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
  children: string,
  className?: string,
  color?: string,
  href?: string,
}

export function Link({ onClick, children, className, color, href }: LinkProps) {
  return (
    <a onClick={ onClick } href={ href }>
      <span className={`link-span ${ className }`} style={ { color: color } }>{ children }</span>
    </a>
  )
}