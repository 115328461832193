import React from 'react';
import { Note } from '../models/APIModels';
import Label from './Label';
import Moment from 'moment';

interface NoteBlockProps {
  notes: Note[],
}

export function NoteBlock({ notes }: NoteBlockProps) {

  return (
    notes.length ?
    <div className="note-block">
      <Label>Notes: </Label>
      {
        notes.map((note, i) =>
          <div className="each" key={ i }>
            <Label>Note Updated At:</Label>
            <p>{ Moment(note.updatedAt).format('dddd, MMM Do YYYY') }</p>
            <Label>Content</Label>
            <p>{ note.text }</p>
          </div>
        )
      }
    </div> :
    <></>
  )
}