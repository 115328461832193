import React, { useState } from 'react';
import { withRouter } from 'react-router';
import Title from '../components/Title';
import { Formik } from 'formik';
import { axiosKK } from '../services/networkRequest';
import errorMessage from '../services/errorMessage';
import { AxiosError } from 'axios';
import SubmitButton from '../components/SubmitButton';
import { Link } from 'react-router-dom';

interface ForgotPasswordErrors {
  email?: string;
  network?: string;
}

function ForgotPasswordComponent(): JSX.Element {
  const [successMessage, setSuccessMessage] = useState<string>();
  const [networkError, setNetworkError] = useState<string>();

  const initialValues = {
    email: '',
  };

  return <div className="log-and-reg-page">
    <Title>Forgot Password</Title>
    <div className="log-and-reg-content">
      <section>
        <div className="form-outer">
          <Formik
            initialValues={initialValues}
            validate={({email}) => {
              const errors: ForgotPasswordErrors = {};

              if(!email || email.length < 4) {
                errors.email = 'No email';
              }

              if(Object.values(errors).length) {
                setSuccessMessage(undefined);
              }
              return errors;
            }}
            onSubmit={({email}, {setSubmitting, setFieldError}) => {
              setSubmitting(true);
              axiosKK.post<{}>('/forgottenPassword', {email})
                .then(() => {
                  setSuccessMessage("We have emailed you a reset link");
                })
                .catch(error => {
                  errorMessage(setNetworkError)(error);
                  setSuccessMessage(undefined);
                })
                .catch((error: Error | AxiosError) => {
                  setSuccessMessage(undefined);
                  const jsonError = JSON.parse(JSON.stringify(error));
                  let errorMessage: string;

                  if(jsonError.hasOwnProperty('response')) {
                    errorMessage = String((error as AxiosError).response?.data);
                  } else if(jsonError.hasOwnProperty('message')) {
                    errorMessage = (error.message);
                  } else {
                    errorMessage = String(error);
                  }
                  setFieldError("network", errorMessage);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {
              ({
                 values,
                 handleSubmit,
                 handleChange,
                 isSubmitting,
                 isValid,
                 handleBlur,
               }) =>
                <form className="log-and-reg" onSubmit={handleSubmit}>
                  <div className="close-btn-div">
                  </div>
                  <div className="form-details">
                    <p className="grey">Please enter your email address</p>
                    <div className="each">
                      <section><input id="user`name" name="email" onBlur={handleBlur} onChange={handleChange}
                                      value={values.email} type="email" /></section>
                      <label className="log-and-reg-label" htmlFor="email">Email</label>
                    </div>
                  </div>
                  <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
                    <img alt="" src="/img/right-arrow-circular-button-outline.png" />Send
                  </SubmitButton>
                  {networkError && <span className="error">{networkError}</span>}
                  {successMessage && <span className="successMessage">{successMessage}, <Link to="/login">Back to Login</Link></span>}
                </form>
            }
          </Formik>
        </div>
      </section>
    </div>
  </div>;
}

export default withRouter(ForgotPasswordComponent);