import React from 'react';
import Label from './Label';
import Moment from 'moment';
import { PaymentResponse } from '../models/APIModels';
import { toMoney } from '../utilities/MoneyConversion';

interface PaymentBlockProps {
  payments: PaymentResponse[];
}

export default function PaymentStatusBlock({ payments }: PaymentBlockProps) {
  return (<>
    { payments.length > 0 &&
      <div className="payment-status-block">
        <Label>Payment Status Update:</Label>
        {payments?.map((payment, index) => {
          return (
            <div key={index} className="each">
              <Label>Payment Made At:</Label>
              <p>{Moment(payment.createdAt).format('dddd, MMM Do YYYY')}</p>
              <Label>Amount:</Label>
              <p>{toMoney(payment.amount)}</p>
              <Label>Status</Label>
              <p>{payment.status}</p>
            </div>
          );
        })}
      </div>
    }
  </>);
}