import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, StaticContext } from 'react-router';
import { RouteProps } from 'react-router-dom';
import Title from '../../components/Title';
import Label from '../../components/Label';
import { axiosKK } from '../../services/networkRequest';
import { Customer, CustomerListResponse } from '../../models/APIModels';
import { useHistory } from "react-router-dom";
import SmallButton from '../../components/SmallButton';
import SubmitButton from '../../components/SubmitButton';

interface CustomersComponentState {
  customers?: Customer[];
}

function AdminCustomersListComponent({history}: RouteComponentProps<undefined, StaticContext, CustomersComponentState>): JSX.Element {
  const [customers, setCustomers] = useState<Customer[]>(history.location.state?.customers || []);
  const [page, setPage] = useState(0);
  const [hideButton, setHideButton] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  useEffect(() => {
    
    axiosKK.get<CustomerListResponse>(`/customers/${ page }`)
      .then(({ data }) => {
        const customers = data.customers;

        if (customers.length < 20) setHideButton(true);

        setCustomers(prevState => [...prevState, ...customers]);
    });

  }, [page]);

  return(<>
    <section className="nav-status-bar">
      <div className="status-bar-content">
        <i className="fas fa-arrow-circle-left"/>
        <SmallButton onClick={() => {history.push("/admin/dashboard")}}>Back</SmallButton>
      </div>
    </section>
    <Title>Customers Accounts and Info</Title>
    <section className="search-bar">
      <SearchBar setKeyword={keyword => {
        axiosKK.get<CustomerListResponse>(keyword.length === 0 ? '/customers' : `/customers/search/simple/${keyword}`)
          .then(({data}) => {
            setCustomers(data.customers);
          });
      }} />
    </section>
    <section className="admin-all-customers-info">
      <div className="table">
        <div className="thead">
          <div className="tr">
            <div className="td id">ID</div>
            <div className="td name">Name</div>
            <div className="td email">Email</div>
            <div className="td phone">Phone</div>
          </div>
        </div>
        <div className="tbody">
          {customers.map(each => (<Row key={each.id} user={each} />))}
        </div>
      </div>

      {
        !hideButton &&
        <div className="button-div">
          <SubmitButton type="button" onClick={ () => setPage(page + 1) }>Show more</SubmitButton>
        </div>
      }

    </section>
  </>)
}

interface RowProps extends RouteProps {
  user: Customer;
}

function Row({user} :RowProps) : JSX.Element {
  const history = useHistory();

  return (
    <div className="tr" onClick={() => history.push(`/admin/customer/${user.id}`)}>
      <Label>ID</Label>
      <div className="td id">{user.id}</div>
      <Label>Name</Label>
      <div className="td name">{`${user.firstName} ${user.lastName}`}</div>
      <Label>Email</Label>
      <div className="td email">{user.email}</div>
      <Label>Phone</Label>
      <div className="td phone">{user?.phoneNumber}</div>
    </div>
  )
}

interface SearchBarProps {
  setKeyword: (keyword: string) => void;
}

function SearchBar({setKeyword}: SearchBarProps): JSX.Element {
  const [search, setSearch] = useState('');
  const [dirty, setDirty] = useState(false);
  const [interval, setInterval] = useState<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      interval && clearTimeout(interval);
    };
  }, [interval]);


  return (
    <input
      onBlur={() => {
        setKeyword(search);
      }}
      value={search}
      className={'search-bar'}
      placeholder={'Search User'}
      onChange={(e) => {
        setDirty(true);
        setSearch(e.target.value);

        if(interval) {
          clearTimeout(interval);
        }
        setInterval(setTimeout(() => {
          if(dirty) {
            setDirty(false);
            setKeyword(search);
          }
        }, 250));
      }}
    />
  );
}

export default withRouter(AdminCustomersListComponent);