import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';
import { useParams } from 'react-router-dom';
import Title from '../../components/Title';
import Label from '../../components/Label';
import { axiosKK } from '../../services/networkRequest';
import { Product } from '../../models/APIModels';
import SmallButton from '../../components/SmallButton';
import SubmitButton from '../../components/SubmitButton';
import Error from '../../components/Error';
import Modal from 'react-modal';
import Moment from 'moment';
import { toMoney } from '../../utilities/MoneyConversion';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import { Formik } from 'formik';
import UserContext from '../../contexts/UserContext';
import { Role } from '../../utilities/Role';

Modal.setAppElement('body');

interface UserParams {
  id: string;
}

interface UserComponentState {
  product?: Product;
}

export default function AdminUserComponent({history}: RouteComponentProps<undefined, StaticContext, UserComponentState>): JSX.Element {

  const { id } = useParams<UserParams>();
  const [product, setProduct] = useState(history.location.state.product);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { user: loggedInUser } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);

    if(!product) {
      axiosKK.get<{ product: Product }>(`/boxes/${id}`)
        .then(({data}) => {
          setProduct(data.product);
        });
    }

  }, [id, product]);


  return <>
    <section className="nav-status-bar">
      <div className="status-bar-content">
        <i className="fas fa-arrow-circle-left" />
        <SmallButton onClick={() => {history.push("/admin/products");}}>Back</SmallButton>
      </div>
    </section>
    <Title>Product Details</Title>
    { loggedInUser?.role === Role.Admin &&
      <section className="admin-customer-profile-button-section">
        {!editMode &&
        <SubmitButton onClick={() => setEditMode(true)}>
            Edit
        </SubmitButton>}
        <SubmitButton red={true} onClick={() => {setShowModal(true);}}>
            <i className="fas fa-trash-alt" /> Delete Product
        </SubmitButton>
      </section> }

    <section className="admin-product-profile">
      <section className="all-content">
        <div className="name"><h3>{product?.name}</h3></div>
        <div className="line" />
        <section className="content-img">
          <div className="img">
            <img src={product?.imageUrl} alt=""/>
          </div>
          <div className="content">
            <Label>ID</Label>
            <span>{product?.id}</span>
            <Label>Description</Label>
            <span>{product?.description}</span>
            <Label>Email Field</Label>
            <span>{product?.emailField}</span>
            <Label>Created At</Label>
            <span>{(product?.createdAt && Moment(product?.createdAt).format("MMM Do YYYY")) || "N/A"}</span>
            <Label>Updated At</Label>
            <span>{(product?.updatedAt && Moment(product?.updatedAt).format("MMM Do YYYY")) || "N/A"}</span>
            <Label>Purchase Cost</Label>
            <span>{toMoney(product?.purchaseCost || 0)}</span>
            <Label>Storage Cost</Label>
            <span>{toMoney(product?.storageCost || 0)}</span>
            <Label>Shipping Cost</Label>
            <span>{toMoney(product?.shippingCost || 0)}</span>
            <Label>Dimensions</Label>
            <span>Width: {product?.dimensions.width}, Height: {product?.dimensions.height}, Depth: {product?.dimensions.depth}</span>
            <Label>Sort Order</Label>
            <span>{product?.sortOrder}</span>
            <Label>Charge return fee?</Label>
            <span>{product?.returnFee ? "Yes" : "No"}</span>
            <Label>Charge shipping fee?</Label>
            <span>{product?.chargeShipping ? "Yes" : "No"}</span>
          </div>
        </section>
        
      </section>
    </section>
    {
      product && <ProductEditModal
        editMode={editMode}
        product={product}
        close={() => {
          setEditMode(false);
        }}
        updateProducts={(updated: Product) => {
          setProduct(updated);
        }}
      />
    }    

    <DeleteConfirmationModal 
      show={showModal} 
      directory="/boxes/"
      id={id} 
      warningMessage = "Are you sure you want to delete this Product?"
      success={() => {
        setShowModal(false);
        history.push("/admin/products");
      }} 
      failure={() => setShowModal(true)} 
      close={() => setShowModal(false)} />
  </>;
}


interface NewProductEditErrors {
  name?: string,
  purchaseCost?: string,
  shippingCost?: string,
  storageCost?: string,
  description?: string,
  sortOrder?: string,
  imageUrl?: string,
  dimensions?: {
    width?: string,
    depth?: string,
    height?: string,
  },
}

interface ProductEditModalProps {
  updateProducts: (product: Product) => void;
  close: () => void;
  editMode: boolean;
  product: Product;
}

function ProductEditModal({editMode, product, updateProducts, close}: ProductEditModalProps): JSX.Element {

  const initialValues: Product = {
    ...product,
  };

  return <Modal
    isOpen={editMode}
    onRequestClose={close}
    style={{
      content: {
        display: 'flex',
      },
    }}
    contentLabel="Discount Modal"
  >
    <Formik
      initialValues={initialValues}
      validate={({name, purchaseCost, shippingCost, storageCost, dimensions, description, imageUrl}: Product) => {
        const errors: NewProductEditErrors = {};

        if(name.length < 2) {
          errors.name = 'Name too short';
        }

        if(purchaseCost < 0 || shippingCost < 0 || storageCost < 0) {
          errors.purchaseCost = errors.shippingCost = errors.storageCost = 'Costs must be a positive number';
        }

        const { width, height, depth } = dimensions;

        if(width <= 0 || height <= 0 || depth <= 0) {
          const dimensionError = 'Dimensions must be over 0';
          
          errors.dimensions = {
            width: dimensionError,
            height: dimensionError,
            depth: dimensionError,
          };
        }

        if(imageUrl.length < 2) {
          errors.imageUrl = 'Image URL too short';
        }

        if(description.length < 2) {
          errors.description = 'Description too short';
        }

        return errors;
      }}
      onSubmit={(values, {setSubmitting}) => {
        setSubmitting(true);
        axiosKK.put(`/boxes/${product.id}`, values)
          .then(() => {
            updateProducts(values);
          })
          .finally(() => {
            setSubmitting(false);
            close();
          });
      }}
    >
      {
        ({
           values,
           handleChange,
           handleSubmit,
           errors,
           touched,
           handleBlur,
           isValid,
           dirty
         }) =>
          <form onSubmit={handleSubmit} name="new-product-form">
            <div className="modal-header">
              <h3>Edit Product</h3>
              <SubmitButton red={true} onClick={close}>Close</SubmitButton>
            </div>

            <div className="modal-content">
              <div className="code">
                <Label htmlFor="name">Name</Label>
                <div className="stuff">
                  <input onBlur={handleBlur} onChange={handleChange} type="text" name="name" id="name"
                        value={values.name} />
                </div>
                <Error error={errors.name} touched={touched.name} />
              </div>
              <div className="cost">
                <Label>Costs:</Label>
                <div className="stuff">
                  <div className="each">
                    <input onBlur={handleBlur} onChange={handleChange} name="purchaseCost" id="purchaseCost" 
                          type="number" min="0" value={values.purchaseCost} />
                    <Label htmlFor="purchaseCost">Purchase Cost</Label>
                  </div>
                  <div className="each">
                    <input onBlur={handleBlur} onChange={handleChange} name="storageCost" id="storageCost" 
                          type="number" min="0" value={values.storageCost} />
                    <Label htmlFor="storageCost">Storage Cost</Label>
                  </div>
                  <div className="each">
                    <input onBlur={handleBlur} onChange={handleChange} name="shippingCost" id="shippingCost" 
                          type="number" min="0" value={values.shippingCost} />
                    <Label htmlFor="shippingCost">Shipping Cost</Label>
                  </div>
                </div>
                <Error error={errors.purchaseCost || errors.storageCost || errors.shippingCost}
                      touched={touched.purchaseCost || touched.storageCost || touched.shippingCost} />
              </div>
              <div className="cost">
                <Label>Dimensions:</Label>
                <div className="stuff">
                  <div className="each">
                    <input onBlur={handleBlur} onChange={handleChange} name="dimensions.width" id="width"
                           type="number" min="0" value={ values.dimensions.width } />
                    <Label htmlFor="width">Width</Label>
                  </div>
                  <div className="each">
                    <input onBlur={handleBlur} onChange={handleChange} name="dimensions.height" id="height"
                           type="number" min="0" value={ values.dimensions.height } />
                    <Label htmlFor="height">Height</Label>
                  </div>
                  <div className="each">
                    <input onBlur={handleBlur} onChange={handleChange} name="dimensions.depth" id="depth"
                           type="number" min="0" value={ values.dimensions.depth } />
                    <Label htmlFor="depth">Depth</Label>
                  </div>
                </div>
                <Error error={errors.dimensions?.width || errors.dimensions?.height || errors.dimensions?.depth}
                       touched={touched.dimensions?.width || touched.dimensions?.height || touched.dimensions?.depth} />
              </div>
              <div className="code">
                <Label htmlFor="description">Description</Label>
                <div className="stuff">
                  <textarea onBlur={handleBlur} onChange={handleChange} name="description" id="description"
                        value={values.description} />
                </div>
                <Error error={errors.description} touched={touched.description} />
              </div>
              <div className="code">
                <Label htmlFor="imageUrl">Image URL</Label>
                <div className="stuff">
                  <input type="text" onBlur={handleBlur} onChange={handleChange} name="imageUrl" id="imageUrl"
                        value={values.imageUrl} />
                </div>
                <Error error={errors.imageUrl} touched={touched.imageUrl} />
              </div>
              <div className="code">
                <Label htmlFor="description">Sort Order</Label>
                <div className="stuff">
                  <input type="number" min="0" onBlur={handleBlur} onChange={handleChange} name="sortOrder" id="sortOrder"
                        value={values.sortOrder} />
                </div>
              </div>
              <div className="code">
                <Label htmlFor="description">Charge return fee?</Label>
                <div className="stuff">
                  <input type="checkbox" onBlur={handleBlur} onChange={handleChange} name="returnFee" id="returnFee" checked={values.returnFee}/>
                </div>
              </div>
               <div className="code">
                <Label htmlFor="description">Charge shipping fee?</Label>
                <div className="stuff">
                  <input type="checkbox" onBlur={handleBlur} onChange={handleChange} name="chargeShipping" id="chargeShipping" checked={values.chargeShipping}/>
                </div>
              </div>
            </div>

            <SubmitButton disabled={!isValid || !dirty}>
              Submit
            </SubmitButton>
          </form>
      }
    </Formik>
  </Modal>;
}