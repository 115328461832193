import React from 'react';

interface ErrorProps {
  error?: string,
  touched?: boolean,
  removeBreak?: boolean
}

export default function Error({ error, touched, removeBreak }: ErrorProps): JSX.Element | null {
  return touched && error ? <>{ !removeBreak && <br />}<span className="error">{error}</span></> : null;
}