import React from 'react';
import Loading from './Loading';

interface SubmitProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    isSubmitting?: boolean;
  }
  
  export default function SubmitButton({children, isSubmitting, className, type, onClick, ...props}: SubmitProps): JSX.Element {
  
    return (
      <button className="smallButton" type={type || (onClick ? "button" : "submit")} onClick={onClick} {...props}>
        <div className={className}>
          {isSubmitting ? <Loading /> : children}
        </div>
      </button>
    );
  }