import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Title from '../../components/Title';
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Formik } from 'formik';
import SubmitButton from '../../components/SubmitButton';
import UserContext from '../../contexts/UserContext';
import AddressLookup, { Address } from '../../components/AddressLookup';
import SmallButton from '../../components/SmallButton';
import { axiosKK } from '../../services/networkRequest';
import { UpdatePaymentRequest } from '../../models/APIModels';
import { PaymentFormErrors, PaymentFormInitialValues } from '../Payment';

interface PaymentProps extends RouteComponentProps {
}



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!!);

const cardInputStyle = {
  style: {
    base: {
      fontSize: '14px',
      color: '#424770',
      fontWeight: '500',
      fontFamily: 'Montserrat, sans-serif',
      '::placeholder': {
        color: '#9e9e9e',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

export default function UpdatePaymentComponent(props: PaymentProps): JSX.Element {

  return <>
    <Elements stripe={stripePromise}>
      <PaymentComponent {...props} />
    </Elements>
  </>
}

function PaymentComponent({history}: PaymentProps): JSX.Element {
  const stripe = useStripe();
  const elements = useElements();
  const [billingAddress, setBillingAddress] = useState<Address>({});
  const [showComplete, setShowComplete] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialValues: PaymentFormInitialValues = {
    name: '',
    cardNumberValidation: false,
    cvcValidation: false,
    expiryDateValidation: false,
    address: '',
    agreeToTerms: true,
  }

  return (<>
    <section className="nav-status-bar">
      <div className="status-bar-content">
        <i className="fas fa-arrow-circle-left" />
        <SmallButton onClick={() => {history.push('/profile');}}>Back</SmallButton>
      </div>
    </section>
    <Title>Update Payment Method</Title>
    <UserContext.Consumer>
      {({user}) => (
        <>
          { user && <Formik initialValues={ initialValues }
            validateOnMount={true}
            validate={({ name, cardNumberValidation, cvcValidation, expiryDateValidation }) => {
              const errors: PaymentFormErrors = {};

              if(!name || name?.length < 5) {
                errors.name = 'too short of a name';
              }

              if(!cardNumberValidation) {
                errors.cardNumberValidation = "Card number incomplete";
              }

              if(!cvcValidation) {
                errors.cardNumberValidation = "CVC incomplete";
              }

              if(!expiryDateValidation) {
                errors.cardNumberValidation = "Expiry date incomplete";
              }

              return errors;
            }}
            onSubmit={async ({ name }, {setSubmitting}) => {
              setSubmitting(true);

              if(!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                throw new Error('Payment gateway not prepared');
              }

              // Get a reference to a mounted CardElement. Elements knows how
              // to find your CardElement because there can only ever be one of
              // each type of element.
              const cardNumber = elements.getElement(CardNumberElement);
              const cvcNumber = elements.getElement(CardCvcElement);
              const expiryDate = elements.getElement(CardExpiryElement);

              if(!(cardNumber && cvcNumber && expiryDate)) {
                throw Error('Card elements not ready');
              }

              const {paymentMethod, error} = await stripe.createPaymentMethod({
                type: 'card', 
                card: cardNumber, 
                billing_details: {
                  name, 
                  address: {
                    city: billingAddress.city,
                    line1: billingAddress.line1,
                    line2: billingAddress.line2,
                    postal_code: billingAddress.postCode,
                    state: billingAddress.county
                  }
                }
              })

              if(error) {
                throw error;
              }

              if(!paymentMethod) {
                throw new Error('Payment method not prepared');
              }

              const request: UpdatePaymentRequest = {
                paymentMethodId: paymentMethod.id,
              };

              await axiosKK.put('/customer/addPaymentMethod', request);

              setShowComplete(true);
            }}>
      {
        ({
           values,
           handleChange,
           handleSubmit,
           isSubmitting,
           isValid,
           setFieldValue
         }) =>
          <form className="update-payment" onSubmit={handleSubmit}>
            <section className="content">
              <section className="all-details">
                <div className="address">
                  <div className="all-coll-del">
                  <AddressLookup address={billingAddress} setAddress={setBillingAddress} />
                  </div>
                </div>
                <div className="payment">
                  <div className="card-info">
                    <div className="title-bar">
                      <h5>Card Details:</h5>
                      <img src="img/card-icon.png" alt="" />
                    </div>
                    <div className="input-bar">
                      <div className="each">
                        <section><input onChange={handleChange} id="name"
                                        value={values.name} /></section>
                        <label className="payment-label">Cardholder Name</label>
                      </div>
                      {/*<CardElement />*/}
                      <div className="each">
                        <section><CardNumberElement options={cardInputStyle} onChange={ event => {
                          setFieldValue("cardNumberValidation", event.complete);
                        } } /></section>
                        <label className="payment-label">Card Number</label>
                      </div>
                      <div className="each-tgt">
                        <div className="each">
                          <section><CardCvcElement options={cardInputStyle} onChange={ event => {
                            setFieldValue("cvcValidation", event.complete);
                          } } /></section>
                          <label className="payment-label">CVC</label>
                        </div>
                        <div className="each">
                          <section><CardExpiryElement options={cardInputStyle} onChange={ event => {
                            setFieldValue("expiryDateValidation", event.complete);
                          } } /></section>
                          <label className="payment-label">Expiry Date</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>
            {showComplete && <h5 className="updateComplete">Payment details is successfully updated</h5>}
            <SubmitButton className="payment-btn" disabled={!isValid} isSubmitting={isSubmitting}>
              Update Card Details<img alt="" src="/img/right-arrow-circular-button-outline.png" />
            </SubmitButton>
          </form>
      }
    </Formik>}
    </>
    )}
  </UserContext.Consumer>
    
  </>);
}