import React, { useContext, useEffect, useState } from 'react';
import Title from '../../components/Title';
import Label from '../../components/Label';
import SubmitButton from '../../components/SubmitButton';
import SmallButton from '../../components/SmallButton';
import { axiosKK } from '../../services/networkRequest';
import { Discount, DiscountAndCount, DiscountListResponse } from '../../models/APIModels';
import { RouteProps, useHistory } from 'react-router-dom';
import Moment from 'moment';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import { DiscountModal } from '../../components/admin/DiscountModal';
import UserContext from '../../contexts/UserContext';
import { Role } from '../../utilities/Role';

export default function DiscountComponent(this: any): JSX.Element {
  const history = useHistory();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState<boolean | number>(false);
  const [discounts, setDiscounts] = useState<DiscountAndCount[]>([]);
  const [reloadData, setReloadData] = useState(false);
  const { user: loggedInUser } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);

    axiosKK.get<DiscountListResponse>('/discounts')
      .then(({data}) => {
        setDiscounts(data.discounts);
      });
  }, [reloadData]);

  return (<>
    <section className="nav-status-bar">
      <div className="status-bar-content">
        <i className="fas fa-arrow-circle-left"/>
        <SmallButton onClick={() => {history.push("/admin/dashboard")}}>Back</SmallButton>
      </div>
    </section>

    <Title>Discounts</Title>
    <section className="admin-all-discounts-info">

      { loggedInUser?.role === Role.Admin &&
        <section className="search-bar">
          <SubmitButton onClick={() => {setShowCreateModal(true);}}>+ Create New Discount</SubmitButton>
        </section> }

      <div className="table">
        <div className="thead">
          <div className="tr">
            <div className="td xs">ID</div>
            <div className="td large">Code</div>
            <div className="td small">Status</div>
            <div className="td small">Discount</div>
            <div className="td small">Up-Front</div>
            <div className="td small">Recurring</div>
            <div className="td small">No. of Uses</div>
            <div className="td mid">Expiry</div>
            <div className="td mid">Actions</div>
          </div>
        </div>
        <div className="tbody">
          {discounts.map(each => (<Row id={each.id} key={each.id} discount={each} setShowEdit={id => setShowEditModal(id)} reloadData={() => setReloadData(!reloadData)} count={each} />))}
        </div>
      </div>

    </section>

    { loggedInUser?.role === Role.Admin &&
      <DiscountModal
        showCreate={showCreateModal}
        showEdit={showEditModal}
        close={() => {
          setShowCreateModal(false);
          setShowEditModal(false);
        }}
        discount={discounts.find((value) => value.id === showEditModal)}
        refreshDiscounts={() => setReloadData(!reloadData)}
        updateDiscounts={discount => {
          setDiscounts(([
            discount,
            ...discounts.filter((value) => value.id !== discount.id),
           ]));
        } } /> }
  </>);
}

interface RowProps extends RouteProps {
  reloadData: () => void;
  setShowEdit: (id: number) => void;
  discount: Discount;
  count: DiscountAndCount;
  id: number;
}

function Row({discount, count, setShowEdit, reloadData}: RowProps): JSX.Element {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);

  const currentDate = Moment();
  const expireDateIsBeforeCurrent = Moment(discount.expires).isBefore(currentDate);

  return(<>
    <div className="tr" >
      <Label>ID</Label>
      <div className="td xs">{discount.id}</div>
      <Label>Code</Label>
      <div className="td large code">{discount.discountCode}</div>
      <Label>Status</Label>
      <div className="td small">
        {discount.deletedAt || (discount.expires && expireDateIsBeforeCurrent) ?
          <span className="inactive">inactive</span> :
          <span className="active">active</span>}
      </div>
      <Label>Percentage</Label>
      <div className="td small">{discount.discountPercentage}%</div>
      <Label>Up-Front</Label>
      <div className="td small">
        {discount.upFront ? "Yes" : "No"}
      </div>
      <Label>Recurring</Label>
      <div className="td small">
        {discount.recurring ? "Yes" : "No"}
      </div>
      <Label>Number of Uses</Label>
      <div className="td small">
        {count.count}
      </div>
      <Label>Expiry</Label>
      {discount.deletedAt ?
        <div className="td mid">{Moment(discount.deletedAt).format('MMM Do YYYY')}</div> :
        <div className="td mid">
          {discount.expires ?
            <>{Moment(discount.expires).format('MMM Do YYYY')}</> :
            <>N/A</>}
        </div>
      }
      <Label>Actions</Label>
      <div className="td mid discount-button-wrapper">
        {discount.deletedAt || (discount.expires && expireDateIsBeforeCurrent) ?
         <SubmitButton className="activate" onClick={() => setShowActivateModal(true)}>
           <i className="fas fa-trash-restore" /> Reactivate
         </SubmitButton> :
         <SubmitButton red={true} className="trash" onClick={() => setShowConfirmModal(true)}>
           <i className="fas fa-trash" /> Deactivate
         </SubmitButton>
        }
      </div>
    </div>

    <DeleteConfirmationModal
      show={showConfirmModal}
      close={() => setShowConfirmModal(false)}
      success={() => {
        setShowConfirmModal(false);
        reloadData();
      }}
      failure={() => setShowConfirmModal(true)}
      directory = "/discounts/"
      id={discount.id}
      warningMessage = "Are you sure you want to deactivate this discount code?" />

    <ConfirmationModal
      id={discount.id}
      show={showActivateModal}
      close={() => setShowActivateModal(false)}
      success={() => {
        setShowActivateModal(false);
        setShowEdit(discount.id);
        reloadData();
      }}
      failure={() => setShowActivateModal(true)}
      directory = "/discounts/activate/"
      warningMessage = "Do you want to reactivate this discount code?" />
  </>)
}