import Switch from 'react-switch';
import React from 'react';

interface StyledSwitchProps {
  handleChange: (field: string, value: any, shouldValidate?: boolean) => void;
  value: any;
  id: string;
  disabled?: boolean;
}

export default function StyledSwitch({handleChange, value, id, disabled}: StyledSwitchProps) : JSX.Element {
  return (<>
    <label className="toggle-switch-label">
      <Switch
        onChange={(checked, event, id) => {
          handleChange(id, checked, true);
        }}
        disabled={disabled}
        id={id}
        name={id}
        checked={value}
        className={disabled ? 'disabled-switch' : ''}
        onColor="#86d3ff"
        onHandleColor="#2693e6"
        handleDiameter={30}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48} />
    </label>
  </>);
}