import React from 'react';
import { withRouter } from 'react-router';
import Title from '../../components/Title';
import SmallButton from '../../components/SmallButton';

import RegisterSection, { RegistrationProps } from '../../components/RegisterSection';

function AdminUsersCreateComponent(props: RegistrationProps): JSX.Element {
  return (
    <>
      <section className="nav-status-bar">
        <div className="status-bar-content">
          <i className="fas fa-arrow-circle-left" />
          <SmallButton className="submitButton" onClick={() => {props.history.push("/admin/users")}}>Back</SmallButton>
        </div>
      </section>
      <Title>Users Create</Title>
      <RegisterSection {...props} postRegistration={() => {props.history.push("/admin/users")}} path="/users/create" createAdminAccount={ true } />
    </>
  );
}

export default withRouter(AdminUsersCreateComponent);