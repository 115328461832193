import { reduceBankHolidays } from './BankHolidayReducer';
import moment from 'moment';
import { BankHolidays } from '../models/APIModels';
import { Address } from '../components/AddressLookup';

export function populateDisabledDays(bankHolidays: BankHolidays, address: Address | null, currentDate: Date, initialDisabledDays: any[] | null){
  let disabledDays = [];

  let datesToInclude = reduceBankHolidays(bankHolidays!!, address);

  for(const bankHoliday of datesToInclude){
    const bankHolidayDate = new Date(bankHoliday.date);
    if(bankHolidayDate >= currentDate) {
      bankHolidayDate.setHours(0, 0, 0, 0);
      disabledDays.push(bankHolidayDate);
    }
  }

  let numberOfDays;

  if(currentDate.getHours() >= 11) {
    // If it is after 11am, the earliest collection date is 2 days from now
    numberOfDays = 3;
  } else {
    // If it is before 11am, the earliest collection date is 1 day from now
    numberOfDays = 2;
  }

  // If today is Thursday, Friday or Saturday account for the weekend days
  //  0 = Sunday, 6 = Saturday
  if(currentDate.getDay() === 4 || currentDate.getDay() === 5 || currentDate.getDay() === 6) {
    numberOfDays += 2;
  }

  // If any of the days between now and the nearest collection date are bank holidays, add an extra disabled day.
  for(let i = 1; i < numberOfDays; i++) {
    let date = new Date(currentDate);
    date.setDate(date.getDate() + i);
    date.setHours(0, 0, 0, 0);
    if(disabledDays.find(disabledDate => disabledDate.getTime() === date.getTime())) {
      numberOfDays += 1;
    }
  }

  const dateBefore = moment(currentDate.getTime()).add(numberOfDays, 'days').toDate();
  dateBefore.setHours(0, 0, 0, 0);

  disabledDays.push({ daysOfWeek: [0, 6] })
  disabledDays.push({ before: dateBefore });
  
  disabledDays.push({
    from: new Date('2022-12-23'),
    to: new Date('2023-1-5')
  })

  if(initialDisabledDays) {
    disabledDays.push(...initialDisabledDays)
  }
  
  return disabledDays;
}